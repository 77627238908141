import { makeAutoObservable, toJS } from 'mobx'
import {
  DateTemplateDate,
  DateTemplateFragment,
  DateTemplateInput,
  DateTemplateInterval,
  DateTemplateType,
} from 'types/graphql'

import { Interval } from 'src/models/Settings/Interval'
import { SemanticDate } from 'src/models/Settings/SemanticDate'

export class DateTemplate {
  private _type: DateTemplateType
  private _interval: Interval | undefined
  private _date: SemanticDate | undefined

  constructor(args?: {
    date?: DateTemplateDate
    interval?: DateTemplateInterval
    type: DateTemplateType
  }) {
    const { date, interval, type } = args ?? {}
    this._type = type ?? 'Interval'

    if (this._type === 'Interval') {
      this._date = undefined
      this._interval = interval ? Interval.fromProtocol(interval) : new Interval()
    } else {
      this._date = date ? SemanticDate.fromProtocol(date) : new SemanticDate()
      this._interval = undefined
    }

    makeAutoObservable(this)
  }

  public get date(): SemanticDate | undefined {
    return this._date
  }

  public get interval(): Interval | undefined {
    return this._interval
  }

  public get isInvalid(): boolean {
    return this._type === 'Interval' && !this._interval?.isPopulated
  }

  public get type(): DateTemplateType {
    return this._type
  }

  public set type(value: DateTemplateType) {
    if (this._type === value) {
      return
    }

    this._type = value
    if (value === 'Date') {
      this._interval = undefined
      this._date = new SemanticDate()
    } else {
      this._interval = new Interval()
      this._date = undefined
    }
  }

  public get protocol(): DateTemplateInput {
    return {
      type: this._type,
      date: toJS(this._date?.protocol) ?? null,
      interval: toJS(this._interval?.protocol) ?? null,
    }
  }

  public static fromProtocol(protocol: DateTemplateFragment): DateTemplate {
    return new DateTemplate({
      type: protocol.type,
      date: protocol.date || undefined,
      interval: protocol.interval || undefined,
    })
  }
}
