import dayjs, { Dayjs } from 'dayjs'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsBusinessDays from 'dayjs-business-days2'
import isNil from 'lodash.isnil'

import { TimeZone } from '../../types/graphql'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsIsToday)
dayjs.extend(dayjsBusinessDays)

export enum DateFormats {
  NumericalDateTime = 'MM-DD-YY [at] h:mma',
  NumericalDateTimeConcise = 'MM-DD-YY, h:mma',
  NumericalDateTimeWithSeconds = 'MM-DD-YY, h:mma:ss',
  Month = 'MMMM',
  MonthNameDateTime = 'MMMM D, YYYY, h:mma',
  MonthNameDateNoTime = 'MMMM D, YYYY',
  MonthDayYear = 'M-DD-YY',
  MonthDayYearWithSlash = 'M/DD/YY',
  MonthDay = 'M-DD',
  MonthDayFullYear = 'M-DD-YYYY',
  FullDateWithDayOfWeek = 'dddd MMMM D, YYYY, h:mma',
  Time = 'h:mma',
  ISO8601Standard = 'YYYY-MM-DD',
}

export const formatDate = (date: Date | string, dateFormat: DateFormats) => {
  return dayjs(date).format(dateFormat)
}

export const getDayjsInTimeZone = (date: Date | string, timeZone: TimeZone | null | undefined) =>
  dayjs(date).tz(timeZone ? TimeZoneMap[timeZone] : undefined)

export const formatDateInTimeZone = (
  date: Date | string,
  timeZone: TimeZone | null | undefined,
  dateFormat: DateFormats
) => {
  return getDayjsInTimeZone(date, timeZone).format(dateFormat)
}

/**
 * Antd time pickers will preserve the time zone of any pre-existing field value when a user changes the time.
 * However, if the user sets a time in a blank field, the antd time pickers will always instantiate the value using the client time zone, which may differ from the org time zone.
 * This function should be used when writing a form field value to the backend; it enforces that the internal time value sent to the backend corresponds to the org time zone.
 *
 * IF YOU'RE USING THIS ON NEW CODE YOU'RE DOING SOMETHING WRONG.
 * Do not store abstract time of day as an instant/datetime, instead use a number of minutes since midnight or some equivalent.
 *
 * @param date
 * @deprecated
 */
export const getTimeOnlyDayjsInTimeZone = (
  date: Dayjs | null | undefined,
  timeZone: TimeZone | undefined
) => {
  if (!date || !timeZone) {
    return date
  }

  // NOTE! THIS IS STILL BUGGY!
  // The evaluted time will not match what the user entered if one time zone respects daylight savings while the other does not.
  // To fix, we ultimately need to stop storing abstract time as Datetime
  const timeZoneStrippedDateString = date.format('YYYY-MM-DD hh:mm:ss')
  return dayjs.tz(timeZoneStrippedDateString, TimeZoneMap[timeZone])
}

// is the date at least one day in the past?
export const isBeforeToday = (date: Date | string) => {
  return dayjs(date).isBefore(new Date(), 'day')
}

export const isToday = (date: Date | string) => {
  return dayjs(date).isToday()
}

export const getDueDate = (daysToComplete?: number | null): Date | undefined => {
  if (daysToComplete == null) {
    return undefined
  }
  return dayjs(new Date()).businessDaysAdd(daysToComplete).toDate()
}

export const removeSeconds = (date: Date | string) => {
  return dayjs(date).second(0).millisecond(0).toDate()
}

// convertDateToTimeZone exists because AntdDatePicker does not allow the timezone to be
// selected:  It is needed to translate a selected date to the desired timezone.
//
// For example, suppose the following the local browser time zone is EST and the
// user wants to generate a report using Central time: If '01-01-25, 12:00am' is
// chosen in the date picker, then we are given the form value date of
// '2025-01-01T05:00:00.272Z' (which is the chosen time in the local offset, the
// start of the day in EST), and this function would then convert it to the
// desired '2025-01-01T06:00:00.272Z' (which is one hour later, the start of the
// day in central).
export const convertDateToTimeZone = (date: Date | string, timeZone: TimeZone) => {
  // Convert the date to Day.js object in UTC
  const dayjsDateUTC = dayjs(date).utc()

  // Get the offset in hours for the target time zone
  const targetOffset = dayjs().tz(TimeZoneMap[timeZone]).utcOffset()
  const localOffset = dayjs().utcOffset()

  // Calculate the difference in minutes and adjust the date
  const adjustedDate = dayjsDateUTC.add(localOffset - targetOffset, 'minute')

  return adjustedDate.toDate()
}

export const TimeZoneAbbreviationMap: Record<TimeZone, string> = {
  Pacific: 'PDT',
  Mountain: 'MDT',
  MountainStandard: 'MST',
  Central: 'CDT',
  Eastern: 'EDT',
}

export const TimeZoneDisplayMap: Record<TimeZone, string> = {
  Pacific: 'Pacific Time',
  Mountain: 'Mountain Daylight Time',
  MountainStandard: 'Mountain Standard Time',
  Central: 'Central Time',
  Eastern: 'Eastern Time',
}

export const TimeZoneMap: Record<TimeZone, string> = {
  Pacific: 'America/Los_Angeles',
  Mountain: 'America/Denver',
  MountainStandard: 'America/Phoenix',
  Central: 'America/Chicago',
  Eastern: 'America/New_York',
}

export const getLocalBrowserTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return dayjs().tz(timeZone).format('z')
}

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

/**
 * Gets the number of days in a month ignoring leap years
 * @param month 1-indexed month
 * @returns 1-indexed day
 */
export const getNumberOfDaysForMonth = (month: number) => {
  let maxNumberOfDays = 31
  if (month === 4 || month === 6 || month === 9 || month === 11) {
    // April, June, September, November
    maxNumberOfDays = 30
  } else if (month === 2) {
    // February
    maxNumberOfDays = 28 // Specifically not allowing configuring leap year 29, since this day config could apply to any year
  }
  return maxNumberOfDays
}

export const getGraphQLDateString = <T extends string | Date | Dayjs | null | undefined>(
  value: T
) => {
  return !isNil(value) ? dayjs(value).format(DateFormats.ISO8601Standard) : value
}
