export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CalendarEventMoment: [
      'CalendarEventMomentDate',
      'CalendarEventMomentDateTime',
    ],
    FormulaOutput: [
      'FormulaOutputAlert',
      'FormulaOutputRecordTaskFeeInputValue',
      'FormulaOutputUser',
      'FormulaOutputWorkflowTemplate',
    ],
    RecordTaskFeeInputValue: [
      'RecordTaskFeeInputQuantity',
      'RecordTaskFeeInputText',
    ],
    ViewDataElement: [
      'ViewDataElementBoolean',
      'ViewDataElementDate',
      'ViewDataElementDateTime',
      'ViewDataElementFeeType',
      'ViewDataElementFieldType',
      'ViewDataElementGeneralLedgerNumberType',
      'ViewDataElementInspectionTemplate',
      'ViewDataElementOrganization',
      'ViewDataElementOrganizationFinixMerchantType',
      'ViewDataElementPaymentMethodType',
      'ViewDataElementPaymentTransferType',
      'ViewDataElementRecord',
      'ViewDataElementRecordActionRequiredBy',
      'ViewDataElementRecordPlansSet',
      'ViewDataElementRecordTaskGroupType',
      'ViewDataElementRecordTaskInspectionResult',
      'ViewDataElementRecordTaskInspectionStatus',
      'ViewDataElementRecordTaskPaymentStatusType',
      'ViewDataElementRecordTaskStatus',
      'ViewDataElementRecordTaskType',
      'ViewDataElementRecordTemplate',
      'ViewDataElementRecordTypeStatus',
      'ViewDataElementRecordTypeType',
      'ViewDataElementString',
      'ViewDataElementUser',
      'ViewDataElementViolation',
      'ViewDataElementViolationStatus',
      'ViewDataElementViolationType',
    ],
  },
}
export default result
