export const FormulaFragment = gql`
  fragment FormulaFragment on Formula {
    id
    defaultOutput {
      __typename
      ...FormulaOutputFragment
    }
    description
    inputFieldTemplate {
      ...FieldFragment
    }
    inputGisAttributeEnumeration {
      ...EnumerationFragment
    }
    inputType
    mappings {
      ...FormulaMappingFragment
    }
    name
    outputType
    outputUserType
    recordTemplate {
      id
    }
    recordType {
      id
    }
  }
  fragment FormulaMappingFragment on FormulaMapping {
    inputEnumerationValue {
      ...EnumerationValueFragment
    }
    inputString
    output {
      ...FormulaOutputFragment
    }
  }
  fragment FormulaOutputFragment on FormulaOutput {
    __typename
    ... on FormulaOutputAlert {
      alertText
    }
    ... on FormulaOutputUser {
      user {
        ...UserDataFragment
      }
    }
    ... on FormulaOutputWorkflowTemplate {
      workflowTemplate {
        id
        name
      }
    }
    ... on FormulaOutputRecordTaskFeeInputValue {
      feeTemplate {
        ...FeeFragment
      }
      recordTaskFeeInputValue {
        ...RecordTaskFeeInputValueFragment
      }
    }
  }
`

export const FormulaOptionFragment = gql`
  fragment FormulaOptionFragment on Formula {
    id
    name
    description
    outputType
    outputUserType
  }
`
