import { faLink, faMagnifyingGlass, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import styled from 'styled-components'
import {
  CSLBContractorResponse,
  CSLBContractorResponseFragment,
  CSLBContractorSnapshot,
  CSLBContractorSnapshotFragment,
} from 'types/graphql'

import { AutoComplete, AutocompleteFieldValues } from 'src/components/form/AutoComplete'
import { makeArrayFieldName } from 'src/components/form/util'
import { FormFieldWrapperProps } from 'src/components/form/Wrapper'
import { ColumnData, FieldValueTable } from 'src/components/shared/FieldValueTable'
import { getContainerStyleProps } from 'src/components/shared/StyledComponents'
import Text, { TextSize } from 'src/components/Typography/Text'
import {
  useCreateCSLBContractorSnapshotMutation,
  useSearchCSLBContractorsMutation,
} from 'src/fetch/cslbContractors'
import { useSlug } from 'src/hooks/use-slug'
import { useTheme } from 'src/hooks/use-theme'

export interface CSLBContractorAutocompleteProps extends FormFieldWrapperProps {
  placeholder?: string
  disabled?: boolean
  disabledContractorDisplayNames?: string[]
  size?: SizeType
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  flex-direction: column;
`

const ConnectedToCSLBContainer = styled.div`
  ${(props) => getContainerStyleProps(props.theme)}
  padding: 12px;
  .ant-table-thead {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export interface CSLBContractorAutocompleteOption {
  value: string
  label: string
  id: string
  disabled?: boolean
}

export interface CSLBContractorAutocompleteFieldValues
  extends AutocompleteFieldValues<CSLBContractorResponseFragment> {
  cslbContractorSnapshotId?: number
  cslbContractorSnapshot?: CSLBContractorSnapshotFragment
  loading?: boolean
}

export const mapCSLBContractorDataToOption = (
  data: CSLBContractorResponse | CSLBContractorSnapshotFragment,
  disabledContractorDisplayNamesSet?: Set<string>
): CSLBContractorAutocompleteOption => {
  return {
    value: data.licenseNumber,
    label: `${data.displayName} (${data.licenseNumber})`,
    id: data.licenseNumber,
    disabled: disabledContractorDisplayNamesSet?.has(data.displayName || ''),
  }
}

const CSLBContractorAutocomplete = (props: CSLBContractorAutocompleteProps) => {
  const { placeholder, disabledContractorDisplayNames, size, ...formFieldWrapperProps } = props
  const { fieldName } = formFieldWrapperProps

  const { mutateAsync: searchCSLB } = useSearchCSLBContractorsMutation()

  const { mutateAsync: createSnapshot } = useCreateCSLBContractorSnapshotMutation()

  const disabledContractorDisplayNamesSet = new Set(disabledContractorDisplayNames)
  const form = Form.useFormInstance()

  const makeHiddenFieldName = (str: string) => {
    return makeArrayFieldName(fieldName, str)
  }

  Form.useWatch([fieldName], form)
  const { setFieldValue, getFieldValue } = form

  const cslbContractorSnapshotIdFieldName = makeHiddenFieldName('cslbContractorSnapshotId')
  const cslbContractorSnapshotFieldName = makeHiddenFieldName('cslbContractorSnapshot')
  const loadingFieldName = makeHiddenFieldName('loading')

  Form.useWatch(cslbContractorSnapshotIdFieldName)
  Form.useWatch(cslbContractorSnapshotFieldName)
  Form.useWatch(loadingFieldName)

  const { getTokenVal } = useTheme()

  const orgSlug = useSlug()
  const search = async (query: string) => {
    const res = await searchCSLB({ input: { query, orgSlug } })
    const results = res.searchCSLBContractors.results
    return results
  }

  const mapDataToOption = (data: CSLBContractorResponse): CSLBContractorAutocompleteOption => {
    return {
      value: data.licenseNumber,
      label: `${data.displayName} (${data.licenseNumber})`,
      id: data.licenseNumber,
      disabled: disabledContractorDisplayNamesSet.has(data.displayName || ''),
    }
  }

  const handleChange = async (id: string) => {
    try {
      setFieldValue(loadingFieldName, true)
      const { createCSLBContractorSnapshot } = await createSnapshot({
        input: { licenseNumber: id, orgSlug },
      })
      setFieldValue(cslbContractorSnapshotFieldName, createCSLBContractorSnapshot)
      setFieldValue(cslbContractorSnapshotIdFieldName, createCSLBContractorSnapshot.id)
    } catch (e) {
      console.log(e)
    } finally {
      setFieldValue(loadingFieldName, false)
    }
  }

  const contractorSnapshot = getFieldValue(cslbContractorSnapshotFieldName) as
    | CSLBContractorSnapshot
    | undefined
  const loading = getFieldValue(loadingFieldName)

  const dataSource: ColumnData[] = contractorSnapshot
    ? [
        {
          fieldName: 'License Number',
          key: 'licenseNumber',
          fieldValue: contractorSnapshot.licenseNumber,
        },
        {
          fieldName: 'Display Name',
          key: 'displayName',
          fieldValue: contractorSnapshot.displayName,
        },
        { fieldName: 'Status', key: 'status', fieldValue: contractorSnapshot.status },
        { fieldName: 'Address', key: 'address', fieldValue: contractorSnapshot.address },
        {
          fieldName: 'Phone Number',
          key: 'phoneNumber',
          fieldValue: contractorSnapshot.phoneNumber,
        },
        { fieldName: 'Issue Date', key: 'issueDate', fieldValue: contractorSnapshot.issueDate },
        {
          fieldName: 'Expiration Date',
          key: 'expirationDate',
          fieldValue: contractorSnapshot.expirationDate,
        },
      ]
    : []

  const getLabel = () => {
    if (
      contractorSnapshot &&
      contractorSnapshot?.licenseNumber ===
        // the selectedId set in Autocomplete does not update in the Form.List context for some reason
        getFieldValue(makeHiddenFieldName('selectedId'))
    ) {
      return (
        <Tooltip
          title={
            <ConnectedToCSLBContainer>
              <Text size={TextSize.Large}>
                This information was imported from the CSLB database and will be attached to your
                submittal.
              </Text>
              <FieldValueTable dataSource={dataSource} />
            </ConnectedToCSLBContainer>
          }
          overlayStyle={{
            maxWidth: 'unset',
            width: 'fit-content',
            backgroundColor: 'white',
          }}
          overlayInnerStyle={{
            backgroundColor: getTokenVal('white'),
            padding: 0,
          }}
        >
          {baseLabel}{' '}
          <FontAwesomeIcon
            icon={faLink}
            style={{
              color: getTokenVal('colorSuccessBase'),
            }}
          />
        </Tooltip>
      )
    }
    if (loading) {
      return (
        <>
          {baseLabel}{' '}
          <FontAwesomeIcon
            icon={faSpinner}
            style={{
              color: getTokenVal('colorPrimaryBase'),
              animation: 'spin 2s linear infinite',
              marginLeft: '4px',
            }}
          />
        </>
      )
    }
    return baseLabel
  }

  const { label: baseLabel, ...rest } = formFieldWrapperProps

  return (
    <Container>
      <AutoComplete<CSLBContractorResponse, CSLBContractorAutocompleteOption>
        {...rest}
        size={size}
        rules={[
          {
            validator: async () => {
              if (loading) {
                return Promise.reject('Loading CSLB information')
              }
              return Promise.resolve()
            },
            validateTrigger: ['onSubmit'],
          },
          ...(rest.required ? [{ required: rest.required, message: rest.errorMessage }] : []),
        ]}
        label={getLabel()}
        placeholder={placeholder}
        search={search}
        optionLabelProp="label"
        mapDataToOption={mapDataToOption}
        caption={
          <Text size={TextSize.Small} inline>
            Start typing a company name or license number.
          </Text>
        }
        suffixIcon={faMagnifyingGlass}
        onChange={handleChange}
      />
      <Form.Item name={cslbContractorSnapshotIdFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={cslbContractorSnapshotFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={loadingFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
    </Container>
  )
}

export default React.memo(CSLBContractorAutocomplete)
