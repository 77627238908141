import { useEffect, useMemo, useState } from 'react'

import {
  ColumnSort,
  ExpandedState,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
} from '@tanstack/react-table'

import useDebounce from 'src/hooks/use-debounce'

export const DEFAULT_PAGE_SIZE = 50

export type TableState<TData> = {
  debouncedSearchQuery: string
  expanded: ExpandedState
  hoveredRow: Row<TData> | undefined
  onExpandedChange: (expanded: ExpandedState) => void
  onHoveredRowChange: (row: Row<TData> | undefined) => void
  onPaginationChange: (pagination: PaginationState) => void
  onRowSelectionChange: (rowSelection: RowSelectionState) => void
  onSearchQueryChange: (searchQuery: string) => void
  onSortingChange: OnChangeFn<ColumnSort[]>
  pagination: PaginationState
  rowSelection: RowSelectionState
  searchQuery: string
  selectedRowSet: Set<TData>
  sorting: ColumnSort[]
}

export const useTableState = <TData>(args?: {
  autoResetKey?: string
  initialState?: {
    pagination?: PaginationState
    sorting?: ColumnSort[]
  }
}): TableState<TData> => {
  const { autoResetKey, initialState } = args ?? {}
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery)
  const defaultPaginationState = useMemo(
    () => ({
      pageIndex: initialState?.pagination?.pageIndex ?? 0,
      pageSize: initialState?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    }),
    [initialState?.pagination?.pageIndex, initialState?.pagination?.pageSize]
  )
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationState)

  const defaultSortingDependencyString = JSON.stringify(initialState?.sorting ?? [])
  const defaultSortingState = useMemo(
    () => initialState?.sorting ?? [],
    // Shallow memoization of the initial state sorting array results in an infinite loop if the consumer forgets to memoize
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultSortingDependencyString]
  )
  const [sorting, setSorting] = useState<ColumnSort[]>(defaultSortingState)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [hoveredRow, setHoveredRow] = useState<Row<TData>>()
  const [selectedRowSet, setSelectedRowSet] = useState<Set<TData>>(new Set<TData>())
  const [expanded, setExpanded] = useState<ExpandedState>({})

  useEffect(() => {
    setExpanded({})
    setPagination(defaultPaginationState)
    setRowSelection({})
    setSelectedRowSet(new Set<TData>())
  }, [autoResetKey, defaultPaginationState, defaultSortingState, searchQuery])

  return {
    debouncedSearchQuery,
    expanded,
    hoveredRow,
    onExpandedChange: setExpanded,
    onHoveredRowChange: setHoveredRow,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSearchQueryChange: setSearchQuery,
    onSortingChange: setSorting,
    pagination,
    rowSelection,
    searchQuery,
    selectedRowSet,
    sorting,
  }
}
