import { Flex } from 'antd'
import {
  FormControlProps,
  getCaptionId,
  getErrorMessageId,
  GovwellFormFieldErrorClassName,
  useBaseAriaAttributes,
} from 'govwell-ui/components/FormControl/util'

import Text, { TextAlign, TextSize } from 'src/components/Typography/Text'

type Props = FormControlProps & {
  children: (props: ReturnType<typeof useBaseAriaAttributes>) => React.ReactNode
}
const FormControl = (props: Props) => {
  const { caption, children, errorMessage, id, isInvalid, isRequired, label, width } = props
  const { ariaDescribedBy, ariaInvalid, ariaLive } = useBaseAriaAttributes(props)

  return (
    <Flex
      vertical
      align="flex-start"
      gap="3px"
      style={{
        position: 'relative',
        width,
        minWidth: 0, // This CSS hack is necessary to get form controls to shrink in flex rows
      }}
    >
      {label && (
        <label htmlFor={id}>
          <Flex>
            {typeof label === 'string' ? <Text colorToken="colorTextLabel">{label}</Text> : label}
            {isRequired && <Text colorToken="red-7">&nbsp;*</Text>}
          </Flex>
        </label>
      )}
      {children({ ariaDescribedBy, ariaInvalid, ariaLive })}
      {isInvalid && errorMessage && id && (
        <div id={getErrorMessageId(id)} className={GovwellFormFieldErrorClassName}>
          {typeof errorMessage === 'string' ? (
            <Text size={TextSize.Small} colorToken="colorError" inline align={TextAlign.Left}>
              {errorMessage}
            </Text>
          ) : (
            errorMessage
          )}
        </div>
      )}
      {caption && id && (
        <div id={getCaptionId(id)}>
          {typeof caption === 'string' ? (
            <Text size={TextSize.Small} colorToken="colorTextLabel" inline align={TextAlign.Left}>
              {caption}
            </Text>
          ) : (
            caption
          )}
        </div>
      )}
    </Flex>
  )
}

export default React.memo(FormControl)
