import { faExclamation, faRefresh, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'

import { useTheme } from '../../../hooks/use-theme'
import { getErrorName } from '../../../utils/file'
import Text, { TextSize } from '../../Typography/Text'

interface Props {
  actionLabel?: string
  actionIcon?: IconDefinition
  errorType: string | undefined
  name: string
  onClick: () => void
}

export const Error = (props: Props) => {
  const { actionLabel = 'Try again', actionIcon = faRefresh, errorType, name, onClick } = props
  const { getTokenVal } = useTheme()

  return (
    <Flex vertical gap="6px" align="center" justify="center">
      <Flex gap="6px" align="center">
        <Text strong>
          <FontAwesomeIcon
            icon={faExclamation}
            style={{
              color: getTokenVal('colorErrorBase'),
            }}
          />
        </Text>
        <Text>Error: {name}</Text>
      </Flex>
      {errorType && <Text size={TextSize.Small}>{getErrorName(errorType)}</Text>}
      <Button
        type="link"
        icon={<FontAwesomeIcon icon={actionIcon} />}
        onClick={onClick}
        size="small"
      >
        {actionLabel}
      </Button>
    </Flex>
  )
}
