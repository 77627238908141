import { useContext } from 'react'

import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Tooltip } from 'antd'
import Button, { ButtonType } from 'antd/es/button'
import { Size } from 'govwell-ui/types'
import styled from 'styled-components'

import { useTheme } from 'src/hooks/use-theme'

import Clock from '../../public/clock.svg'
import EmptyInbox from '../../public/empty-inbox.svg'

import Text, { TextSize } from './Typography/Text'

const Container = styled(Flex)<{ $showBorder: boolean; $size: Size }>`
  width: 100%;
  height: 100%;
  padding: ${({ $size }) => ($size === 'sm' ? '12px' : '60px')};
  border-radius: 8px;
  ${({ $showBorder, theme }) =>
    $showBorder
      ? `
    border: solid 1px ${theme.colorBorder};
  `
      : ''};
  background-color: ${({ theme }) => theme.colorBgContainer};
`

const StyledContainer = styled(Flex)<{ $size: Size }>`
  ${({ $size }) =>
    $size === 'lg' &&
    `
    padding: 60px 0px;
  `}
`
type Props = {
  hideBorder?: boolean
  size?: Size
  children: React.ReactNode
}
const EmptyStateBase = ({ children, hideBorder = false, size = 'md' }: Props) => {
  return (
    <Container align="center" justify="center" $showBorder={!hideBorder} $size={size}>
      <StyledContainer align="center" gap="16px" $size={size} vertical>
        <EmptyStateContext.Provider value={{ size }}>{children}</EmptyStateContext.Provider>
      </StyledContainer>
    </Container>
  )
}

type ImageProps = {
  type?: 'Clock' | 'EmptyInbox'
  icon?: IconDefinition
}
const EmptyStateImage = ({ type = 'EmptyInbox', icon }: ImageProps) => {
  const { getTokenVal } = useTheme()
  const { size } = useEmptyStateContext()
  const dimensions =
    size === 'sm'
      ? {
          width: '80px',
          height: '66px',
        }
      : {
          width: '120px',
          height: '100px',
        }
  return icon ? (
    <FontAwesomeIcon
      icon={icon}
      style={{
        ...dimensions,
        color: getTokenVal('colorBorder'),
      }}
    />
  ) : (
    <img src={type === 'EmptyInbox' ? EmptyInbox : Clock} alt="" {...dimensions} />
  )
}

type MessageProps = {
  children: React.ReactNode
}
const EmptyStateMessage = ({ children }: MessageProps) => {
  return (
    <Text size={TextSize.Base} colorToken="colorTextSecondary" center>
      {children}
    </Text>
  )
}

type ButtonProps = {
  type?: ButtonType
  text: string
  onClick: () => void | Promise<void>
  icon: IconDefinition
  isDisabled?: boolean
  isLoading?: boolean
  tooltip?: string
}
const EmptyStateButton = ({
  type,
  text,
  onClick,
  icon,
  isDisabled,
  isLoading,
  tooltip,
}: ButtonProps) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        type={type}
        onClick={onClick}
        icon={<FontAwesomeIcon icon={icon} />}
        loading={isLoading}
        disabled={isDisabled}
      >
        {text}
      </Button>
    </Tooltip>
  )
}

type EmptyStateContextType = {
  size?: Size
}
const EmptyStateContext = React.createContext<EmptyStateContextType>({} as EmptyStateContextType)
const useEmptyStateContext = () => useContext(EmptyStateContext)

const EmptyState = Object.assign(React.memo(EmptyStateBase), {
  Image: React.memo(EmptyStateImage),
  Message: React.memo(EmptyStateMessage),
  Button: React.memo(EmptyStateButton),
})

export default EmptyState
