import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import {
  useRecordTaskInspectionAttemptSchedulingIntakeFormQuery,
  useRescheduleRecordTaskInspectionAttemptMutation,
} from '../../fetch/recordTaskInspectionAttempts'
import { DateFormats, formatDate } from '../../utils/date'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import { Modal } from '../Modal'
import { VerticalFieldValues } from '../shared/VerticalFieldValues'
import Text, { TextSize } from '../Typography/Text'

import { InspectionMetadata } from './InspectionMetadata'
import { InspectionsTabModalProps } from './types'

interface FormValues {
  date: Dayjs
}

interface Props extends InspectionsTabModalProps {
  currentInspectionDate: string
}

export const RescheduleInspectionModal = (props: Props) => {
  const { modalState, recordTaskInspectionAttemptId, currentInspectionDate, refetch } = props
  const { mutateAsync: reschedule } = useRescheduleRecordTaskInspectionAttemptMutation()
  const onOk = async (formValues: FormValues) => {
    const { date } = formValues
    await reschedule({
      id: recordTaskInspectionAttemptId,
      scheduledFor: dayjs(date).toISOString(),
    })
    await refetch()
    modalState.close()
  }

  const title = `Reschedule Inspection`
  const { intakeFormFieldGroup, isLoading } =
    useRecordTaskInspectionAttemptSchedulingIntakeFormQuery(recordTaskInspectionAttemptId)

  return (
    <Modal {...modalState} form icon={faCalendar} title={title} okText={title} onOk={onOk}>
      <Flex vertical gap="16px">
        <InspectionMetadata {...props} />
        {isLoading && <Skeleton active />}
        {!!intakeFormFieldGroup?.fields?.length && (
          <VerticalFieldValues fields={intakeFormFieldGroup.fields} />
        )}
        <Text size={TextSize.Large}>
          This inspection is currently scheduled for{' '}
          {formatDate(currentInspectionDate, DateFormats.MonthDayFullYear)}. You may reschedule it.
        </Text>
        <DatePicker
          fieldName="date"
          label="New Inspection Date"
          required
          errorMessage="You must enter a date"
          allowedDates={DatePickerAllowedDates.All}
          disableWeekends
          noMargin
        />
      </Flex>
    </Modal>
  )
}
