import { useCallback, useEffect, useMemo, useState } from 'react'

import isNil from 'lodash.isnil'
import omit from 'lodash.omit'
import {
  CreateViewCSVMutation,
  CreateViewCSVMutationVariables,
  CreateViewMutation,
  CreateViewMutationVariables,
  DeleteViewMutation,
  DeleteViewMutationVariables,
  GetColumnTemplatesForViewQuery,
  GetColumnTemplatesForViewQueryVariables,
  GetOrCreateViewsMutation,
  GetOrCreateViewsMutationVariables,
  GetViewDataInput,
  GetViewsDataCountsQuery,
  GetViewsDataCountsQueryVariables,
  GetViewsDataQuery,
  GetViewsDataQueryVariables,
  ReorderViewsMutation,
  ReorderViewsMutationVariables,
  UpdateViewMutation,
  UpdateViewMutationVariables,
  UpdateViewNameMutation,
  UpdateViewNameMutationVariables,
  ViewFragment,
  ViewType,
} from 'types/graphql'

import { TypedDocumentNode } from '@redwoodjs/web'
import { registerFragment } from '@redwoodjs/web/apollo'

import { TableViewDateFilter } from 'src/models/TableViews/TableViewDateFilter'
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'src/utils/queryClient'

registerFragment(gql`
  fragment ViewDataElementFragment on ViewDataElement {
    ... on ViewDataElementBoolean {
      bool
    }
    ... on ViewDataElementDate {
      date
    }
    ... on ViewDataElementDateTime {
      dateTime
    }
    ... on ViewDataElementRecord {
      record {
        id
        uuid
        identifier
        issuedIdentifier
        isDraft
        organization {
          slug
        }
      }
    }
    ... on ViewDataElementRecordActionRequiredBy {
      recordActionRequiredBy
    }
    ... on ViewDataElementRecordTaskGroupType {
      recordTaskGroupType
    }
    ... on ViewDataElementRecordTemplate {
      recordTemplate {
        id
        name
      }
    }
    ... on ViewDataElementRecordTypeStatus {
      recordTypeStatus {
        id
        name
        type
      }
    }
    ... on ViewDataElementString {
      string
    }
    ... on ViewDataElementUser {
      user {
        ...UserDataFragment
      }
    }
    ... on ViewDataElementViolation {
      violation {
        id
        identifier
      }
    }
    ... on ViewDataElementViolationStatus {
      violationStatus
    }
    ... on ViewDataElementViolationType {
      violationType {
        id
        name
      }
    }
    ... on ViewDataElementInspectionTemplate {
      inspectionTemplate {
        id
        name
      }
    }
    ... on ViewDataElementRecordTaskInspectionStatus {
      recordTaskInspectionStatus
    }
    ... on ViewDataElementRecordTaskInspectionResult {
      recordTaskInspectionResult {
        id
        name
        isPassing
      }
    }
    ... on ViewDataElementRecordTaskStatus {
      recordTaskStatus
    }
    ... on ViewDataElementRecordTaskType {
      recordTaskType
    }
    ... on ViewDataElementFeeType {
      fee {
        id
        name
      }
    }
    ... on ViewDataElementFieldType {
      field {
        ...FieldFragment
      }
    }
    ... on ViewDataElementGeneralLedgerNumberType {
      generalLedgerNumber {
        id
        glNumber
      }
    }
    ... on ViewDataElementRecordTypeType {
      recordType {
        id
        displayName
      }
    }
    ... on ViewDataElementRecordTaskPaymentStatusType {
      recordTaskPaymentStatus
    }
    ... on ViewDataElementPaymentMethodType {
      paymentMethod
    }
    ... on ViewDataElementPaymentTransferType {
      paymentTransferType
    }
    ... on ViewDataElementOrganizationFinixMerchantType {
      organizationFinixMerchant {
        id
        description
      }
    }
    ... on ViewDataElementOrganization {
      organization {
        displayName
      }
    }
    ... on ViewDataElementRecordPlansSet {
      recordPlansSet {
        id
        versionNumber
      }
    }
  }
  fragment ViewFragment on View {
    columns {
      ...ViewColumnFragment
    }
    customizableColumnTemplates {
      columnType
      dataType
      labelSentenceCase
      labelTitleCase
    }
    displayIndex
    filters {
      ...ViewFilterFragment
    }
    id
    name
    recordType {
      id
    }
    sorts {
      ...ViewSortFragment
    }
    viewType
  }
  fragment ViewColumnFragment on ViewColumn {
    columnTemplateGeneratedId
  }
  fragment ViewFilterFragment on ViewFilter {
    bool
    columnTemplateGeneratedId
    date {
      ...ViewFilterDateFragment
    }
    optionsSelected
    isNull
  }
  fragment ViewFilterDateFragment on ViewFilterDate {
    exactEnd
    exactStart
    relativeToTodayCount
    relativeToTodayDelayType
    relativeToTodayDirection
    type
  }
  fragment ViewSortFragment on ViewSort {
    columnTemplateGeneratedId
    mode
  }
  fragment ViewColumnTemplateFragment on ViewColumnTemplate {
    columnType
    dataType
    generatedId
    labelSentenceCase
    labelTitleCase
    fieldTemplates {
      ...FieldFragment
    }
    filterOptions {
      ...ViewFilterOptionFragment
    }
    includeByDefault
    isFilterable
    isSortable
    isNullable
    usedInViews {
      ...ViewColumnUsedInViewFragment
    }
  }
  fragment ViewColumnUsedInViewFragment on View {
    id
    name
  }
  fragment ViewFilterOptionFragment on ViewFilterOption {
    data {
      ...ViewDataElementFragment
    }
    identifier
  }
`)

const GetOrCreateViews = gql`
  mutation GetOrCreateViewsMutation($input: GetViewsInput!) {
    getExistingViewsOrCreateDefaultViews(input: $input) {
      ...ViewFragment
    }
  }
`

export const useGetOrCreateViewsMutation = (
  options?: UseMutationOptions<GetOrCreateViewsMutation, GetOrCreateViewsMutationVariables>
) => {
  return useMutation<GetOrCreateViewsMutation, GetOrCreateViewsMutationVariables>({
    mutationDocument: GetOrCreateViews,
    ...options,
  })
}

const GetColumnTemplatesForView = gql`
  query GetColumnTemplatesForViewQuery($input: GetViewsInput!) {
    getColumnTemplatesForView(input: $input) {
      ...ViewColumnTemplateFragment
    }
  }
`

export const useViewsQuery = (args: { viewType: ViewType; recordTypeId?: number }) => {
  const { viewType, recordTypeId } = args
  const [views, setViews] = useState<ViewFragment[]>([])
  const { mutateAsync: getOrCreateViews } = useGetOrCreateViewsMutation({
    onSuccess: (mutationResult) => {
      setViews(
        (mutationResult?.getExistingViewsOrCreateDefaultViews ?? []).sort(
          (a, b) => a.displayIndex - b.displayIndex
        )
      )
    },
  })
  const generateDefaultViews = useCallback(async () => {
    return await getOrCreateViews({
      input: { viewType, recordTypeId },
    })
  }, [getOrCreateViews, recordTypeId, viewType])
  useEffect(() => {
    if (viewType) {
      void generateDefaultViews()
    }
  }, [generateDefaultViews, recordTypeId, viewType])

  const queryResult = useQuery<
    GetColumnTemplatesForViewQuery,
    GetColumnTemplatesForViewQueryVariables
  >({
    queryKey: [QueryKey.Views, args],
    queryDocument: GetColumnTemplatesForView,
    variables: {
      input: {
        viewType: args.viewType,
        recordTypeId: args.recordTypeId,
      },
    },
    refetchOnWindowFocus: false,
  })
  const columnTemplates = useMemo(
    () => queryResult?.data?.getColumnTemplatesForView ?? [],
    [queryResult?.data?.getColumnTemplatesForView]
  )

  const { data: viewsDataCountsResult, isLoading: isLoadingViewsDataCounts } =
    useViewsDataCountsQuery(views)
  const viewsDataCounts = useMemo(
    () => viewsDataCountsResult?.getViewsData ?? [],
    [viewsDataCountsResult?.getViewsData]
  )

  return {
    columnTemplates,
    generateDefaultViews,
    views,
    viewsDataCounts,
    isLoading: queryResult.isLoading || isLoadingViewsDataCounts,
  }
}

export const GetViewsDataCounts: TypedDocumentNode<
  GetViewsDataCountsQuery,
  GetViewsDataCountsQueryVariables
> = gql`
  query GetViewsDataCountsQuery($inputs: [GetViewDataInput!]!) {
    getViewsData(inputs: $inputs) {
      viewId
      totalCount
    }
  }
`

export const useViewsDataCountsQuery = (
  views: ViewFragment[],
  options?: UseQueryOptions<GetViewsDataCountsQuery, GetViewsDataCountsQueryVariables>
) => {
  const inputs: GetViewDataInput[] = views.map((v) => ({
    viewId: v.id,
    viewInput: {
      displayIndex: v.displayIndex,
      name: v.name,
      viewType: v.viewType,

      columns: [], // column data is not needed for counts
      filters: v.filters.map((f) => ({
        bool: f.bool,
        columnTemplateGeneratedId: f.columnTemplateGeneratedId,
        date: TableViewDateFilter.isDatePopulated(f.date) ? omit(f.date, '__typename') : undefined,
        optionsSelected: f.optionsSelected,
        isNull: f.isNull,
      })),
      sorts: v.sorts.map((s) => ({
        columnTemplateGeneratedId: s.columnTemplateGeneratedId,
        mode: s.mode,
      })),
      recordTypeId: v.recordType?.id,
    },
  }))
  return useQuery<GetViewsDataCountsQuery, GetViewsDataCountsQueryVariables>({
    queryKey: [QueryKey.ViewsDataCounts, inputs.map(transformGetViewDataInputForQueryKey)],
    queryDocument: GetViewsDataCounts,
    variables: {
      inputs,
    },
    refetchOnWindowFocus: false,
    ...options,
    enabled: inputs.length > 0 && (isNil(options?.enabled) || options.enabled),
  })
}

const GetViewsData = gql`
  query GetViewsDataQuery($inputs: [GetViewDataInput!]!) {
    getViewsData(inputs: $inputs) {
      totalCount
      rows {
        ...ViewDataRowFragment
      }
    }
  }
  fragment ViewDataRowFragment on ViewDataRow {
    id
    columns {
      ...ViewDataElementFragment
    }
    record {
      ...RecordFragmentOverview
    }
    violation {
      ...ViolationFragmentOverview
    }
    recordTaskInspectionAttempt {
      ...RecordTaskInspectionAttemptFragmentOverview
    }
    recordTask {
      ...RecordTaskFragmentOverview
    }
    recordTaskFee {
      ...RecordTaskFeeFragmentOverview
    }
    recordTaskPayment {
      ...RecordTaskPaymentFragmentOverview
    }
    paymentTransfer {
      ...PaymentTransferFragmentOverview
    }
  }
  fragment ViolationFragmentOverview on Violation {
    id
    identifier
    status
    uuid
    address
    violationMailers {
      id
    }
    locationSnapshot {
      ...LocationSnapshotInDepthFragment
    }
  }
  fragment RecordTaskInspectionAttemptFragmentOverview on RecordTaskInspectionAttempt {
    ...RecordTaskInspectionAttemptFragment
    recordTaskInspection {
      id
      visibleToApplicant
      inspectionGroup {
        record {
          identifier
          issuedIdentifier
          uuid
          applicantFormatted
          addressField {
            ...AddressFieldFragment
          }
          recordTemplate {
            name
            recordTypeId
            recordSettingsDerived {
              inspectionCoordinationType
              inspectionCutoffTime
              inspectionMaxTries
              inspectionSchedulingInstructions
            }
          }
          currentCaseHistory {
            id
            violationType {
              id
              name
            }
          }
        }
        recordTaskGroup {
          id
          isLocked
        }
      }
    }
  }
  fragment RecordTaskFragmentOverview on RecordTask {
    id
    name
    type
    recordTaskGroup {
      id
      record {
        id
        uuid
      }
    }
    ownerUser {
      ...UserDataFragment
    }
  }
  fragment RecordTaskFeeFragmentOverview on RecordTaskFee {
    id
  }
  fragment RecordTaskPaymentFragmentOverview on RecordTaskPayment {
    id
    dueDate
    isPayable
  }
  fragment PaymentTransferFragmentOverview on PaymentTransfer {
    id
  }
`

const transformGetViewDataInputForQueryKey = (i: GetViewDataInput) => ({
  ...i,
  viewInput: omit(i.viewInput, 'name', 'displayIndex'), // avoid refetching on name or ordering changes
})

export const useViewsDataQuery = (inputs: GetViewDataInput[]) => {
  /**
   * TODO: Remove placeholderData once using new DS table after TableV2, which will not update its internal state on effect.
   * The placeholder data is to avoid flashing when switching queries (when you change filters to an uncached query, the cache returns an empty array while loading.)
   * The new table will ignore state updates while isLoading is true so we don't need to use placeholderData on all queries that feed tables.
   */
  const [placeholderData, setPlaceholderData] = useState<GetViewsDataQuery>()
  const queryResult = useQuery<GetViewsDataQuery, GetViewsDataQueryVariables>({
    queryKey: [QueryKey.ViewsData, inputs.map(transformGetViewDataInputForQueryKey)],
    queryDocument: GetViewsData,
    variables: {
      inputs,
    },
    enabled: inputs.every((i) => !!i.viewInput?.columns?.length),
    placeholderData,
  })
  useEffect(() => {
    setPlaceholderData(queryResult.data)
  }, [queryResult.data])

  return queryResult
}

const CreateViewCSV = gql`
  mutation CreateViewCSVMutation($fileName: String!, $input: GetViewDataInput!) {
    createViewCsv(fileName: $fileName, input: $input) {
      csvFileId
      csvUrl
    }
  }
`

export const useCreateViewCSVMutation = () => {
  return useMutation<CreateViewCSVMutation, CreateViewCSVMutationVariables>({
    mutationDocument: CreateViewCSV,
  })
}

const CreateView = gql`
  mutation CreateViewMutation($input: CreateViewInput!) {
    createView(input: $input) {
      ...ViewFragment
    }
  }
`

export const useCreateViewMutation = (
  options?: UseMutationOptions<CreateViewMutation, CreateViewMutationVariables>
) => {
  return useMutation<CreateViewMutation, CreateViewMutationVariables>({
    mutationDocument: CreateView,
    ...options,
  })
}

const UpdateView = gql`
  mutation UpdateViewMutation($id: Int!, $input: CreateViewInput!) {
    updateView(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateViewMutation = (
  options?: UseMutationOptions<UpdateViewMutation, UpdateViewMutationVariables>
) => {
  return useMutation<UpdateViewMutation, UpdateViewMutationVariables>({
    mutationDocument: UpdateView,
    ...options,
  })
}

const UpdateViewName = gql`
  mutation UpdateViewNameMutation($id: Int!, $name: String!) {
    updateViewName(id: $id, name: $name) {
      id
      name
    }
  }
`

export const useUpdateViewNameMutation = (
  options: UseMutationOptions<UpdateViewNameMutation, UpdateViewNameMutationVariables>
) => {
  return useMutation<UpdateViewNameMutation, UpdateViewNameMutationVariables>({
    mutationDocument: UpdateViewName,
    ...options,
  })
}

const DeleteView = gql`
  mutation DeleteViewMutation($id: Int!) {
    deleteView(id: $id)
  }
`

export const useDeleteViewMutation = (
  options?: UseMutationOptions<DeleteViewMutation, DeleteViewMutationVariables>
) => {
  return useMutation<DeleteViewMutation, DeleteViewMutationVariables>({
    mutationDocument: DeleteView,
    ...options,
  })
}

const ReorderViews = gql`
  mutation ReorderViewsMutation($input: ReorderViewsInput!) {
    reorderViews(input: $input)
  }
`

export const useReorderViewsMutation = () => {
  return useMutation<ReorderViewsMutation, ReorderViewsMutationVariables>({
    mutationDocument: ReorderViews,
  })
}
