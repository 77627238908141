import { ReactNode } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Tag } from 'antd'
import Title from 'antd/es/typography/Title'
import { Input } from 'govwell-ui'
import isNil from 'lodash.isnil'
import styled from 'styled-components'

import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

const Container = styled.header<{ $flexWrap: React.CSSProperties['flexWrap'] }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: ${({ $flexWrap }) => $flexWrap};
`

type Props = {
  actions?: React.ReactNode
  count?: number
  onSearchQueryChange?: (value: string) => void
  searchQuery?: string
  title?: ReactNode
  wrap?: React.CSSProperties['flexWrap']
}
const Header = ({ actions, count, onSearchQueryChange, searchQuery = '', title, wrap }: Props) => {
  const { isSmallScreen } = useCurrentBreakpoint()
  const getTitle = () => {
    if (!title) {
      return null
    }
    if (typeof title === 'string') {
      return (
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
      )
    }
    return title
  }
  return (
    <Container $flexWrap={wrap || (isSmallScreen() ? 'wrap' : 'nowrap')}>
      <Flex align="center" gap="12px" wrap="wrap">
        <Flex gap="8px" align="center">
          {getTitle()}
          {!isNil(count) && (
            <Tag color="default" style={{ margin: 0 }}>
              {count}
            </Tag>
          )}
        </Flex>
        {onSearchQueryChange && (
          <Input
            ariaLabel="Search input"
            isClearable
            placeholder="Search"
            prefixIcon={faSearch}
            value={searchQuery}
            onValueChange={onSearchQueryChange}
            width={isSmallScreen() ? '100%' : '240px'}
          />
        )}
      </Flex>
      <Flex flex={1} gap="8px" justify="flex-end" wrap="wrap">
        {actions}
      </Flex>
    </Container>
  )
}

export default React.memo(Header)
