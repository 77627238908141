//paint style

import { LegacyColorPalettes } from 'antd/es/theme/interface'
import { SeedToken } from 'antd/es/theme/internal'

import { ColorPalettes } from './colorPalettes'

export interface ColorTheme extends Partial<SeedToken>, LegacyColorPalettes {
  black: string
  boxShadowSecondary: string
  chartGradient: string
  colorBgBase: string
  colorBgContainer: string
  colorBgContainerDisabled: string
  colorBgElevated: string
  colorBgLayout: string
  colorTableRowHover: string
  colorBgMask: string
  colorBgSpotlight: string
  colorBgTableCellAlt: string
  colorBgTextActive: string
  colorBgTextHover: string
  colorBlack: string
  colorBorder: string
  colorBorderBg: string
  colorBordersecondary: string
  colorBreadcrumbLastItem: string
  colorBreadcrumbNotLastItem: string
  colorError: string
  colorErrorActive: string
  colorErrorBase: string
  colorErrorBg: string
  colorErrorBgHover: string
  colorErrorBorder: string
  colorErrorBorderHover: string
  colorErrorHover: string
  colorErrorOutline: string
  colorErrortext: string
  colorErrortextActive: string
  colorErrortextHover: string
  colorFill: string
  colorFillAlter: string
  colorFillContent: string
  colorFillContentHover: string
  colorFillQuaternary: string
  colorFillSecondary: string
  colorFillTertiary: string
  colorHighlight: string
  colorIcon: string
  colorIconHover: string
  colorInfo: string
  colorInfoActive: string
  colorInfoBase: string
  colorInfoBg: string
  colorInfoBgHover: string
  colorInfoBorder: string
  colorInfoBorderHover: string
  colorInfoHover: string
  colorInfoText: string
  colorInfoTextActive: string
  colorInfoTextHover: string
  colorItemText: string
  colorItemTextDisabled: string
  colorLink: string
  colorLinkActive: string
  colorLinkHover: string
  colorMenuDarkBg: string
  colorMenuSubitemBg: string
  colorPaymentModalBg: string
  colorPrimary: string
  colorPrimaryActive: string
  colorPrimaryBase: string
  colorPrimaryBg: string
  colorPrimaryBgHover: string
  colorPrimaryBorder: string
  colorPrimaryBorderHover: string
  colorPrimaryHover: string
  colorPrimarytext: string
  colorPrimarytextActive: string
  colorPrimarytextHover: string
  colorSidebarCity: string
  colorSplit: string
  colorSuccess: string
  colorSuccessActive: string
  colorSuccessBase: string
  colorSuccessBg: string
  colorSuccessBgHover: string
  colorSuccessBorder: string
  colorSuccessBorderHover: string
  colorSuccessHover: string
  colorSuccesstext: string
  colorSuccesstextActive: string
  colorSuccesstextHover: string
  colorText: string
  colorTextBase: string
  colorTextDescription: string
  colorHoverReorderRow: string
  colorTextDisabled: string
  colorTextHeading: string
  colorTextLabel: string
  colorTextLightSolid: string
  colorTextPlaceholder: string
  colorTextQuaternary: string
  colorTextSecondary: string
  colorTextTertiary: string
  colorWarning: string
  colorWarningActive: string
  colorWarningBase: string
  colorWarningBg: string
  colorWarningBgHover: string
  colorWarningBorder: string
  colorWarningBorderHover: string
  colorWarningHover: string
  colorWarningOutline: string
  colorWarningtext: string
  colorWarningtextActive: string
  colorWarningtextHover: string
  colorWhite: string
  controlItemBgaAtiveHover: string
  controlItemBgActive: string
  controlItemBgActiveDisabled: string
  controlItemBgHover: string
  controlOutline: string
  controlTmpOutline: string
  segmentedBgColorSelected: string
  segmentedGroupBg: string
  tooltipBg: string
  topbarBg: string
  white: string
}

export const Colors: ColorTheme = {
  ...ColorPalettes,
  black: 'rgba(0, 0, 0, 1)',
  boxShadowSecondary:
    '0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  chartGradient: 'support only solid color',
  colorBgBase: 'rgba(255, 255, 255, 1)',
  colorBgContainer: 'rgba(255, 255, 255, 1)',
  colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
  colorBgElevated: 'rgba(255, 255, 255, 1)',
  colorBgLayout: 'rgba(245, 245, 245, 1)',
  colorTableRowHover: 'rgba(240, 240, 240, 1)',
  colorBgMask: 'rgba(0, 0, 0, 0.4)',
  colorBgSpotlight: 'rgba(0, 0, 0, 0.9)',
  colorBgTableCellAlt: 'rgba(251, 251, 251, 1)',
  colorBgTextActive: 'rgba(0, 0, 0, 0.2)',
  colorBgTextHover: 'rgba(0, 0, 0, 0.1)',
  colorBlack: 'rgba(0, 0, 0, 1)',
  colorBorder: 'rgba(0, 0, 0, 0.2)',
  colorBorderBg: 'rgba(255, 255, 255, 1)',
  colorBordersecondary: 'rgba(0, 0, 0, 0.06)',
  colorBreadcrumbLastItem: 'rgb(0, 0, 0, 0.88)',
  colorBreadcrumbNotLastItem: 'rgb(0, 0, 0, 0.45)',
  colorError: 'rgba(255, 77, 79, 1)',
  colorErrorActive: 'rgba(207, 19, 34, 1)',
  colorErrorBase: 'rgba(255, 77, 79, 1)',
  colorErrorBg: 'rgba(255, 241, 240, 1)',
  colorErrorBgHover: 'rgba(255, 204, 199, 1)',
  colorErrorBorder: 'rgba(255, 163, 158, 1)',
  colorErrorBorderHover: 'rgba(255, 120, 117, 1)',
  colorErrorHover: 'rgba(255, 120, 117, 1)',
  colorErrorOutline: 'rgba(255, 241, 240, 1)',
  colorErrortext: 'rgba(130, 0, 20, 1)',
  colorErrortextActive: 'rgba(92, 0, 17, 1)',
  colorErrortextHover: 'rgba(168, 7, 26, 1)',
  colorFill: 'rgba(0, 0, 0, 0.2)',
  colorFillAlter: 'rgba(0, 0, 0, 0.02)',
  colorFillContent: 'rgba(0, 0, 0, 0.06)',
  colorFillContentHover: 'rgba(0, 0, 0, 0.2)',
  colorFillQuaternary: 'rgba(0, 0, 0, 0)',
  colorFillSecondary: 'rgba(0, 0, 0, 0.1)',
  colorFillTertiary: 'rgba(0, 0, 0, 0)',
  colorHighlight: 'rgba(255, 120, 117, 1)',
  colorIcon: 'rgba(0, 0, 0, 0.4)',
  colorIconHover: 'rgba(0, 0, 0, 0.9)',
  colorInfo: 'rgba(22, 119, 255, 1)',
  colorInfoActive: 'rgba(9, 88, 217, 1)',
  colorInfoBase: 'rgba(22, 119, 255, 1)',
  colorInfoBg: 'rgba(230, 244, 255, 1)',
  colorInfoBgHover: 'rgba(186, 224, 255, 1)',
  colorInfoBorder: 'rgba(145, 202, 255, 1)',
  colorInfoBorderHover: 'rgba(105, 177, 255, 1)',
  colorInfoHover: 'rgba(64, 150, 255, 1)',
  colorInfoText: 'rgba(0, 44, 140, 1)',
  colorInfoTextActive: 'rgba(0, 29, 102, 1)',
  colorInfoTextHover: 'rgba(0, 62, 179, 1)',
  colorItemText: 'rgba(255, 255, 255, 0.65)',
  colorItemTextDisabled: 'rgba(255, 255, 255, 0.3)',
  colorLink: 'rgba(250, 84, 28, 1)',
  colorLinkActive: 'rgba(212, 56, 13, 1)',
  colorLinkHover: 'rgba(255, 122, 69, 1)',
  colorMenuDarkBg: 'rgba(10, 46, 87, 1)',
  colorMenuSubitemBg: 'rgba(0, 12, 23, 1)',
  colorPaymentModalBg: 'rgba(255,255,255,.5)',
  colorPrimary: 'rgba(250, 84, 28, 1)',
  colorPrimaryActive: 'rgba(212, 56, 13, 1)',
  colorPrimaryBase: 'rgba(250, 84, 28, 1)',
  colorPrimaryBg: 'rgba(255, 242, 232, 1)',
  colorPrimaryBgHover: 'rgba(255, 216, 191, 1)',
  colorPrimaryBorder: 'rgba(255, 187, 150, 1)',
  colorPrimaryBorderHover: 'rgba(255, 156, 110, 1)',
  colorPrimaryHover: 'rgba(255, 122, 69, 1)',
  colorPrimarytext: 'rgba(135, 20, 0, 1)',
  colorPrimarytextActive: 'rgba(97, 11, 0, 1)',
  colorPrimarytextHover: 'rgba(173, 33, 2, 1)',
  colorSidebarCity: 'rgb(28, 28, 31, 0.25)',
  colorSplit: 'rgba(0, 0, 0, 0.06)',
  colorSuccess: 'rgba(82, 196, 26, 1)',
  colorSuccessActive: 'rgba(56, 158, 13, 1)',
  colorSuccessBase: 'rgba(82, 196, 26, 1)',
  colorSuccessBg: 'rgba(246, 255, 237, 1)',
  colorSuccessBgHover: 'rgba(217, 247, 190, 1)',
  colorSuccessBorder: 'rgba(183, 235, 143, 1)',
  colorSuccessBorderHover: 'rgba(149, 222, 100, 1)',
  colorSuccessHover: 'rgba(115, 209, 61, 1)',
  colorSuccesstext: 'rgba(19, 82, 0, 1)',
  colorSuccesstextActive: 'rgba(9, 43, 0, 1)',
  colorSuccesstextHover: 'rgba(35, 120, 4, 1)',
  colorText: 'rgba(0, 0, 0, 0.88)',
  colorTextBase: 'rgba(0, 0, 0, 1)',
  colorTextDescription: 'rgba(0, 0, 0, 0.70)',
  colorTextDisabled: 'rgba(0, 0, 0, 0.3)',
  colorHoverReorderRow: 'rgba(0, 0, 0, 0.10)',
  colorTextHeading: 'rgba(0, 0, 0, 0.9)',
  colorTextLabel: 'rgba(0, 0, 0, 0.65)',
  colorTextLightSolid: 'rgba(255, 255, 255, 1)',
  colorTextPlaceholder: 'rgba(0, 0, 0, 0.3)',
  colorTextQuaternary: 'rgba(0, 0, 0, 0.3)',
  colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
  colorTextTertiary: 'rgba(0, 0, 0, 0.55)',
  colorWarning: 'rgba(250, 173, 20, 1)',
  colorWarningActive: 'rgba(212, 136, 6, 1)',
  colorWarningBase: 'rgba(250, 173, 20, 1)',
  colorWarningBg: 'rgba(255, 251, 230, 1)',
  colorWarningBgHover: 'rgba(255, 241, 184, 1)',
  colorWarningBorder: 'rgba(255, 229, 143, 1)',
  colorWarningBorderHover: 'rgba(255, 214, 102, 1)',
  colorWarningHover: 'rgba(255, 197, 61, 1)',
  colorWarningOutline: 'rgba(255, 251, 230, 1)',
  colorWarningtext: 'rgba(135, 77, 0, 1)',
  colorWarningtextActive: 'rgba(97, 52, 0, 1)',
  colorWarningtextHover: 'rgba(173, 104, 0, 1)',
  colorWhite: 'rgba(255, 255, 255, 1)',
  controlItemBgaAtiveHover: 'rgba(255, 216, 191, 1)',
  controlItemBgActive: 'rgba(255, 242, 232, 1)',
  controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.04)',
  controlItemBgHover: 'rgba(255, 242, 232, 1)',
  controlOutline: 'rgba(255, 242, 232, 1)',
  controlTmpOutline: 'rgba(0, 0, 0, 0)',
  segmentedBgColorSelected: 'rgba(255, 255, 255, 1)',
  segmentedGroupBg: 'rgba(0, 0, 0, 0)',
  tooltipBg: 'rgba(0, 0, 0, 0.9)',
  topbarBg: 'rgba(0, 29, 102, 1)',
  white: 'rgba(255, 255, 255, 1)',

  fontFamily: 'Public Sans',
  fontFamilyCode: 'Public Sans',
  fontSize: 14,
  lineWidth: 1,
  borderRadius: 6,
  sizeStep: 4,
  sizeUnit: 4,
  sizePopupArrow: 10,
  controlHeight: 32,
  zIndexBase: 0,
  zIndexPopupBase: 1000,
  opacityImage: 100,
}
