import { ReactNode } from 'react'

import { Flex } from 'antd'
import styled, { css } from 'styled-components'

import Text from 'src/components/Typography/Text'

type Props = {
  isOrdered?: boolean
  title?: string
  children: ReactNode
}

const listStyles = css<{ $isOrdered: boolean }>`
  list-style-type: ${({ $isOrdered }) => ($isOrdered ? 'decimal' : 'disc')};
  padding-left: 20px;
  margin: 4px 0;

  & > li {
    margin-bottom: 4px;

    &::marker {
      font-size: 1em;
    }
  }
`

const StyledList = styled.ul<{ $isOrdered: boolean }>`
  ${listStyles}
`

const List = ({ isOrdered = false, title, children }: Props) => {
  return (
    <Flex vertical>
      {title && (
        <Text strong inline={false}>
          {title}
        </Text>
      )}
      <StyledList as={isOrdered ? 'ol' : 'ul'} $isOrdered={isOrdered}>
        {children}
      </StyledList>
    </Flex>
  )
}

const Item = ({ children }: { children: ReactNode }) => {
  return (
    <li>
      <Text>{children}</Text>
    </li>
  )
}

List.Item = Item

export default List
