import { useEffect } from 'react'

import { Select as AntdSelect, Form, SelectProps } from 'antd'

import useDisclosure from 'src/hooks/use-disclosure'

import { InputTypes } from './Input'
import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  placeholder?: string
  onChange?: (value: string[]) => void | Promise<void>
  disabled?: SelectProps['disabled']
  inputType?: InputTypes
}

export function SelectCreate({
  placeholder,
  onChange,
  disabled,
  inputType,
  ...formFieldWrapperProps
}: Props) {
  const { fieldName } = formFieldWrapperProps
  Form.useWatch(fieldName)
  const { getFieldValue, setFieldValue } = Form.useFormInstance()
  const value = getFieldValue(fieldName)

  const { isOpen: hasFocus, open: focus, close: blur } = useDisclosure()

  useEffect(() => {
    const listener = (e: ClipboardEvent) => {
      if (!hasFocus) {
        return
      }
      e.preventDefault()

      const pastedOptions = e.clipboardData?.getData('text').split(/\r\n|\r|\n/)
      if (!pastedOptions?.length) {
        return
      }
      const existingValueSet = new Set<string>(value || [])
      setFieldValue(fieldName, [
        ...(value || []),
        ...pastedOptions.filter((o) => !existingValueSet.has(o)),
      ])
    }
    window.addEventListener('paste', listener)
    return () => window.removeEventListener('paste', listener)
  }, [fieldName, hasFocus, setFieldValue, value])

  const handleChange: SelectProps['onChange'] = async (values: string[]) => {
    if (inputType === InputTypes.Email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const validValues = values.filter((v) => emailRegex.test(v))
      setFieldValue(fieldName, validValues)
    } else {
      setFieldValue(fieldName, values)
    }
  }

  return (
    <Wrapper {...formFieldWrapperProps}>
      <AntdSelect
        mode="tags"
        value={value}
        onChange={onChange || handleChange}
        placeholder={placeholder || formFieldWrapperProps.label}
        disabled={disabled}
        notFoundContent={null}
        onBlur={blur}
        onFocus={focus}
      />
    </Wrapper>
  )
}

export default SelectCreate
