import { useCallback, useMemo } from 'react'

import { Select } from 'govwell-ui'
import { SelectOption } from 'govwell-ui/components/Select/types'
import { observer } from 'mobx-react-lite'
import { FormulaOutputUserType, UserDataFragment } from 'types/graphql'

import { useMyOrgActiveUsersOfType } from 'src/layouts/AppStateContextLayout/utils'
import { formatUserName } from 'src/utils'

type Props = {
  isClearable?: boolean
  isRequired?: boolean
  label?: React.ReactNode
  onValueChange: (value: UserDataFragment | undefined) => void
  type: FormulaOutputUserType | null | undefined
  value: UserDataFragment | null | undefined
  width?: React.CSSProperties['width']
}
const UserSelect = ({
  isClearable,
  isRequired,
  label,
  onValueChange,
  value,
  type,
  width,
}: Props) => {
  const getOptionFromUser = useCallback(
    (user: UserDataFragment): SelectOption<UserDataFragment> => ({
      label: formatUserName(user),
      value: user,
    }),
    []
  )

  const users = useMyOrgActiveUsersOfType(type)
  const options: SelectOption<UserDataFragment>[] = useMemo(
    () => users.map((u) => getOptionFromUser(u)),
    [getOptionFromUser, users]
  )
  const selectedOption = useMemo(
    () => (value ? options.find((o) => o.value.uuid === value.uuid) : undefined),
    [options, value]
  )

  const handleChange = useCallback(
    (option: SelectOption<UserDataFragment> | undefined) => {
      onValueChange(option?.value)
    },
    [onValueChange]
  )

  return (
    <Select
      {...(label ? { label } : { ariaLabel: 'User' })}
      getOptionKey={(o) => o.value.id}
      isClearable={isClearable}
      isRequired={isRequired}
      onSelectedOptionChange={handleChange}
      options={options}
      placeholder="Select user"
      selectedOption={selectedOption}
      width={width}
    />
  )
}

export default observer(UserSelect)
