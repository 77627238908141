import { useCallback, useState } from 'react'

import FormControl from 'govwell-ui/components/FormControl/FormControl'
import { FormControlProps } from 'govwell-ui/components/FormControl/util'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { TextSize, getFontSize } from 'src/components/Typography/Text'

const StyledTextArea = styled.textarea<{
  $resize: React.CSSProperties['resize']
}>`
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  box-sizing: border-box;
  padding: 5px 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  resize: ${({ $resize }) => $resize ?? 'vertical'};
  width: 100%;
`

type Props = FormControlProps & {
  value: string
  onValueChange: (value: string) => void
  placeholder?: string
  resize?: React.CSSProperties['resize']
  rows?: number
}
const TextArea = ({
  ariaLabel,
  caption,
  errorMessage,
  id: propId,
  isRequired,
  label,
  onValueChange,
  placeholder,
  resize,
  rows = 3,
  value,
  width,
}: Props) => {
  const [id] = useState(propId ?? uuid())

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange(e.target.value ?? '')
    },
    [onValueChange]
  )

  return (
    <FormControl
      caption={caption}
      errorMessage={errorMessage}
      id={id}
      isRequired={isRequired}
      label={label}
      width={width}
    >
      {({ ariaDescribedBy, ariaInvalid, ariaLive }) => (
        <StyledTextArea
          $resize={resize}
          aria-describedby={ariaDescribedBy}
          aria-invalid={ariaInvalid}
          aria-label={ariaLabel}
          aria-live={ariaLive}
          id={id}
          onChange={handleChange}
          placeholder={placeholder}
          rows={rows}
          value={value}
        />
      )}
    </FormControl>
  )
}

export default TextArea
