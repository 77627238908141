import { ReactNode, useEffect, useRef } from 'react'

import { Form } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

import { KeyboardKey } from 'src/types'

import useDebounce from '../../hooks/use-debounce'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  placeholder?: string
  onBlur?: (newVal: string) => void | Promise<void>
}

const StyledQuill = styled(ReactQuill)`
  .ql-editor ul {
    display: flex;
    flex-direction: column;
  }
  .ql-editor ol {
    display: flex;
    flex-direction: column;
  }
`

export const RichTextInput = (props: Props) => {
  const { placeholder: _, onBlur, ...formFieldWrapperProps } = props
  const { fieldName } = formFieldWrapperProps

  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  const value = form.getFieldValue(fieldName)
  const debouncedValue = useDebounce(value)
  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) {
      onBlur?.(debouncedValue)
    } else {
      isMounted.current = true
    }
  }, [debouncedValue])

  return (
    <Wrapper {...formFieldWrapperProps}>
      {({ formInstance }) => {
        const { setFieldValue, getFieldValue } = formInstance
        const fieldValue = getFieldValue(fieldName)
        return (
          <StyledQuill
            value={fieldValue}
            onChange={(newVal: string) => setFieldValue(fieldName, newVal)}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
                ['link'],
              ],
              // https://github.com/slab/quill/issues/2905#issuecomment-883724079
              clipboard: {
                matchVisual: false,
              },
            }}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === KeyboardKey.K && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation()
              }
            }}
          />
        )
      }}
    </Wrapper>
  )
}

const RenderedRichTextInputContainer = styled.div`
  ul {
    display: flex;
    flex-direction: column;
  }
  ol {
    display: flex;
    flex-direction: column;
  }
  ol li {
    margin-bottom: 0.5em;
  }
  p {
    margin: 0;
  }
`

interface RenderedRichTextInputProps {
  children: ReactNode
}
export const RenderedRichTextInput = (props: RenderedRichTextInputProps) => {
  const { children } = props

  if (!children) {
    return
  }

  return (
    <RenderedRichTextInputContainer
      dangerouslySetInnerHTML={{ __html: children }}
      className="ql-editor"
    ></RenderedRichTextInputContainer>
  )
}
