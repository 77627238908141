import {
  FieldFragment,
  FieldGroupFragment,
  OrganizationAddressEntryType,
  RecordComponentRecordTaskFragment,
  RecordFragment,
} from 'types/graphql'

export type CustomTaskDisplayInputs = {
  addFilesToPlanReview?: boolean | null
  fieldGroup?: FieldGroupFragment | null
  identifier?: string | null
  organizationUuid: string
  recordTask: RecordComponentRecordTaskFragment
  addressEntryType?: OrganizationAddressEntryType
  workflowStepIndex: number
}

export const getCustomTaskDisplays = (record: RecordFragment): CustomTaskDisplayInputs[] => {
  const res: CustomTaskDisplayInputs[] = []
  const recordTaskGroups = record.recordTaskGroups || []
  const recordType = record.recordTemplate?.recordType
  const organizationUuid = record.organization?.uuid
  if (!organizationUuid) {
    return []
  }
  recordTaskGroups.forEach((rtg, index) => {
    const recordTasks = rtg.recordTasks || []
    const recordTask = rtg.recordTasks[0]
    recordTasks.forEach((rt) => {
      if (rt.status === 'Done' && rt.fieldGroup?.fields?.length) {
        res.push({
          addFilesToPlanReview: recordTask?.recordTaskTemplate?.addFilesToPlanReview,
          fieldGroup: recordTask?.fieldGroup,
          identifier: rtg.recordTaskTemplateGroup?.identifier,
          organizationUuid,
          recordTask: rt,
          addressEntryType: recordType?.addressEntryType,
          workflowStepIndex: index + 1,
        })
      }
    })
  })
  return res
}

// Fall back to field id for migrated fields
export const getFieldNameSuffixForFormSubmittal = (field: FieldFragment) =>
  `${field.basedOnFieldId ?? field.id}`
