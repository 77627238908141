import { useEffect } from 'react'

import {
  GetOrganizationBySlugStateQuery,
  GetOrganizationBySlugStateQueryVariables,
} from 'types/graphql'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

const Query = gql`
  query GetOrganizationBySlugStateQuery($slug: String, $viewportWidth: Float!) {
    organizationBySlug(slug: $slug) {
      ...OrganizationFragmentWithUsers
      shouldExposeViolations
      homePageHeaderText
      homePageSubText
      homePageBackgroundImageUrl(viewportWidth: $viewportWidth)
      homePageBackgroundFile {
        ...FileFragment
      }
    }
  }
`

export const useOrganizationBySlugQuery = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery<
    GetOrganizationBySlugStateQuery,
    GetOrganizationBySlugStateQueryVariables
  >(Query, {
    variables: {
      slug,
      viewportWidth: document.body.offsetWidth || Number.MAX_SAFE_INTEGER,
    },
    skip: !slug,
  })

  const organizationBySlug = data?.organizationBySlug
  useEffect(() => {
    if (slug && !organizationBySlug && !loading) {
      navigate(routes.notFound())
    }
  }, [loading, organizationBySlug, slug])

  return {
    organizationBySlug,
    isLoading: loading,
  }
}
