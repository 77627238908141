import { useMemo } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  PaginationState,
  getSortedRowModel,
  OnChangeFn,
  ColumnSort,
  ColumnDef,
  getPaginationRowModel,
  ExpandedState,
  RowSelectionState,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table'

type Params<TData> = {
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  expanded?: ExpandedState
  getRowIsSelectable?: (row: Row<TData>) => boolean
  getRowCanExpand?: (row: Row<TData>) => boolean
  getRowId: (row: TData, index: number) => string
  isPaginationEnabled: boolean
  isRowExpansionEnabled: boolean
  isRowSelectionEnabled: boolean
  isPaginatedOnClient: boolean
  isSortedOnClient: boolean
  onExpandedChange?: (expanded: ExpandedState) => void
  onPaginationChange?: (pagination: PaginationState) => void
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void
  onSortingChange?: OnChangeFn<ColumnSort[]>
  pagination?: PaginationState
  rowSelection?: RowSelectionState
  sorting?: ColumnSort[]
}
export const useTable = <TData>({
  columns,
  data: _data,
  expanded,
  getRowIsSelectable,
  getRowCanExpand,
  getRowId,
  isPaginationEnabled,
  isRowExpansionEnabled,
  isRowSelectionEnabled,
  isPaginatedOnClient,
  isSortedOnClient,
  pagination,
  onExpandedChange,
  onPaginationChange,
  onRowSelectionChange,
  onSortingChange,
  rowSelection,
  sorting,
}: Params<TData>) => {
  const data = useMemo(() => _data ?? [], [_data])

  const isSortingEnabled = !!data.length && !!onSortingChange

  const tableState = useReactTable<TData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    ...(isRowExpansionEnabled
      ? {
          getExpandedRowModel: getExpandedRowModel(),
          getRowCanExpand: getRowCanExpand || (() => true),
          onExpandedChange: onExpandedChange as OnChangeFn<ExpandedState> | undefined,
        }
      : {}),
    ...(isPaginationEnabled
      ? {
          getPaginationRowModel: getPaginationRowModel(),
          manualPagination: !isPaginatedOnClient, // Manual means we are passing paginated data to the table
        }
      : {}),
    ...(isRowSelectionEnabled
      ? {
          ...(getRowIsSelectable ? { enableRowSelection: getRowIsSelectable } : {}),
          onRowSelectionChange: onRowSelectionChange as OnChangeFn<RowSelectionState> | undefined,
        }
      : {}),
    ...(isSortingEnabled
      ? {
          getSortedRowModel: getSortedRowModel(),
          manualSorting: !isSortedOnClient, // Manual means we are passing sorted data to the table
          onSortingChange,
        }
      : {}),
    state: {
      ...(isRowExpansionEnabled ? { expanded } : {}),
      ...(isPaginationEnabled ? { pagination, onPaginationChange } : {}),
      ...(isRowSelectionEnabled ? { rowSelection } : {}),
      ...(isSortingEnabled
        ? {
            sorting,
          }
        : {}),
    },
  })

  return {
    tableState,
  }
}
