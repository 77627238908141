import { useEffect } from 'react'

import { Skeleton } from 'antd'

import { navigate } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import CreateNewRecordButton from '../../components/Records/CreateNewRecordButton/CreateNewRecordButton'
import Page from '../../components/shared/Page/Page'
import { LoadingSpin } from '../../components/shared/StyledComponents'
import { useRecordTypeQuery } from '../../fetch/recordTypes'
import { useHomePage } from '../../hooks/use-home-page'
import { useAmIInspector, useIsRestrictedReviewer } from '../../layouts/AppStateContextLayout/utils'

import RecordsInspectionsContent from './RecordsInspectionsContent'

type Props = {
  recordTypeId: number
}
const RecordsInspectionsPage = ({ recordTypeId }: Props) => {
  const { data, isLoading } = useRecordTypeQuery(recordTypeId)
  const isRestrictedReviewer = useIsRestrictedReviewer()
  const isInspector = useAmIInspector()
  const homePage = useHomePage()

  const hasPermissions = !isRestrictedReviewer || isInspector

  useEffect(() => {
    if (!hasPermissions) {
      navigate(homePage)
    }
  }, [homePage, hasPermissions])

  if (!hasPermissions) {
    return null
  }

  return (
    <Page>
      <Page.Header
        title="Inspections"
        primaryButton={
          isLoading ? (
            <Skeleton.Input active />
          ) : (
            <CreateNewRecordButton
              category={data?.recordType?.category}
              recordTypeId={recordTypeId}
            />
          )
        }
      />
      <Page.Content>
        <Metadata title="Inspections" />
        {data?.recordType ? (
          <RecordsInspectionsContent recordType={data.recordType} key={recordTypeId} />
        ) : (
          <LoadingSpin />
        )}
      </Page.Content>
    </Page>
  )
}
export default React.memo(RecordsInspectionsPage)
