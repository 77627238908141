import { Tag, TagProps } from 'antd'
import styled from 'styled-components'
import { RecordTypeStatusType } from 'types/graphql'

const NoMarginTag = styled(Tag)`
  margin: 0;
`

interface Props {
  isDraft?: boolean
  status?: {
    name: string
    type: RecordTypeStatusType
  } | null
}

const getColor = (type: RecordTypeStatusType): TagProps['color'] => {
  switch (type) {
    case 'ToDo': {
      return 'blue'
    }
    case 'InProgress': {
      return 'gold'
    }
    case 'Complete': {
      return 'green'
    }
    case 'CompleteNegative': {
      return 'red'
    }
  }
}

export const RecordTypeStatusDisplay = (props: Props) => {
  const { isDraft, status } = props
  if (isDraft) {
    return <NoMarginTag color="default">Draft</NoMarginTag>
  }
  const name = status?.name
  const type = status?.type
  const color = type ? getColor(type) : undefined
  return <NoMarginTag color={color}>{name}</NoMarginTag>
}
