import { FileUploadState, FileUploadStatuses } from '../../hooks/use-file-upload-state'
import { StyledDragger } from '../StyledComponents'

import { Default } from './states/Default'
import { Done } from './states/Done'
import { Error } from './states/Error'
import { Uploading } from './states/Uploading'

export interface FileUploadProps extends FileUploadState {
  accept?: string
  disabled?: boolean
  error?: boolean
  onRemove?: () => void
}

export const FileUpload = (props: FileUploadProps) => {
  const {
    accept,
    disabled,
    size,
    setName,
    type,
    status,
    progress,
    name,
    url,
    thumbnailUrl,
    govWellFileId,
    cancelUpload,
    removeFile,
    beforeUpload,
    error,
    errorType,
    reset,
  } = props

  return (
    <>
      <StyledDragger
        accept={accept}
        className="dragger-class-name"
        $disabled={disabled}
        disabled={disabled}
        name="file"
        action=""
        beforeUpload={beforeUpload}
        openFileDialogOnClick={[
          FileUploadStatuses.Default,
          FileUploadStatuses.Error,
          FileUploadStatuses.Existing,
        ].includes(status)}
        $error={error}
      >
        {status === FileUploadStatuses.Default && <Default accept={accept} disabled={disabled} />}
        {status === FileUploadStatuses.Error && errorType && (
          <Error name={name} errorType={errorType} onClick={reset} />
        )}
        {status === FileUploadStatuses.Uploading && (
          <Uploading progress={progress} cancelUpload={cancelUpload} />
        )}
        {[FileUploadStatuses.Done, FileUploadStatuses.Existing].includes(status) && size && (
          <Done
            id={govWellFileId}
            name={name}
            onRenameFile={setName}
            size={size}
            status={status}
            onRemoveFile={removeFile}
            thumbnailUrl={thumbnailUrl}
            type={type}
            url={url}
          />
        )}
      </StyledDragger>
    </>
  )
}
