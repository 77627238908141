import { Flex } from 'antd'

import Text, { TextSize } from '../Typography/Text'

type Props = {
  inspectionTemplateName: string
  schedulingNotes: string
}
export const InspectionMetadata = React.memo((props: Props) => {
  const { inspectionTemplateName, schedulingNotes } = props

  return (
    <Flex vertical gap="16px">
      <Flex vertical>
        <Text size={TextSize.Base} strong underline>
          Inspection Type
        </Text>
        <Text size={TextSize.Base} margin="0 0 12px">
          {inspectionTemplateName || '{No Name}'}
        </Text>
      </Flex>
      {schedulingNotes && (
        <Flex vertical>
          <Text size={TextSize.Base} underline>
            Notes from Applicant
          </Text>
          <Text size={TextSize.Base} margin="0 0 12px">
            {schedulingNotes}
          </Text>
        </Flex>
      )}
    </Flex>
  )
})
