import React, { useEffect } from 'react'

import { ClerkProvider, useUser } from '@clerk/clerk-react'

import { createAuth } from '@redwoodjs/auth-clerk-web'

import { EnvVariables } from './constants'

export const { AuthProvider: ClerkRwAuthProvider, useAuth } = createAuth()

const ClerkStatusUpdater = () => {
  const { isSignedIn, user, isLoaded } = useUser()
  const { reauthenticate } = useAuth()

  useEffect(() => {
    if (isLoaded) {
      void reauthenticate()
    }
  }, [isSignedIn, user, reauthenticate, isLoaded])

  return null
}

type Props = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  return (
    <ClerkRwAuthProvider>
      <ClerkProvider
        publishableKey={EnvVariables.ClerkPublishableKey}
        localization={{
          formFieldLabel__phoneNumber: 'Mobile Phone Number',
          formFieldInputPlaceholder__phoneNumber: 'Enter your mobile phone number',
        }}
      >
        {children}
        <ClerkStatusUpdater />
      </ClerkProvider>
    </ClerkRwAuthProvider>
  )
}
