import { ReactNode, useCallback, useState } from 'react'

import { faCheck, faHyphen } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Flex } from 'antd'
import FormControl from 'govwell-ui/components/FormControl/FormControl'
import { Size } from 'govwell-ui/types'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import Text, { TextSize } from 'src/components/Typography/Text'

const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  padding: 0px;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  transition: border-color 0.25s;

  &:hover:not([data-disabled]) {
    border-color: ${({ theme }) => theme.colorPrimaryBase};
  }

  &:focus {
    outline-offset: 1px;
    outline: solid 3px ${({ theme }) => theme.controlItemBgaAtiveHover};
  }

  &[data-disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colorBgContainerDisabled};
  }

  &[data-state='checked'] {
    border-color: ${({ theme }) => theme.colorPrimaryBase};
    background-color: ${({ theme }) => theme.colorPrimaryBase};
    color: ${({ theme }) => theme.white};
    svg {
      color: ${({ theme }) => theme.white};
    }
  }
`

const StyledLabel = styled.label<{ $isDisabled?: boolean }>`
  padding-left: 8px; /* Using this instead of gap to continue showing hover highlighting when cursor is on the gap */
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`

type CheckboxBaseProps = {
  caption?: ReactNode
  children?: ReactNode
  errorMessage?: ReactNode
  isDisabled?: boolean
  onValueChange?: (value: true | false | 'indeterminate') => void
  size?: Size
  tabIndex?: number
  value?: true | false | 'indeterminate'
}
const Checkbox = ({
  caption,
  children,
  errorMessage,
  isDisabled,
  onValueChange,
  size = 'md',
  tabIndex,
  value = false,
}: CheckboxBaseProps) => {
  const [id] = useState(uuid())

  const handleCheckedChange = useCallback(
    (newValue: true | false | 'indeterminate') => {
      if (isDisabled) {
        return
      }
      onValueChange?.(newValue)
    },
    [isDisabled, onValueChange]
  )

  return (
    <FormControl label={null} id={id} caption={caption} errorMessage={errorMessage}>
      {({ ariaDescribedBy, ariaInvalid, ariaLive }) => (
        <Flex align="center">
          <StyledCheckbox
            aria-describedby={ariaDescribedBy}
            aria-invalid={ariaInvalid}
            aria-live={ariaLive}
            checked={value}
            disabled={isDisabled}
            id={id}
            onCheckedChange={handleCheckedChange}
            tabIndex={tabIndex}
            {...(tabIndex === -1
              ? {
                  role: 'presentation',
                  ['aria-checked']: false,
                }
              : {})}
          >
            <CheckboxPrimitive.Indicator>
              <FontAwesomeIcon icon={value === 'indeterminate' ? faHyphen : faCheck} />
            </CheckboxPrimitive.Indicator>
          </StyledCheckbox>
          {children ? (
            <StyledLabel htmlFor={id} $isDisabled={isDisabled}>
              <Text size={size === 'md' ? TextSize.Base : TextSize.Small}>{children}</Text>
            </StyledLabel>
          ) : null}
        </Flex>
      )}
    </FormControl>
  )
}

export default Checkbox
