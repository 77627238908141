import { useCallback } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createColumnHelper, Row } from '@tanstack/react-table'
import { Button, Flex } from 'antd'
import { observer } from 'mobx-react-lite'

import { routes } from '@redwoodjs/router'

import { useIsGov } from 'src/layouts/AppStateContextLayout/utils'

import {
  RecordTaskInspectionAttemptFragmentOverview,
  RecordTypeFragment,
  ViewDataRowFragment,
} from '../../../types/graphql'
import {
  InspectionsTableButtons,
  InspectionTableViewResultsType,
} from '../../components/InspectionsTableButtons'
import { ReassignMultipleInspectionsButton } from '../../components/Records/Inspections/ReassignMultipleInspectionsModal'
import ScheduleMultipleInspectionAttemptsModal from '../../components/Records/Inspections/ScheduleMultipleInspectionAttemptsModal'
import { TableLink } from '../../components/shared/StyledComponents'
import TableViewBuilder from '../../components/TableViewBuilder'
import useDisclosure from '../../hooks/use-disclosure'
import { RefetchFnType } from '../../types'

type Props = {
  recordType: RecordTypeFragment
}
const RecordsInspectionsContent = ({ recordType }: Props) => {
  const isGov = useIsGov()
  const getAdditionalColumns = useCallback(
    (refetch: RefetchFnType) => {
      const columnHelper = createColumnHelper<ViewDataRowFragment>()
      return [
        columnHelper.display({
          id: 'actions',
          cell: ({ row: { original } }) => {
            const attempt = original.recordTaskInspectionAttempt
            if (!attempt) {
              return null
            }
            return (
              <InspectionsTableButtons
                attempt={attempt}
                recordTaskInspectionId={attempt.recordTaskInspection?.id ?? 0}
                isVisibleToApplicant={attempt.recordTaskInspection?.visibleToApplicant ?? false}
                refetch={refetch}
                inspectionCoordinationType={
                  attempt.recordTaskInspection?.inspectionGroup?.record?.recordTemplate
                    ?.recordSettingsDerived?.inspectionCoordinationType || 'SchedulingRequest'
                }
                showViewResults={attempt.status === 'Done'}
                viewResultsType={InspectionTableViewResultsType.Attempt}
                isLatestAttempt={!!attempt.isLatestAttempt}
                inspectionCutoffTime={
                  attempt.recordTaskInspection?.inspectionGroup?.record?.recordTemplate
                    ?.recordSettingsDerived?.inspectionCutoffTime
                }
                isLocked={
                  !!attempt.recordTaskInspection?.inspectionGroup?.recordTaskGroup?.isLocked
                }
                isAvailable={isGov} // TODO(IS_AVAILABLE): For now, not allowing citizens to schedule from this view because of the complexity of calculating isAvailable, it's also not the same across all attempts
                addressEntryType={recordType?.addressEntryType}
                recordExpirationDate={original.record?.expirationDate}
              />
            )
          },
        }),
      ]
    },
    [isGov, recordType?.addressEntryType]
  )
  const getLocationSnapshot = useCallback(
    (attempt: RecordTaskInspectionAttemptFragmentOverview) => {
      return attempt.recordTaskInspection?.inspectionGroup?.record?.addressField?.locationSnapshot
    },
    []
  )
  const renderPopup = useCallback((attempt: RecordTaskInspectionAttemptFragmentOverview) => {
    const record = attempt.recordTaskInspection?.inspectionGroup?.record
    if (!record) {
      return null
    }
    return (
      <TableLink href={routes.record({ recordUuid: record?.uuid })}>
        #{record?.issuedIdentifier || record?.identifier}
      </TableLink>
    )
  }, [])
  const getDataFromRow = useCallback((row: ViewDataRowFragment) => {
    return row.recordTaskInspectionAttempt
  }, [])

  const getRowIsSelectable = useCallback((row: Row<ViewDataRowFragment>) => {
    const attempt = row.original.recordTaskInspectionAttempt
    return !!attempt?.isLatestAttempt && !attempt?.result?.isPassing
  }, [])

  const scheduleInspectionsModalState = useDisclosure()
  const renderRowSelection = useCallback(
    (rows: ViewDataRowFragment[], refetch: RefetchFnType) => {
      if (!rows.length) {
        return null
      }
      const recordTaskInspectionAttemptIds = rows.map((r) => r.id)
      return (
        <Flex gap="6px">
          <Button
            icon={<FontAwesomeIcon icon={faCalendar} />}
            onClick={scheduleInspectionsModalState.open}
          >
            Schedule
          </Button>
          <ScheduleMultipleInspectionAttemptsModal
            modalState={scheduleInspectionsModalState}
            recordTaskInspectionAttempts={rows.flatMap((r) => r.recordTaskInspectionAttempt || [])}
            refetch={refetch}
          />
          <ReassignMultipleInspectionsButton
            recordTaskInspectionAttemptIds={recordTaskInspectionAttemptIds}
            refetch={refetch}
          />
        </Flex>
      )
    },
    [scheduleInspectionsModalState]
  )

  return (
    <TableViewBuilder<RecordTaskInspectionAttemptFragmentOverview>
      emptyStateMessage="No inspections found"
      getAdditionalColumns={getAdditionalColumns}
      getDataFromRow={getDataFromRow}
      getRowIsSelectable={getRowIsSelectable}
      map={{ getLocationSnapshot, renderPopup }}
      recordTypeId={recordType.id}
      renderRowSelection={renderRowSelection}
      viewType="RecordTaskInspectionAttemptsForStaffInModule"
    />
  )
}
export default observer(RecordsInspectionsContent)
