import { useCallback, useContext, useEffect, useRef } from 'react'

import { faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Button, Flex } from 'antd'
import styled from 'styled-components'

import Text, { TextSize } from '../../../components/Typography/Text'
import useDisclosure from '../../../hooks/use-disclosure'
import { KeyboardKey } from '../../../types'
import Portal from '../Portal/Portal'

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colorBgMask};
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const AlignmentContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: fit-content;

  margin: 80px 0px;
  @media (max-width: 768px) {
    margin: 20px 0px;
  }

  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Panel = styled(DialogPanel)`
  position: relative;
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadowSecondary};
  padding: 0;
  width: 100%;
  max-width: 500px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  max-height: calc(100vh - 160px);
  @media (max-width: 768px) {
    max-height: calc(100vh - 40px);
  }

  &:focus {
    outline: none;
  }
`

const Title = styled(Dialog.Title)`
  padding: 12px 16px;
  margin: 0;
  border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
`

const CloseButton = styled.button`
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorWhite};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: solid 2px ${({ theme }) => theme.colorPrimaryBorder};
  }

  &:hover {
    color: ${({ theme }) => theme.colorPrimaryBase};
    border-color: ${({ theme }) => theme.colorPrimaryBase};
  }
`

const Main = styled.div`
  padding: 20px;
  overflow-y: auto;
  flex: 1;
`

const Footer = styled.footer`
  padding: 8px 12px;
  border-top: solid 1px ${({ theme }) => theme.colorSplit};
  height: fit-content;
  min-height: fit-content;
`

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  /**
   * Customize which element receives focus when the modal closes; by default it is the element that triggered the modal, but you will need to set this if the trigger no longer exists (e.g. a menu item)
   */
  onCloseFocusRef?: React.RefObject<HTMLElement>
  /**
   * Customize which element receives focus when the modal opens; by default it is the close button
   */
  onOpenFocusRef?: React.RefObject<HTMLElement>
}

const Modal = ({ children, isOpen, onClose, onCloseFocusRef, onOpenFocusRef }: Props) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const handleClose = useCallback(() => {
    if (onCloseFocusRef) {
      onCloseFocusRef.current?.focus()
    }
    onClose()
  }, [onClose, onCloseFocusRef])

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === KeyboardKey.Escape) {
        e.stopPropagation()
        handleClose()
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [handleClose])

  return (
    <Dialog open={isOpen} onClose={() => {}} initialFocus={onOpenFocusRef ?? closeButtonRef}>
      <Portal>
        <Overlay onClick={handleClose} />
        <AlignmentContainer>
          <Panel>
            <ModalContext.Provider value={{ closeButtonRef, onClose: handleClose }}>
              {children}
            </ModalContext.Provider>
          </Panel>
        </AlignmentContainer>
      </Portal>
    </Dialog>
  )
}

type ModalContentProps = {
  children: React.ReactNode
}
const ModalContent = ({ children }: ModalContentProps) => {
  return <Main>{typeof children === 'string' ? <Text>{children}</Text> : children}</Main>
}

type ModalFooterProps =
  | {
      cancelText?: never
      children: React.ReactNode
      confirmText?: never
      isDestructive?: never
      onCancel?: never
      onConfirm?: never
    }
  | {
      cancelText?: string
      children?: never
      confirmText?: string
      isDestructive?: boolean
      onCancel?: () => void
      onConfirm?: () => Promise<unknown>
    }
const ModalFooter = ({
  children,
  cancelText,
  confirmText,
  isDestructive,
  onCancel,
  onConfirm,
}: ModalFooterProps) => {
  const { onClose } = useModalContext()
  const { isOpen: isConfirming, open: startConfirming, close: stopConfirming } = useDisclosure()
  const handleConfirm = useCallback(async () => {
    const fn = onConfirm ?? onClose
    try {
      startConfirming()
      await fn()
      onClose()
    } finally {
      stopConfirming()
    }
  }, [onClose, onConfirm, startConfirming, stopConfirming])

  return (
    <Footer>
      <Flex gap="8px" justify="end">
        {children ? (
          children
        ) : (
          <>
            <Button onClick={onCancel ?? onClose}>{cancelText ?? 'Cancel'}</Button>
            <Button
              type="primary"
              onClick={handleConfirm}
              danger={isDestructive}
              loading={isConfirming}
            >
              {confirmText ?? 'Confirm'}
            </Button>
          </>
        )}
      </Flex>
    </Footer>
  )
}

type ModalHeaderProps = {
  children: React.ReactNode
  icon?: IconDefinition
}
const ModalHeader = ({ children, icon }: ModalHeaderProps) => {
  const { closeButtonRef, onClose } = useModalContext()

  return (
    <header>
      <Title>
        <Flex justify="space-between" align="center">
          <Flex align="center" gap="8px">
            {icon && (
              <Text colorToken="colorPrimaryBase">
                <FontAwesomeIcon icon={icon} />
              </Text>
            )}
            {typeof children === 'string' ? (
              <Text size={TextSize.Large} strong>
                {children}
              </Text>
            ) : (
              children
            )}
          </Flex>
          <CloseButton onClick={onClose} ref={closeButtonRef}>
            <FontAwesomeIcon icon={faXmark} />
          </CloseButton>
        </Flex>
      </Title>
    </header>
  )
}

type ModalContextType = {
  closeButtonRef: React.RefObject<HTMLButtonElement>
  onClose: () => void
}
const ModalContext = React.createContext({} as ModalContextType)
const useModalContext = () => useContext(ModalContext)

export default Object.assign(Modal, {
  Content: ModalContent,
  Footer: ModalFooter,
  Header: ModalHeader,
})
