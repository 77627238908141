import { useCallback, useState } from 'react'

import { Input } from 'govwell-ui'
import mime from 'mime'

import { KeyboardKey } from 'src/types'

type Props = {
  errorMessage: string | null
  fileName: string
  isSaving?: boolean
  label?: string
  onCancel?: () => void
  onErrorMessageChange: (value: string | null) => void
  onFileNameChange: (value: string) => void
  onSave?: () => void
  width?: React.CSSProperties['width']
}
const FileNameInput = ({
  errorMessage,
  fileName,
  isSaving,
  label,
  onCancel,
  onErrorMessageChange,
  onFileNameChange,
  onSave,
  width,
}: Props) => {
  const [originalFileName] = useState(fileName)
  const isInvalid = !fileName || !!errorMessage

  const handleSaveChanges = useCallback(() => {
    if (isInvalid) {
      return
    }

    onSave?.()
  }, [isInvalid, onSave])

  const handleCancelChanges = useCallback(() => {
    onFileNameChange(originalFileName)
    onErrorMessageChange(null)
    onCancel?.()
  }, [onCancel, onErrorMessageChange, onFileNameChange, originalFileName])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!(e.key === KeyboardKey.Escape || e.key === KeyboardKey.Enter)) {
        return
      }

      e.preventDefault()
      e.stopPropagation()
      if (isSaving) {
        return
      }

      if (e.key === KeyboardKey.Enter) {
        handleSaveChanges()
      }
      if (e.key === KeyboardKey.Escape) {
        handleCancelChanges()
      }
    },
    [handleCancelChanges, handleSaveChanges, isSaving]
  )

  const handleValueChange = useCallback(
    (value: string | undefined) => {
      const oldExtType = mime.getType(originalFileName)
      const newExtType = mime.getType(value || '')

      if (oldExtType !== newExtType || newExtType == null) {
        let oldExtension = mime.getExtension(oldExtType || '')
        if (oldExtension === 'jpeg' && originalFileName.endsWith('jpg')) {
          oldExtension = 'jpg'
        }
        onErrorMessageChange?.(`File extension must remain ".${oldExtension}"`)
      } else {
        onErrorMessageChange?.(null)
      }

      if (value?.match(/[^a-zA-Z0-9 .,()_-]/g)) {
        return
      }
      onFileNameChange(value || '')
    },
    [onErrorMessageChange, onFileNameChange, originalFileName]
  )

  const handleBlur = useCallback(() => {
    if (isInvalid) {
      handleCancelChanges()
    } else {
      handleSaveChanges()
    }
  }, [handleCancelChanges, handleSaveChanges, isInvalid])

  return (
    <Input
      autoFocus
      value={fileName}
      isLoading={isSaving}
      label={label}
      onBlur={handleBlur}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onKeyDown={handleKeyDown}
      onValueChange={handleValueChange}
      placeholder="Enter new file name"
      isDisabled={isSaving}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      isRequired
      width={width}
    />
  )
}

export default React.memo(FileNameInput)
