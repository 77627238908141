import { useCallback, useEffect, useRef, useState } from 'react'

import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { ExpandedState, flexRender, Row } from '@tanstack/react-table'
import { useTableContext } from 'govwell-ui/components/Table/context'
import { TableColumnId, TableRowDragState } from 'govwell-ui/components/Table/types'
import { isTableRowDragState } from 'govwell-ui/components/Table/util'
import isNil from 'lodash.isnil'
import styled from 'styled-components'

import { DraggableStyleProps, DraggableStyles } from 'src/components/DragIndicator'
import DropIndicator from 'src/components/DropIndicator'
import { getFontSize, TextSize } from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'

const StyledTBody = styled.tbody<{ $isDragging: boolean } & DraggableStyleProps>`
  ${DraggableStyles}
  tr:nth-child(even) > td {
    background-color: ${({ theme }) => theme.colorBgTableCellAlt};
  }
  tr:nth-child(odd) > td {
    background-color: ${({ theme }) => theme.colorWhite};
  }
`
const StyledTD = styled.td<{
  $horizontalPaddingOverride?: React.CSSProperties['paddingLeft']
  $isLastRow: boolean
  $textAlign?: React.CSSProperties['textAlign']
  $verticalAlign?: React.CSSProperties['verticalAlign']
  $width: React.CSSProperties['width']
}>`
  width: ${({ $width }) => $width};
  ${({ $verticalAlign }) => `vertical-align: ${$verticalAlign ?? 'middle'};`};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  font-weight: 400;
  text-align: ${({ $textAlign }) => $textAlign ?? 'left'};
  padding: ${({ $horizontalPaddingOverride }) =>
    !isNil($horizontalPaddingOverride) ? `8px ${$horizontalPaddingOverride}` : '8px'};
  &:first-child {
    padding-left: ${({ $horizontalPaddingOverride }) =>
      !isNil($horizontalPaddingOverride) ? $horizontalPaddingOverride : '12px'};
  }
  &:last-child {
    padding-right: ${({ $horizontalPaddingOverride }) =>
      !isNil($horizontalPaddingOverride) ? $horizontalPaddingOverride : '12px'};
  }
  color: ${({ theme }) => theme.colorText};

  ${({ $isLastRow, theme }) =>
    !$isLastRow &&
    `
     border-bottom: solid 1px ${theme.colorSplit};
  `};
`
const ExpandedRowTD = styled.td`
  padding: 0px;
  overflow: clip;
  isolation: isolate;
`
const ExpandedRowBoxShadowContainer = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 1;
    box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.colorBorder} inset;
  }
`

type Props<TData> = {
  expanded?: ExpandedState
  getRowExpandedContent?: (value: TData) => React.ReactNode
  isLastRow: boolean
  onHoveredRowChange?: (row: Row<TData> | undefined) => void
  onRowReordered?: (sourceRow: Row<TData>, destinationRow: Row<TData>, closestEdge: Edge) => void
  overrideReorderingWithinSameTableInvariant?: (rowDragState: TableRowDragState<TData>) => boolean
  row: Row<TData>
  tableContainerWidth: React.CSSProperties['width']
  verticalAlign: React.CSSProperties['verticalAlign']
}
const TableRow = <TData,>({
  expanded,
  getRowExpandedContent,
  isLastRow,
  onHoveredRowChange,
  onRowReordered,
  overrideReorderingWithinSameTableInvariant,
  row,
  tableContainerWidth,
  verticalAlign,
}: Props<TData>) => {
  const { isOpen: isDragging, open: startDragging, close: stopDragging } = useDisclosure()
  const { uuid: tableUuid } = useTableContext()

  const invariant = useCallback(
    (dragState: TableRowDragState<TData>) => {
      if (overrideReorderingWithinSameTableInvariant) {
        return overrideReorderingWithinSameTableInvariant(dragState)
      }
      return dragState.tableUuid === tableUuid
    },
    [overrideReorderingWithinSameTableInvariant, tableUuid]
  )

  const draggableRef = useRef<HTMLTableSectionElement>(null)
  const dragHandleRef = useRef(null)
  const getDragData = useCallback(
    (): TableRowDragState<TData> => ({
      row,
      tableUuid,
    }),
    [row, tableUuid]
  )
  useEffect(() => {
    const element = draggableRef.current
    const dragHandle = dragHandleRef.current
    if (!element || !dragHandle || !onRowReordered) {
      return
    }
    return draggable({
      dragHandle,
      element,
      getInitialData: getDragData,
      onDragStart: startDragging,
      onDrop: stopDragging,
    })
  }, [getDragData, onRowReordered, row, startDragging, stopDragging])

  const {
    isOpen: isDropTarget,
    open: prepareForDrop,
    close: stopPreparingForDrop,
  } = useDisclosure()
  const [closestEdge, setClosestEdge] = useState<Extract<Edge, 'top' | 'bottom'>>('top')
  useEffect(() => {
    const element = draggableRef.current
    if (!element || !onRowReordered) {
      return
    }
    return dropTargetForElements({
      canDrop: ({ source }) => {
        const dragData = source.data
        if (
          !isTableRowDragState<TData>(dragData) ||
          !invariant(dragData) ||
          dragData.row.id === row.id
        ) {
          return false
        }
        return true
      },
      element,
      getData: ({ input, element: targetElement }) => {
        const data = attachClosestEdge(getDragData(), {
          input,
          element: targetElement,
          allowedEdges: ['top', 'bottom'],
        })
        const newEdge = extractClosestEdge(data) as Extract<Edge, 'top' | 'bottom'> | undefined
        setClosestEdge(newEdge ?? 'top')
        return data
      },
      getDropEffect: () => 'move',
      onDragEnter: prepareForDrop,
      onDragLeave: stopPreparingForDrop,
      onDrop: stopPreparingForDrop,
    })
  }, [getDragData, invariant, onRowReordered, prepareForDrop, row, stopPreparingForDrop, tableUuid])

  useEffect(() => {
    return monitorForElements({
      onDrop: async ({ source, location }) => {
        const destination = location.current.dropTargets[0]
        if (!destination) {
          return
        }
        const sourceData = source.data
        const destinationData = destination.data
        if (
          !isTableRowDragState<TData>(sourceData) ||
          !isTableRowDragState<TData>(destinationData) ||
          !invariant(sourceData) ||
          !invariant(destinationData) ||
          destinationData.row.id !== row.id
        ) {
          return
        }

        onRowReordered?.(sourceData.row, destinationData.row, closestEdge)
      },
    })
  }, [closestEdge, invariant, onRowReordered, row.id])

  const handleMouseEnter = useCallback(() => onHoveredRowChange?.(row), [onHoveredRowChange, row])
  const handleMouseLeave = useCallback(() => onHoveredRowChange?.(undefined), [onHoveredRowChange])

  return (
    <StyledTBody
      key={row.id}
      $isDragging={isDragging}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={draggableRef}
    >
      {isDropTarget && (
        <tr style={{ display: 'block', maxHeight: 0, overflow: 'visible' }}>
          <td style={{ display: 'block', maxHeight: 0 }}>
            <DropIndicator
              edge={closestEdge}
              fixedLayout={{
                dropTargetBoundingClientRect: draggableRef.current?.getBoundingClientRect(),
                width: tableContainerWidth,
              }}
            />
          </td>
        </tr>
      )}
      <tr>
        {row.getAllCells().map((cell) => {
          const width = cell.column.columnDef.meta?.width
          return (
            <StyledTD
              key={cell.id}
              $horizontalPaddingOverride={
                cell.column.columnDef.meta?.dangerouslyOverrideHorizontalPadding
              }
              $isLastRow={isLastRow}
              $verticalAlign={verticalAlign}
              $textAlign={cell.column.columnDef.meta?.textAlign}
              $width={width ? width : 'auto'}
              ref={cell.column.id === TableColumnId.Reorder ? dragHandleRef : null}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </StyledTD>
          )
        })}
      </tr>
      {typeof expanded !== 'boolean' && !!row.getIsExpanded() && (
        <tr>
          <ExpandedRowTD colSpan={100}>
            <ExpandedRowBoxShadowContainer>
              {getRowExpandedContent?.(row.original) || null}
            </ExpandedRowBoxShadowContainer>
          </ExpandedRowTD>
        </tr>
      )}
    </StyledTBody>
  )
}

export default React.memo(TableRow) as typeof TableRow
