import { makeAutoObservable, toJS } from 'mobx'
import {
  AutomationActionTemplateFragment,
  AutomationActionTemplateInput,
  AutomationActionType,
  DateTemplateFragment,
  EmailTemplateFragment,
} from 'types/graphql'
import { v4 as uuid } from 'uuid'

import { DateTemplate } from 'src/models/Settings/DateTemplate'

export class AutomationActionTemplate {
  public readonly id?: number
  public readonly clientId: string

  public dateTemplate: DateTemplate
  public emailTemplate?: EmailTemplateFragment
  public shouldEmailApplicant: boolean = false
  public shouldEmailOwner: boolean = false
  public shouldEmailSubmitter: boolean = false
  public type: AutomationActionType | undefined

  constructor(args?: {
    dateTemplate?: DateTemplateFragment
    emailTemplate?: EmailTemplateFragment
    id?: number
    shouldEmailApplicant: boolean
    shouldEmailOwner: boolean
    shouldEmailSubmitter: boolean
    type?: AutomationActionType
  }) {
    const {
      dateTemplate,
      emailTemplate,
      id,
      shouldEmailApplicant,
      shouldEmailOwner,
      shouldEmailSubmitter,
      type,
    } = args ?? {}
    this.clientId = uuid()
    this.dateTemplate = dateTemplate ? DateTemplate.fromProtocol(dateTemplate) : new DateTemplate()
    this.emailTemplate = emailTemplate
    this.id = id
    this.shouldEmailApplicant = shouldEmailApplicant || false
    this.shouldEmailOwner = shouldEmailOwner || false
    this.shouldEmailSubmitter = shouldEmailSubmitter || false
    this.type = type
    makeAutoObservable(this)
  }

  /**
   * NOTE: this does not handle invalid states for all types
   */
  public get isInvalid(): boolean {
    return this.dateTemplate.isInvalid || (this.type === 'Notification' && !this.emailTemplate)
  }

  public get protocol(): AutomationActionTemplateInput {
    return {
      delay: toJS(this.dateTemplate.protocol),
      emailTemplateId: this.emailTemplate?.id,
      emailApplicant: this.shouldEmailApplicant,
      emailOwner: this.shouldEmailOwner,
      emailSubmitter: this.shouldEmailSubmitter,
      type: this.type,
    }
  }

  public static fromProtocol(protocol: AutomationActionTemplateFragment): AutomationActionTemplate {
    return new AutomationActionTemplate({
      dateTemplate: protocol.delay || undefined,
      emailTemplate: protocol.emailTemplate || undefined,
      id: protocol.id,
      shouldEmailApplicant: !!protocol.emailApplicant,
      shouldEmailOwner: !!protocol.emailOwner,
      shouldEmailSubmitter: !!protocol.emailSubmitter,
      type: protocol.type || undefined,
    })
  }
}
