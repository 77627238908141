import { ReactNode, useCallback, useMemo } from 'react'

import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table'
import { Table } from 'govwell-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { LocationSnapshotInDepthFragment, ViewDataRowFragment, ViewType } from 'types/graphql'

import { LoadingSpin } from 'src/components/shared/StyledComponents'
import TableViewCell from 'src/components/TableViews/TableViewCell'

import { useTableViewManager } from '../fetch/views/use-table-view-manager'
import { DataMap } from '../pages/DataMap/DataMap'
import { RefetchFnType } from '../types'
import { filterNil } from '../utils'

import TableViewEditor from './TableViews/TableViewEditor'

type Props<T extends { id: number }> = {
  recordTypeId?: number
  viewType: ViewType
  map?: {
    getLocationSnapshot: (datum: T) => LocationSnapshotInDepthFragment | null | undefined
    renderPopup: (datum: T) => ReactNode
  }
  getAdditionalColumns?: (refetch: RefetchFnType) => ColumnDef<ViewDataRowFragment>[]
  getDataFromRow: (row: ViewDataRowFragment) => T | null | undefined
  getRowIsSelectable?: (row: Row<ViewDataRowFragment>) => boolean
  emptyStateMessage: string
  renderRowSelection?: (rows: ViewDataRowFragment[], refetch: RefetchFnType) => ReactNode
  isCsvEnabled?: boolean
}

function TableViewBuilder<T extends { id: number }>({
  getAdditionalColumns,
  getDataFromRow,
  getRowIsSelectable,
  isCsvEnabled = true,
  emptyStateMessage,
  map,
  recordTypeId,
  renderRowSelection,
  viewType,
}: Props<T>) {
  const {
    columnTemplates,
    generateDefaultViews,
    hasViewsDataLoaded,
    hoveredRow,
    isFetchingViewData,
    isLoadingViewData,
    isLoadingViews,
    onHoveredRowChange,
    onPaginationChange,
    onRowSelectionChange,
    onSaveViewChanges,
    onSortingChange,
    pagination,
    refetchDatabaseCountForView,
    refetchViewData,
    rows,
    rowSelection,
    sorting,
    searchQuery,
    setSearchQuery,
    tableViewManager,
  } = useTableViewManager({ recordTypeId, viewType })

  const handleRowActionCompleted = useCallback(async () => {
    await runInAction(async () => {
      const selectedView = tableViewManager.selectedView
      if (!selectedView) {
        return
      }

      onRowSelectionChange({})
      return await Promise.all([refetchDatabaseCountForView(selectedView), refetchViewData()])
    })
  }, [
    onRowSelectionChange,
    refetchDatabaseCountForView,
    refetchViewData,
    tableViewManager.selectedView,
  ])

  const selectedViewColumns = tableViewManager.selectedView?.columnManager.columns
  const columns = useMemo(() => {
    if (!columnTemplates?.length) {
      return []
    }
    const columnHelper = createColumnHelper<ViewDataRowFragment>()
    const baseColumns = filterNil(
      selectedViewColumns?.map((c, index) => {
        if (!c.columnTemplate) {
          return null
        }

        return columnHelper.accessor((row) => row.columns[index], {
          id: c.columnTemplate.generatedId,
          header: c.columnTemplate.labelTitleCase,
          cell: (cell) => <TableViewCell row={cell.row.original} data={cell.getValue()} />,
          enableSorting: c.columnTemplate.isSortable,
        })
      }) ?? []
    )
    const additionalColumns = getAdditionalColumns?.(handleRowActionCompleted) || []
    return [...baseColumns, ...additionalColumns]
  }, [columnTemplates?.length, getAdditionalColumns, handleRowActionCompleted, selectedViewColumns])
  const data = useMemo(() => filterNil(rows.map(getDataFromRow) ?? []), [getDataFromRow, rows])

  const selectedRows = useMemo(() => {
    return rows.filter((row) => !!rowSelection[row.id])
  }, [rowSelection, rows])

  const getRowId = useCallback((r: ViewDataRowFragment) => `${r.id}`, [])

  const isInitialLoading = isLoadingViews || isLoadingViewData || !hasViewsDataLoaded
  return (
    <>
      <TableViewEditor
        generateDefaultViews={generateDefaultViews}
        isLoadingViewData={isLoadingViewData}
        isLoadingViews={isLoadingViews}
        onSaveViewChanges={onSaveViewChanges}
        onSelectedViewChange={refetchDatabaseCountForView}
        refetchViewData={refetchViewData}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        tableViewManager={tableViewManager}
        isCsvEnabled={isCsvEnabled}
      />
      {map && (
        <DataMap<T>
          data={data}
          isLoading={isLoadingViews || isLoadingViewData}
          hoveredId={hoveredRow?.original ? getDataFromRow(hoveredRow.original)?.id : undefined}
          getLocationSnapshot={map.getLocationSnapshot}
          renderPopup={map.renderPopup}
        />
      )}
      <Table
        size="lg"
        {...(renderRowSelection
          ? { actions: renderRowSelection(selectedRows, handleRowActionCompleted) }
          : {})}
        columns={columns}
        data={rows}
        emptyStateImage={isInitialLoading ? null : undefined}
        emptyStateMessage={isInitialLoading ? <LoadingSpin /> : emptyStateMessage}
        enablePagination
        enableRowSelection={!!renderRowSelection}
        getRowId={getRowId}
        getRowIsSelectable={getRowIsSelectable}
        isLoading={isLoadingViews || isFetchingViewData}
        onHoveredRowChange={onHoveredRowChange}
        onPaginationChange={onPaginationChange}
        onRowSelectionChange={onRowSelectionChange}
        onSortingChange={onSortingChange}
        pagination={pagination}
        rowSelection={rowSelection}
        sorting={sorting}
        totalCount={tableViewManager.selectedView?.totalCount}
      />
    </>
  )
}
export default observer(TableViewBuilder)
