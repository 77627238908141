import { Popover } from 'govwell-ui'
import { MenuContentStyles } from 'govwell-ui/components/Menu/Menu'
import styled from 'styled-components'

const StyledMenuContent = styled(Popover.Content).attrs({
  align: 'start',
  side: 'bottom',
  role: 'listbox',
  sideOffset: 1,
  onOpenAutoFocus: (e) => e.preventDefault(),
  onMouseDown: (e) => e.preventDefault(), // Prevent blurring input when selecting items
})<{ $layout?: 'flex-column' | 'grid'; $gridItemWidth?: string }>`
  ${MenuContentStyles};
  max-height: var(--radix-popover-content-available-height);
  width: fit-content;
  gap: 1px;

  ${({ $layout = 'flex-column', $gridItemWidth = '190px' }) =>
    $layout === 'flex-column'
      ? `
    display: flex;
    flex-direction: column;
    min-width: var(--radix-popper-anchor-width);
  `
      : `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${$gridItemWidth}, 1fr));
    gap: 8px;
    max-width: var(--radix-popper-anchor-width);
  `};
`
export default StyledMenuContent
