import { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'antd'
import { Size } from 'govwell-ui/types'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { FormSelectLoadingPlaceholder } from 'src/components/form/shared/LoadingPlaceholder'
import LottieAnimation from 'src/components/Lottie'
import Text, { TextSize } from 'src/components/Typography/Text'
import { useMergeRefs } from 'src/hooks/use-merge-refs'
import { LottiesLoading } from 'src/lotties/loading'

const OuterContainer = styled(Flex)<{
  $height: React.CSSProperties['height']
  $isDisabled: boolean
  $isInvalid: boolean
  $size: Size
  $width: React.CSSProperties['width']
}>`
  position: relative;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  border-radius: 6px;
  height: ${({ $height }) => ($height ? $height : 'auto')};
  width: ${({ $width }) => ($width ? $width : 'auto')};

  ${({ $size }) => {
    switch ($size) {
      case 'sm':
        return `
          min-height: 24px;
          font-size: 10px;
        `
      case 'md':
        return `
          min-height: 32px;
          font-size: 14px;
        `
      case 'lg':
        return `
          min-height: 40px;
          font-size: 18px;
        `
    }
  }}

  ${({ $isDisabled, theme }) =>
    $isDisabled
      ? `
      pointer-events: none;
      background-color: ${theme.controlItemBgActiveDisabled};
  `
      : `
      background-color: ${theme.colorWhite};
      &:hover: {
        border-color: ${theme.colorPrimaryHover};
      }
  `}

  ${({ $isInvalid, theme }) =>
    $isInvalid &&
    `
    border-color: ${theme.colorErrorBase};
  `};

  &:focus-within {
    border-color: ${({ $isInvalid, theme }) =>
      $isInvalid ? theme.colorErrorBase : theme.colorPrimaryBase};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.controlOutline};
  }
`

const InnerContainer = styled(Flex)<{ $size: Size }>`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: visible;
  padding: ${({ $size }) => {
    switch ($size) {
      case 'sm':
        return '0px 12px'
      default:
        return '3px 12px'
    }
  }};
`

const StyledInput = styled.input`
  border: none;
  color: ${({ theme }) => theme.colorText};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamily};
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 40px;
  overflow: visible;

  :read-only {
    cursor: default;
  }

  ::placeholder {
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.colorTextPlaceholder};
  }
`

const StyledXButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0px 4px;
  border-radius: 4px;
  line-height: 0px;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colorPrimaryBorder};
  }
`

const Addon = styled.div<{ $side: 'left' | 'right' }>`
  width: 34px;
  min-width: 34px;
  height: 100%;
  color: ${({ theme }) => theme.colorText};
  background-color: ${({ theme }) => theme.colorFillAlter};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $side, theme }) =>
    $side === 'left'
      ? `border-right: solid 1px ${theme.colorBorder};`
      : `border-left: solid 1px ${theme.colorBorder};`};
`

export type InputBaseProps = {
  ariaActiveDescendant?: React.HTMLProps<HTMLInputElement>['aria-activedescendant']
  ariaControls?: React.HTMLProps<HTMLInputElement>['aria-controls']
  ariaDescribedBy?: React.HTMLProps<HTMLInputElement>['aria-describedby']
  ariaExpanded?: React.HTMLProps<HTMLInputElement>['aria-expanded']
  ariaInvalid?: React.HTMLProps<HTMLInputElement>['aria-invalid']
  ariaLabel?: React.HTMLProps<HTMLInputElement>['aria-label']
  ariaLive?: React.HTMLProps<HTMLInputElement>['aria-live']
  autoFocus?: boolean
  id?: string
  isClearable?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  isLoading?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  leftAddon?: React.ReactNode
  onClear?: () => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onValueChange: (value: string) => void
  placeholder?: string
  prefixIcon?: IconDefinition
  prefix?: ReactNode
  rightAddon?: React.ReactNode
  role?: React.HTMLProps<HTMLInputElement>['role']
  size?: Size
  suffixIcon?: IconDefinition
  suffix?: ReactNode
  type?: HTMLInputElement['type']
  value: string
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
}
const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      ariaActiveDescendant,
      ariaControls,
      ariaDescribedBy,
      ariaExpanded,
      autoFocus,
      ariaInvalid,
      ariaLabel,
      ariaLive,
      id: propId,
      isClearable,
      isDisabled = false,
      isInvalid = false,
      isLoading,
      isReadOnly,
      isRequired,
      leftAddon,
      onBlur,
      onClear,
      onClick,
      onFocus,
      onKeyDown,
      onValueChange,
      placeholder,
      prefix = null,
      prefixIcon,
      rightAddon,
      role,
      size = 'md',
      suffix = null,
      suffixIcon,
      type = 'text',
      value,
      width,
      height,
    }: InputBaseProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergeRefs(ref, inputRef)
    const hasValue = !!value
    const isShowingClearButton = hasValue && isClearable
    const isShowingFullLoadingBar = isLoading && !value?.length
    const [id] = useState(propId ?? uuid())

    const [hasAutoFocused, setHasAutoFocused] = useState(false)
    useEffect(() => {
      if (autoFocus && !isLoading && !hasAutoFocused) {
        inputRef.current?.select()
        setHasAutoFocused(true)
      }
    }, [autoFocus, hasAutoFocused, isLoading])

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(e.target.value)
      },
      [onValueChange]
    )

    const handleClick = useCallback(() => {
      inputRef.current?.focus()
    }, [])

    const handleClear = useCallback(() => {
      onClear?.()
      onValueChange('')
    }, [onClear, onValueChange])

    return (
      <OuterContainer
        align="center"
        $size={size}
        onClick={handleClick}
        $isDisabled={isDisabled}
        $isInvalid={isInvalid}
        $width={width}
        $height={height}
      >
        {leftAddon && <Addon $side="left">{leftAddon}</Addon>}
        <InnerContainer $size={size} align="center" gap="4px">
          {prefixIcon && (
            <Text colorToken="colorIcon">
              <FontAwesomeIcon icon={prefixIcon} />
            </Text>
          )}
          <Flex
            wrap="wrap"
            gap="3px"
            align="center"
            style={{ flexGrow: 1, flexBasis: 0, minWidth: 0, height: '100%' }}
          >
            {prefix}
            {isShowingFullLoadingBar && <FormSelectLoadingPlaceholder />}
            {!isShowingFullLoadingBar && (
              <StyledInput
                aria-activedescendant={ariaActiveDescendant}
                aria-controls={ariaControls}
                aria-describedby={ariaDescribedBy}
                aria-expanded={ariaExpanded}
                aria-invalid={ariaInvalid}
                aria-label={ariaLabel}
                aria-live={ariaLive}
                autoComplete="off"
                autoFocus={autoFocus}
                disabled={isDisabled}
                id={id}
                onBlur={onBlur}
                onChange={handleChange}
                onClick={onClick}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                readOnly={isReadOnly}
                ref={mergedRef}
                required={isRequired}
                role={role}
                type={type}
                value={value}
              />
            )}
            {suffix}
          </Flex>
          {isShowingClearButton && (
            <StyledXButton onClick={handleClear} title="Clear input">
              <Text size={TextSize.Small} colorToken="colorIcon">
                <FontAwesomeIcon icon={faXmark} />
              </Text>
            </StyledXButton>
          )}
          {isLoading && !isShowingFullLoadingBar && (
            // Show this discreet spinner when there's already some search query text you don't want to obfuscate
            <LottieAnimation animationData={LottiesLoading} width={22} height={22} />
          )}
          {suffixIcon && (
            <Text colorToken="colorIcon">
              <FontAwesomeIcon icon={suffixIcon} />
            </Text>
          )}
        </InnerContainer>
        {rightAddon && <Addon $side="right">{rightAddon}</Addon>}
      </OuterContainer>
    )
  }
)

export default InputBase
