import { useCallback, useEffect, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { ColumnSort, Updater } from '@tanstack/react-table'
import { useTableState } from 'govwell-ui/components/Table/use-table-state'
import omit from 'lodash.omit'
import { computed, runInAction } from 'mobx'
import { UpdateViewMutation, ViewDataRowFragment, ViewType } from 'types/graphql'

import {
  GetViewsDataCounts,
  useUpdateViewMutation,
  useViewsDataQuery,
  useViewsQuery,
} from 'src/fetch/views/views'
import useDebounce from 'src/hooks/use-debounce'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

type Args = {
  recordTypeId?: number
  viewType: ViewType
}

export const useTableViewManager = (args: Args) => {
  const { recordTypeId, viewType } = args
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery)

  const [tableViewManager] = useState(
    new TableViewManager({
      columnTemplates: [],
      recordTypeId,
      views: [],
      viewType,
    })
  )

  const {
    hoveredRow,
    pagination,
    onHoveredRowChange,
    onPaginationChange,
    onRowSelectionChange,
    rowSelection,
  } = useTableState<ViewDataRowFragment>({
    autoResetKey: `${tableViewManager.selectedView?.id}-${tableViewManager.selectedView?.filterManager.populatedFilters?.map((f) => JSON.stringify(f.protocol)).join('')}`,
  })

  const sorting: ColumnSort[] = computed(
    () =>
      tableViewManager.selectedView?.sortManager.sorts.map((sort) => ({
        id: sort.columnTemplateGeneratedId,
        desc: sort.mode === 'Desc',
      })) ?? []
  ).get()

  const onSortingChange = useCallback(
    (newSorting: Updater<ColumnSort[]>) => {
      runInAction(() => {
        if (!tableViewManager.selectedView) {
          return
        }
        if (!newSorting) {
          tableViewManager.selectedView.sortManager.sorts = []
        } else {
          const value = newSorting instanceof Function ? newSorting(sorting) : newSorting
          tableViewManager.selectedView.sortManager.sorts = value.map((sort) => ({
            columnTemplateGeneratedId: sort.id,
            mode: sort.desc ? 'Desc' : 'Asc',
          }))
        }
      })
    },
    [sorting, tableViewManager.selectedView]
  )

  const {
    columnTemplates,
    generateDefaultViews,
    views,
    viewsDataCounts,
    isLoading: isLoadingViews,
  } = useViewsQuery({
    viewType,
    recordTypeId: tableViewManager.recordTypeId,
  })
  const {
    data: viewsData,
    isLoading: isLoadingViewData,
    isFetching: isFetchingViewData,
    refetch: refetchViewData,
    isSuccess: hasViewsDataLoaded,
  } = useViewsDataQuery([
    {
      viewInput: tableViewManager.getCreateViewInput(tableViewManager.selectedView?.protocol),
      searchQuery: debouncedSearchQuery,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
    },
  ])
  const viewData = useMemo(() => viewsData?.getViewsData?.[0], [viewsData?.getViewsData])
  const totalCount = viewData?.totalCount
  useEffect(() => {
    runInAction(() => {
      const view = tableViewManager.selectedView
      if (!view || !viewData) {
        return
      }
      view.totalCount = totalCount ?? 0
    })
  }, [tableViewManager.selectedView, totalCount, viewData])

  useEffect(() => {
    runInAction(() => {
      if (!columnTemplates.length || !views.length || !viewsDataCounts.length) {
        return
      }
      if (!tableViewManager.columnTemplates.length) {
        tableViewManager.initializeViews({ columnTemplates, views })
      }
      const countsByViewId = new Map<number, number>(
        viewsDataCounts.map((vc) => [vc.viewId ?? -1, vc.totalCount])
      )
      tableViewManager.views.forEach((v) => {
        v.databaseTotalCount = countsByViewId.get(v.id) ?? 0
      })
    })
  }, [columnTemplates, recordTypeId, tableViewManager, views, viewsDataCounts])

  const handleViewChangesSaved = useCallback(
    (data: UpdateViewMutation) => {
      runInAction(() => {
        tableViewManager.getViewById(data.updateView.id)?.save()
      })
    },
    [tableViewManager]
  )
  const { mutateAsync: updateView } = useUpdateViewMutation({
    onSuccess: handleViewChangesSaved,
  })

  const onSaveViewChanges = useCallback(async () => {
    await runInAction(async () => {
      if (!tableViewManager.selectedView) {
        return
      }
      await updateView({
        id: tableViewManager.selectedView.id,
        input: omit(tableViewManager.selectedView.protocol, 'id'),
      })
    })
  }, [tableViewManager.selectedView, updateView])

  const apolloClient = useApolloClient()
  const refetchDatabaseCountForView = useCallback(
    async (view: TableView) => {
      const result = await runInAction(async () => {
        const viewInput = tableViewManager.getCreateViewInput(view.databaseProtocol)
        return apolloClient.query({
          query: GetViewsDataCounts,
          variables: {
            inputs: {
              viewInput,
            },
          },
        })
      })
      runInAction(() => {
        view.databaseTotalCount = result.data?.getViewsData?.[0]?.totalCount ?? 0
      })
    },
    [apolloClient, tableViewManager]
  )

  const rows = useMemo(() => viewData?.rows ?? [], [viewData?.rows])

  return {
    columnTemplates,
    generateDefaultViews,
    hasViewsDataLoaded,
    hoveredRow,
    isFetchingViewData,
    isLoadingViews,
    isLoadingViewData,
    onHoveredRowChange,
    onPaginationChange,
    onRowSelectionChange,
    onSaveViewChanges,
    onSortingChange,
    pagination,
    refetchDatabaseCountForView,
    refetchViewData,
    rows,
    rowSelection,
    searchQuery,
    setSearchQuery,
    sorting,
    tableViewManager,
  }
}
