import { faSignature } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'

import useDisclosure, { UseDisclosureReturn } from '../../hooks/use-disclosure'
import Modal from '../Modal'
import Text, { TextSize } from '../Typography/Text'

interface Props {
  url: string
  type: 'modal' | 'inline'
}

const SignatureContainerInline = styled.img`
  border: 1px solid ${({ theme }) => theme.colorBorder};
  border-radius: 8px;
  max-width: 40%;
  height: auto;
  display: block;
`

export const RenderedSignature = (props: Props) => {
  const { url, type } = props
  const modalState = useDisclosure()

  if (type === 'inline') {
    return <SignatureContainerInline src={url} />
  }

  return (
    <>
      <Text size={TextSize.Base} colorToken="cyan-7" onClick={modalState.open} pointer>
        View signature
      </Text>
      <SignatureModal url={url} modalState={modalState} />
    </>
  )
}

interface ModalProps extends Omit<Props, 'type'> {
  modalState: UseDisclosureReturn
}

const SignatureContainerModal = styled.img`
  border: 1px solid black;
`

const SignatureModal = (props: ModalProps) => {
  const { modalState, url } = props
  return (
    <Modal
      {...modalState}
      hideCancelButton
      okText="Ok"
      title="Signature"
      icon={faSignature}
      onOk={modalState.close}
      width="fit-content"
    >
      <SignatureContainerModal src={url} />
    </Modal>
  )
}
