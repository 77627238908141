export const RecordSettingsFragment = gql`
  fragment RecordSettingsFragment on RecordSettings {
    advancePastInspectionsMode
    allowAnonymousSubmissions
    defaultInvoiceDueDateTemplate {
      ...DateTemplateFragment
    }
    enableCloseRecord
    inspectionSchedulingIntakeFormFieldGroup {
      id
    }
    inspectionCoordinationType
    inspectionCutoffTime
    inspectionMaxTries
    inspectionSchedulingInstructions
    issuedIdentifierYearCutoff {
      day
      month
    }
    lateFee {
      id
    }
    setExpirationAtIssueDateTemplate {
      ...DateTemplateFragment
    }
    setExpirationAtIssueRecordTypeStatus {
      ...RecordTypeStatusFragment
    }
    trackApplicants
  }
`
