import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { GroupOrderUpdateInput, OrderUpdateInput } from 'types/graphql'

export type DragAndDropItem = {
  id: number
  index: number
}
export type DragAndDropItemWithGroupId = DragAndDropItem & {
  groupId: number
}
type DragAndDropItemGroup = DragAndDropItem & {
  length: number
}

type WithIndex = { index: number }

type GetReorderingIndicesInput = {
  sourceRow: WithIndex
  destinationRow: WithIndex
  closestEdge: Edge
  // min and max to apply to the destination index
  minIndex?: number // default: 0
  maxIndex: number
}
type ReorderingIndices = { sourceIndex: number; destinationIndex: number }

const clamp = (n: number, { min, max }: { min: number; max: number }) =>
  Math.min(Math.max(n, min), max)

// Suppose the user is dragging a table row R downward, and lets go of R while the cursor is inside row X.
// If the user let go LESS than 50% of the way through X, R should be inserted just ABOVE X.
// But if they let go MORE than 50% of the way, R should be inserted BELOW X.
// (Analogous logic applies if the user is dragging upward.)
export function getReorderingIndices({
  sourceRow,
  destinationRow,
  closestEdge,
  minIndex = 0,
  maxIndex,
}: GetReorderingIndicesInput): ReorderingIndices {
  const direction = sourceRow.index < destinationRow.index ? 'down' : 'up'
  const sourceIndex = sourceRow.index
  let destinationIndex = destinationRow.index
  if (closestEdge === 'bottom' && direction === 'up') {
    destinationIndex = destinationIndex + 1
  }
  if (closestEdge === 'top' && direction == 'down') {
    destinationIndex = destinationIndex - 1
  }
  destinationIndex = clamp(destinationIndex, { min: minIndex, max: maxIndex })
  return { sourceIndex, destinationIndex }
}

/**
 * @description Calculate new order of items rearranged within a single group
 */
export const getSingleGroupDragAndDropInputs = ({
  items,
  sourceItem,
  destinationItem,
  closestEdge,
}: {
  items: DragAndDropItem[]
  sourceItem: DragAndDropItem
  destinationItem: DragAndDropItem
  closestEdge: Edge
}): OrderUpdateInput[] => {
  const { sourceIndex, destinationIndex } = getReorderingIndices({
    sourceRow: sourceItem,
    destinationRow: destinationItem,
    closestEdge,
    maxIndex: items.length - 1,
  })

  const inputs: OrderUpdateInput[] = items.map((i) => {
    if (i.id === sourceItem.id) {
      // This item was dragged and dropped
      return {
        id: i.id,
        order: destinationIndex,
      }
    }
    let newIndex = i.index // Base case is that the other items are unmoved
    if (i.index >= destinationIndex && i.index < sourceIndex) {
      // Shift down any items if the item was dragged up
      newIndex = newIndex + 1
    }
    if (i.index <= destinationIndex && i.index > sourceIndex) {
      // Shift up any items if the item was dragged down
      newIndex = newIndex - 1
    }

    return {
      id: i.id,
      order: newIndex,
    }
  })
  return inputs
}

/**
 * @description Calculate new order of items rearranged across multiple groups
 */
export const getMultiGroupDragAndDropInputs = ({
  items,
  sourceItem,
  sourceGroup,
  destinationItem,
  destinationGroup,
  closestEdge,
}: {
  items: DragAndDropItemWithGroupId[]
  sourceItem: DragAndDropItemWithGroupId
  sourceGroup: DragAndDropItemGroup
  destinationItem: DragAndDropItemWithGroupId
  destinationGroup: DragAndDropItemGroup
  closestEdge: Edge
}): GroupOrderUpdateInput[] => {
  const { sourceIndex, destinationIndex } = getReorderingIndices({
    sourceRow: sourceItem,
    destinationRow: destinationItem,
    closestEdge,
    maxIndex:
      sourceGroup.id === destinationGroup.id
        ? destinationGroup.length - 1
        : destinationGroup.length,
  })

  const inputs: GroupOrderUpdateInput[] = []
  items.forEach((i) => {
    let newIndex = i.index
    let newGroupId = i.groupId
    if (i.id === sourceItem.id) {
      // This item was the dragged item, take the destination values
      newIndex = destinationIndex
      newGroupId = destinationGroup.id
    } else if (sourceGroup.id === destinationGroup.id && i.groupId === destinationGroup.id) {
      // This item is in the same group where both dragging and dropping happened
      if (i.index >= destinationIndex && i.index < sourceIndex) {
        // Shift down any items if the item was dragged up
        newIndex = newIndex + 1
      }
      if (i.index <= destinationIndex && i.index > sourceIndex) {
        // Shift up any items if the item was dragged down
        newIndex = newIndex - 1
      }
    } else if (sourceGroup.id !== destinationGroup.id && i.groupId === sourceGroup.id) {
      // This item is in the group where an item was dragged from
      if (i.index > sourceIndex) {
        newIndex = newIndex - 1
      }
    } else if (sourceGroup.id !== destinationGroup.id && i.groupId === destinationGroup.id) {
      // This item is in the group where an item was dragged to
      if (i.index >= destinationIndex) {
        newIndex = newIndex + 1
      }
    }
    inputs.push({
      childId: i.id,
      order: newIndex,
      parentId: newGroupId,
    })
  })
  return inputs
}
