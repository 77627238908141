export const AutomationTemplateFragment = gql`
  fragment AutomationTemplateFragment on AutomationTemplate {
    id
    actions {
      ...AutomationActionTemplateFragment
    }
    triggerType
    triggerInspectionResults {
      ...InspectionResultFragment
    }
  }
  fragment AutomationActionTemplateFragment on AutomationActionTemplate {
    id
    addRecordTaskTemplateGroupId
    beginWorkflowTemplateId
    changeToStatusId
    delay {
      ...DateTemplateFragment
    }
    emailApplicant
    emailOwner
    emailSubmitter
    emailTemplate {
      ...EmailTemplateFragment
    }
    id
    isExpiration
    recipients {
      id
      emailAddress
      user {
        ...UserDataFragment
      }
    }
    type
  }
`
