import React, { ReactNode, useState } from 'react'

import { IconDefinition, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal as AntdModal, Button, Form, FormInstance } from 'antd'
import { getModalRoot } from 'govwell-ui/components/Portal/Portal'
import { styled } from 'styled-components'

import { RequiredMark } from 'src/components/form/RequiredMark'

import { UseDisclosureReturn } from '../hooks/use-disclosure'
import { useTheme } from '../hooks/use-theme'

import Text, { TextSize } from './Typography/Text'

export type Props<TFormValues> = UseDisclosureReturn & {
  title: ReactNode
  icon: IconDefinition
  children?: ReactNode | ((formValues: TFormValues, formMethods: FormInstance) => ReactNode)
  okText?: string | ((formValues: TFormValues) => string)
  onError?: (error: Error) => void
  okDisabled?: boolean | ((formValues?: TFormValues) => boolean)
  hideCancelButton?: boolean
  hideOkButton?: boolean
  resetOnSuccess?: boolean
  watchFields?: string[]
  formInitialValues?: object
  validateOnlyDirtyFields?: boolean
  width?: string | number
  restrictEscapeKey?: boolean // set to true to prevent Escape key from closing modal
  dontResetForm?: boolean
  footer?: React.ReactNode
  cancelText?: string
  isDestructive?: boolean
} & (
    | {
        form: true
        onOk?: (formValues: TFormValues) => void | Promise<void>
      }
    | {
        form?: false
        onOk?: () => void | Promise<void>
      }
  )

const StyledModal = styled(AntdModal)`
  top: 0;
  height: 100vh;

  display: flex;
  align-items: flex-start;
  justify-items: center;

  padding-top: 100px;
  @media (max-width: 768px) {
    padding-top: 20px;
  }

  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-height: 90%;
    padding: 0;
  }
  .ant-modal-header {
    border-bottom: solid 1px ${({ theme }) => theme.colorBorder};
    padding: 20px 24px;
    margin: 0;
  }
  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
    padding: 20px 24px;
  }
  .ant-modal-footer {
    border-top: solid 1px ${({ theme }) => theme.colorBorder};
    padding: 12px 16px;
    margin: 0;
  }
`
const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export function Modal<FormValues>(props: Props<FormValues>) {
  const {
    title,
    icon,
    isOpen,
    close,
    children,
    onOk,
    form,
    hideCancelButton,
    okDisabled,
    watchFields,
    formInitialValues,
    validateOnlyDirtyFields = false,
    hideOkButton,
    onError,
    width,
    restrictEscapeKey,
    dontResetForm,
    footer,
    cancelText,
    isDestructive,
  } = props
  const [formMethods] = Form.useForm()
  const { getTokenVal } = useTheme()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onFormSubmit = async () => {
    if (!form) {
      return
    }
    try {
      setIsSubmitting(true)
      await formMethods.validateFields({
        dirty: validateOnlyDirtyFields,
      })
      await onOk?.(formMethods.getFieldsValue())
      formMethods.resetFields()
      close()
    } catch (e) {
      if (e instanceof Error) {
        onError?.(e)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const onSubmit = async () => {
    if (form) {
      return
    }
    try {
      setIsSubmitting(true)
      await (onOk as () => Promise<void>)?.() /* remove casting after strict mode is turned on */
      close()
    } catch (e) {
      if (e instanceof Error) {
        onError?.(e)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const submit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (form) {
      await onFormSubmit()
    } else {
      await onSubmit()
    }
  }

  const onFormClose = () => {
    if (!dontResetForm) {
      formMethods.resetFields()
    }
    close()
  }

  const fieldsValues = formMethods.getFieldsValue()

  Form.useWatch(watchFields, formMethods)

  const renderedChildren =
    typeof children === 'function' ? children(fieldsValues, formMethods) : children

  const disabled = typeof okDisabled === 'boolean' ? okDisabled : okDisabled?.(fieldsValues)

  const okText =
    typeof props.okText === 'function' ? props.okText(fieldsValues) : props.okText || title

  return (
    <StyledModal
      title={
        <TitleContainer>
          <FontAwesomeIcon
            icon={icon}
            style={{ margin: '0 10px 0 0' }}
            color={getTokenVal('colorPrimaryBase')}
          />
          <Text size={TextSize.Large} strong margin="0 0 0 0">
            {title}
          </Text>
          <Button
            icon={<FontAwesomeIcon icon={faXmark} />}
            size="small"
            style={{ marginLeft: 'auto' }}
            onClick={close}
          />
        </TitleContainer>
      }
      open={isOpen}
      cancelButtonProps={hideCancelButton ? { style: { display: 'none' } } : {}}
      okButtonProps={{
        loading: isSubmitting,
        disabled,
        danger: isDestructive,
        ...(hideOkButton ? { style: { display: 'none' } } : {}),
      }}
      maskClosable
      keyboard={!restrictEscapeKey}
      onCancel={() => {
        if (form) {
          onFormClose()
        } else {
          close()
        }
      }}
      cancelText={cancelText}
      okText={okText}
      width={width || 684}
      onOk={submit}
      styles={{ mask: { backgroundColor: '#0A1A4279' } }}
      footer={footer}
      getContainer={getModalRoot()}
    >
      {form ? (
        <Form
          form={formMethods}
          layout="vertical"
          requiredMark={RequiredMark}
          {...(formInitialValues ? { initialValues: formInitialValues } : {})}
        >
          {renderedChildren}
        </Form>
      ) : (
        renderedChildren
      )}
    </StyledModal>
  )
}

export default Modal
