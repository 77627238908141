import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Flex } from 'antd'

import { FileCard } from 'src/components/FilesUpload/StyledComponents'
import { Done } from 'src/components/FileUpload/states/Done'
import { Error } from 'src/components/FileUpload/states/Error'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { FileUploadStatuses } from '../../hooks/use-file-upload-state'
import {
  FilesUploadState,
  UploadMultipleFilesFileUploadState,
} from '../../hooks/use-files-upload-state'
import { Default } from '../FileUpload/states/Default'
import { Uploading, UploadingHeader } from '../FileUpload/states/Uploading'
import { StyledDragger } from '../StyledComponents'

interface Props extends FilesUploadState {
  accept?: string
  disabled?: boolean
  fieldName?: string
  error?: boolean
}

export const FilesUpload = (props: Props) => {
  const { accept, disabled, setName, states, beforeUpload, removeFile, cancelUpload, error } = props
  const getOverallStatus = () => {
    if (states.length === 0) {
      return FileUploadStatuses.Default
    }
    if (
      states.some(
        (state: UploadMultipleFilesFileUploadState) => state.status === FileUploadStatuses.Uploading
      )
    ) {
      return FileUploadStatuses.Uploading
    }
    return FileUploadStatuses.Done
  }

  const overallFilesUploadStatus = getOverallStatus()

  const getTopContent = () => {
    switch (overallFilesUploadStatus) {
      case FileUploadStatuses.Default:
      case FileUploadStatuses.Done:
        return <Default accept={accept} disabled={disabled} />
      case FileUploadStatuses.Uploading:
        return <UploadingHeader />
    }
  }

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)

  return (
    <>
      <StyledDragger
        accept={accept}
        className="dragger-class-name"
        $disabled={disabled}
        disabled={disabled}
        name="file"
        action=""
        multiple
        beforeUpload={beforeUpload}
        $error={error}
        style={{ marginBottom: states.length ? '12px' : '0' }}
      >
        {getTopContent()}
      </StyledDragger>
      <Flex vertical gap="4px">
        {states.map((state: UploadMultipleFilesFileUploadState, index: number) => {
          if (state.status === FileUploadStatuses.Uploading) {
            return (
              <FileCard $small={small} key={state.id}>
                <Uploading {...state} key={state.id} cancelUpload={() => cancelUpload(index)} />
              </FileCard>
            )
          }
          if (
            state.status === FileUploadStatuses.Done ||
            state.status === FileUploadStatuses.Existing
          ) {
            return (
              <FileCard $small={small} key={state.id}>
                <Done
                  {...state}
                  id={state.govWellFileId}
                  onRemoveFile={() => removeFile(index)}
                  onRenameFile={(fileName: string) => setName(index, fileName)}
                />
              </FileCard>
            )
          }
          if (state.status === FileUploadStatuses.Error) {
            return (
              <FileCard $small={small} key={state.id}>
                <Error
                  key={state.id}
                  {...state}
                  onClick={() => removeFile(index)}
                  actionLabel="Remove file"
                  actionIcon={faTrash}
                />
              </FileCard>
            )
          }
        })}
      </Flex>
    </>
  )
}
