import { S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { datadogRum } from '@datadog/browser-rum'
import { RcFile } from 'antd/es/upload'
import axios, { CancelToken } from 'axios'
import { pdfjs } from 'react-pdf'
import { v4 } from 'uuid'

import {
  CreateFileMutationVariables,
  FileFragment,
  FileUploadCredentialsFragment,
  GetFileUploadCredentialsMutationVariables,
  ThumbnailFileFragment,
} from '../../types/graphql'
import { EnvVariables, MaxFileSize } from '../constants'
import { PathPrefixes } from '../constants/file'
import { FileUploadStatuses } from '../hooks/use-file-upload-state'

import { DateFormats, formatDate } from './date'

import { filterNil, formatBytes } from '.'

interface UploadFileParams {
  file?: RcFile
  fileUrl?: string
  bucket: string
  setFileName: (name: string) => void
  setFileUploadStatus: (status: FileUploadStatuses) => void
  setFileUploadProgress: (progress: number) => void
  setFileSize: (fileSize: number) => void
  setPath: (path: string) => void
  setGovWellFile?: (govWellFile: FileFragment) => void
  cancelToken: CancelToken
  getFileUploadCredentials: (
    input: GetFileUploadCredentialsMutationVariables
  ) => Promise<FileUploadCredentialsFragment | null | undefined>
  createFile: (input: CreateFileMutationVariables) => Promise<ThumbnailFileFragment>
  pathPrefix?: PathPrefixes
  onError?: (errorType: string) => void
}

export const formatFileProgress = (progress: number) => Math.min(99, Math.round(progress * 100))

const isImage = (type: string) => type.startsWith('image/')

export const getFileName = (file: RcFile): string => {
  const typeWord = isImage(file?.type) ? 'Photo' : 'File'
  const now = formatDate(new Date(), DateFormats.NumericalDateTimeConcise)
  return file?.name ?? `${typeWord} uploaded at ${now}`
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export enum FileUploadErrors {
  EncryptedPdf = 'EncryptedPdf',
  TooBig = 'TooBig',
  UnsupportedFileType = 'UnsupportedFileType',
}

// Given a deprecated axios CancelToken, return an "equivalent" AbortController,
// i.e. an AbortController that is aborted when the CancelToken is cancelled.
const getAbortControllerFromCancelToken = (cancelToken: CancelToken): AbortController => {
  const abortController = new AbortController()
  // when `cancelToken.promise` is resolved, invoke `abortController.abort`
  void cancelToken.promise.then((cancel) => abortController.abort(cancel.message))
  return abortController
}

export const uploadFile = async (
  params: UploadFileParams
): Promise<ThumbnailFileFragment | undefined> => {
  const {
    file,
    fileUrl,
    bucket,
    setFileName,
    setFileUploadStatus,
    setFileUploadProgress,
    setFileSize,
    setPath,
    setGovWellFile,
    cancelToken,
    getFileUploadCredentials,
    createFile,
    pathPrefix,
    onError,
  } = params

  // for files where it's a data url (e.g. signature) the name doesn't matter
  const fileName = file ? getFileName(file) : ''

  setFileName(fileName)
  if (file) {
    setFileSize(file.size)
  }
  setFileUploadStatus(FileUploadStatuses.Uploading)

  const getBlobAndMetadata = async (): Promise<{ blob: Blob; size: number; type: string }> => {
    if (file) {
      return {
        blob: new Blob([file], { type: file.type }),
        size: file.size,
        type: file.type,
      }
    }
    if (!fileUrl) {
      throw new Error('fileUrl missing')
    }
    const dataUrlResponse = await axios.get(fileUrl, {
      responseType: 'blob',
    })
    return {
      blob: dataUrlResponse.data,
      size: dataUrlResponse.data.size,
      type: dataUrlResponse.data.type,
    }
  }

  const handleProgress = (progressEvent: {
    loaded?: number
    total?: number
    progress?: number
  }) => {
    const calculatedProgress = progressEvent.total
      ? (progressEvent.loaded || 0) / progressEvent.total
      : 0
    setFileUploadProgress(progressEvent.progress || calculatedProgress)
  }

  const { blob, size, type } = await getBlobAndMetadata()
  const generatedUuid = v4()
  const path = pathPrefix ? `${pathPrefix}/${generatedUuid}` : generatedUuid

  const credentials = await getFileUploadCredentials({ input: { bucket, path } })
  try {
    if (credentials) {
      const client = new S3Client({
        region: credentials.region,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const upload = new Upload({
        client,
        abortController: getAbortControllerFromCancelToken(cancelToken),
        params: {
          Bucket: credentials.bucket,
          Key: credentials.key,
          ContentType: type,
          Body: blob,
        },
      })
      upload.on('httpUploadProgress', handleProgress)
      await upload.done()
    } else {
      const formData = new FormData()
      formData.append('cacheControl', '3600')
      formData.append('', blob)

      const hostedPath = `${EnvVariables.SupabaseProjectUrl}/storage/v1/object/${bucket}/${path}`
      await axios.post(hostedPath, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${EnvVariables.SupabaseApiKey}`,
        },
        onUploadProgress: handleProgress,
        cancelToken,
      })
    }
  } catch (e) {
    datadogRum.addError(e)
    if (e instanceof Error) {
      onError?.(e?.name)
    }
    setFileUploadStatus(FileUploadStatuses.Error)
    return
  }

  try {
    const response = await createFile({
      input: {
        size,
        type,
        name: fileName,
        bucket,
        path,
      },
    })

    setPath(path)
    setFileUploadStatus(FileUploadStatuses.Done)
    setGovWellFile?.(response)

    return response
  } catch (e) {
    if (e instanceof Error) {
      onError?.(e?.name ?? e?.message)
    }
    setFileUploadStatus(FileUploadStatuses.Error)
    return undefined
  }
}

export const getErrorName = (errorType: string): string => {
  switch (errorType) {
    case FileUploadErrors.EncryptedPdf: {
      return 'You may not upload a password-protected PDF.'
    }
    case FileUploadErrors.TooBig: {
      return `Files have a max size of ${formatBytes(MaxFileSize)}.`
    }
    case FileUploadErrors.UnsupportedFileType: {
      return 'File type not supported.'
    }
    default: {
      return `Error: ${errorType}`
    }
  }
}

export const deduplicateFileFragments = (files: FileFragment[]): FileFragment[] => {
  const fileIds = new Set<number>()
  return files.reduce((prev: FileFragment[], curr: FileFragment) => {
    if (fileIds.has(curr.id)) {
      return prev
    }
    fileIds.add(curr.id)
    return [...prev, curr]
  }, [])
}

export const getIsFileExtensionAccepted = (args: { accept?: string; fileName: string }) => {
  const { accept, fileName } = args
  const acceptedFileExtensions = accept?.split(', ')
  return (
    !acceptedFileExtensions?.length ||
    acceptedFileExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))
  )
}

export const getIsEncryptedPdf = async (file: RcFile) => {
  if (file.type !== 'application/pdf') {
    return false
  }
  try {
    const arrayBuffer = await file.arrayBuffer()
    await pdfjs.getDocument(new Uint8Array(arrayBuffer)).promise
    return false
  } catch (e) {
    if (e instanceof Error && e.name === 'PasswordException') {
      return true
    }
    throw e
  }
}

type FileField = {
  file?: {
    id: number
  } | null
  files?: ({ id: number } | null)[] | null
}
export const getFileIdsFromFields = (fields: FileField[] | null | undefined): number[] =>
  filterNil(
    fields?.flatMap((f) => {
      return f.file?.id || f.files?.map((file) => file?.id) || []
    }) ?? []
  )
