import { makeAutoObservable, toJS } from 'mobx'
import {
  AutomationTemplateFragment,
  AutomationTemplateInput,
  AutomationTriggerType,
} from 'types/graphql'

import { AutomationActionTemplate } from 'src/models/Settings/AutomationActionTemplate'

export class AutomationTemplate {
  public triggerType: AutomationTriggerType | undefined
  public readonly triggerInspectionResultIds: number[]

  private _actionTemplates: AutomationActionTemplate[]
  private _databaseValue: AutomationTemplateInput | undefined

  constructor(args?: {
    actionTemplates?: AutomationActionTemplate[]
    triggerInspectionResultIds?: number[]
    triggerType?: AutomationTriggerType
  }) {
    const { actionTemplates, triggerInspectionResultIds, triggerType } = args ?? {}
    this._actionTemplates = actionTemplates ?? []
    this.triggerInspectionResultIds = triggerInspectionResultIds ?? []
    this.triggerType = triggerType

    this.save()
    makeAutoObservable(this)
  }

  public get actionTemplates(): AutomationActionTemplate[] {
    return this._actionTemplates
  }

  public get protocol(): AutomationTemplateInput {
    return {
      actionTemplates: this._actionTemplates.map((at) => toJS(at.protocol)),
      triggerType: this.triggerType,
    }
  }

  public get hasUnsavedChanges(): boolean {
    return JSON.stringify(this._databaseValue) !== JSON.stringify(toJS(this.protocol))
  }

  public addActionTemplate(actionTemplate?: AutomationActionTemplate) {
    this._actionTemplates.push(actionTemplate ?? new AutomationActionTemplate())
  }

  public removeActionTemplate(index: number) {
    this._actionTemplates.splice(index, 1)
  }

  public save() {
    this._databaseValue = toJS(this.protocol)
  }

  static fromProtocol(protocol: AutomationTemplateFragment): AutomationTemplate {
    const actionTemplates = protocol.actions?.map((a) => AutomationActionTemplate.fromProtocol(a))
    return new AutomationTemplate({
      actionTemplates,
      triggerType: protocol.triggerType || undefined,
      triggerInspectionResultIds: protocol.triggerInspectionResults?.map((r) => r.id),
    })
  }
}
