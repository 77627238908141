import { useCallback } from 'react'

import { FormInstance } from 'antd'
import { GovwellFormFieldErrorClassName } from 'govwell-ui/components/FormControl/util'
import isNil from 'lodash.isnil'
import { FieldError } from 'rc-field-form/lib/interface'
import { DateTemplateInput } from 'types/graphql'

import { useNotification } from 'src/components/shared/Notification/NotificationContext'

export const useAnnounceFirstInvalidField = (form?: FormInstance) => {
  const { notification } = useNotification()

  return useCallback(() => {
    const firstInvalidFieldId = form
      ?.getFieldsError()
      .find((field: FieldError) => !!field.errors.length)
      ?.name?.join('_')

    let firstInvalidField: HTMLElement | null
    if (firstInvalidFieldId) {
      // Handles antd form invalid fields
      firstInvalidField = document.getElementById(`${firstInvalidFieldId}`)
    } else {
      // Handles govwell-ui / tanstack form invalid fields
      firstInvalidField = document.querySelector(`.${GovwellFormFieldErrorClassName}`)
    }

    if (!firstInvalidField) {
      return
    }

    firstInvalidField.style.scrollMargin = '40px'
    firstInvalidField.scrollIntoView({
      behavior: 'smooth',
    })
    notification.error({
      message: 'One or more fields is invalid.',
    })
  }, [form, notification])
}

export const validators = {
  dateTemplateInput: (value: DateTemplateInput): string | undefined => {
    if (
      value.type === 'Interval' &&
      (isNil(value.interval?.count) || isNil(value.interval?.type))
    ) {
      return 'Interval is missing one or more fields'
    }
  },
}
