import { useCallback, useMemo, useState } from 'react'

import { faMemo } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Modal, TextArea } from 'govwell-ui'

import {
  useRecordTaskInspectionAttemptSchedulingIntakeFormQuery,
  useUpdateRecordTaskInspectionAttemptMutation,
} from 'src/fetch/recordTaskInspectionAttempts'
import { RefetchFnType } from 'src/types'

import { AcceptedFileTypes } from '../../constants'
import { filterNil } from '../../utils'
import { useAnnounceFirstInvalidField } from '../../utils/forms'
import { makeInitialFormValues } from '../Field/be-to-fe'
import {
  mapFieldToSingleFormFieldInput,
  mapMultipleFieldsToFormFieldInputs,
} from '../Field/fe-to-be'
import { RenderedFormFieldInput } from '../FieldAndFileInputs/RenderedFormFieldInput'
import { FormContainer } from '../shared/StyledComponents'

type Props = {
  attemptId: number
  attemptNotes: string | null | undefined
  isOpen: boolean
  onClose: () => void
  refetch: RefetchFnType
}
const EditInspectionSchedulingIntakeFormModal = ({
  attemptId,
  attemptNotes,
  isOpen,
  onClose,
  refetch,
}: Props) => {
  const [notes, setNotes] = useState(attemptNotes || '')
  const [form] = useForm()

  const { mutateAsync: updateRecordTaskInspection } = useUpdateRecordTaskInspectionAttemptMutation()
  const { intakeFormFieldGroup, isFetching: isLoadingIntakeForm } =
    useRecordTaskInspectionAttemptSchedulingIntakeFormQuery(attemptId)

  const initialValues = useMemo(
    () =>
      makeInitialFormValues({
        fields: intakeFormFieldGroup?.fields ?? [],
        getFieldNameSuffix: (f) => `${f.id}`,
      }),
    [intakeFormFieldGroup?.fields]
  )

  const announceFirstInvalidField = useAnnounceFirstInvalidField(form)
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()
      const schedulingIntakeForm = mapMultipleFieldsToFormFieldInputs({
        fields: filterNil(intakeFormFieldGroup?.fields ?? []),
        getFieldNameSuffix: (f) => `${f.id}`,
        values,
      })
      await updateRecordTaskInspection({
        id: attemptId,
        input: {
          notes,
          schedulingIntakeForm,
        },
      })
      await refetch()
      onClose()
    } catch {
      announceFirstInvalidField()
      return Promise.reject()
    }
  }, [
    announceFirstInvalidField,
    attemptId,
    form,
    intakeFormFieldGroup?.fields,
    notes,
    onClose,
    refetch,
    updateRecordTaskInspection,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header icon={faMemo}>Edit Intake Form</Modal.Header>
      <Modal.Content>
        <Flex vertical gap="24px">
          <TextArea label="Notes" value={notes} onValueChange={setNotes} />
          {isLoadingIntakeForm && <Skeleton active />}
          {!isLoadingIntakeForm && (
            <FormContainer form={form} initialValues={initialValues}>
              <Flex vertical gap="24px">
                {intakeFormFieldGroup?.fields?.map((f) => (
                  <RenderedFormFieldInput
                    key={f.id}
                    formFieldInput={{
                      id: f.id,
                      input: mapFieldToSingleFormFieldInput({ field: f }),
                    }}
                    fieldNameSuffix={`${f.id}`}
                    errorMessage={f.required ? 'Please enter a valid value.' : undefined}
                    acceptFileTypes={AcceptedFileTypes}
                    noMargin
                  />
                ))}
              </Flex>
            </FormContainer>
          )}
        </Flex>
      </Modal.Content>
      <Modal.Footer confirmText="Save changes" onConfirm={handleSubmit} />
    </Modal>
  )
}

export default React.memo(EditInspectionSchedulingIntakeFormModal)
