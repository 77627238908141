import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Company, User } from 'types/graphql'

import { Avatar, AvatarImage } from 'src/components/shared/StyledComponents'
import Text, { TextSize } from 'src/components/Typography/Text'
import { ColorToken } from 'src/constants/theme/types'
import { useTheme } from 'src/hooks/use-theme'
import { useMyUser } from 'src/layouts/AppStateContextLayout/utils'
import { NullablePartial } from 'src/types'
import { getInitials, getInitialsOneStr } from 'src/utils'

type Props = {
  fallbackAvatarIcon?: IconDefinition
  fallbackBorderColorToken?: ColorToken
  fallbackBackgroundColorToken?: ColorToken
  fallbackIconColorToken?: ColorToken
  sizePx?: number
} & (
  | {
      company?: never
      user: NullablePartial<
        Pick<User, 'id' | 'imageUrl' | 'firstName' | 'lastName'> | null | undefined
      >
    }
  | {
      company: NullablePartial<Pick<Company, 'name'>>
      user?: never
    }
)
const UserAvatar = ({
  company,
  fallbackAvatarIcon,
  fallbackBorderColorToken,
  fallbackBackgroundColorToken,
  fallbackIconColorToken,
  sizePx = 40,
  user,
}: Props) => {
  const { imageUrl, firstName, lastName } = user ?? {}
  const companyName = company?.name
  const me = useMyUser()
  const isMe = me?.id === user?.id
  const theme = useTheme()
  if (imageUrl) {
    return <AvatarImage $size={sizePx} src={imageUrl} />
  }
  if (fallbackAvatarIcon) {
    const fallbackIconSizePx = (30 / 60) * sizePx
    return (
      <Avatar
        size={sizePx}
        borderColorToken={fallbackBorderColorToken}
        backgroundColorToken={fallbackBackgroundColorToken ?? 'colorTextPlaceholder'}
      >
        <FontAwesomeIcon
          icon={fallbackAvatarIcon}
          color={fallbackIconColorToken ? theme.getTokenVal(fallbackIconColorToken) : 'white'}
          style={{
            height: `${fallbackIconSizePx}px`,
            width: `${fallbackIconSizePx}px`,
          }}
        />
      </Avatar>
    )
  }
  return (
    <Avatar size={sizePx} backgroundColorToken={isMe ? 'cyan-7' : 'colorTextPlaceholder'}>
      <Text size={TextSize.Small} colorToken="colorTextLightSolid">
        {companyName ? getInitialsOneStr(companyName) : getInitials(firstName, lastName) || '?'}
      </Text>
    </Avatar>
  )
}

export default React.memo(UserAvatar)
