import { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { Popover } from 'govwell-ui'
import FormControl from 'govwell-ui/components/FormControl/FormControl'
import InputBase from 'govwell-ui/components/Input/InputBase'
import { useMenuKeyboardShortcuts } from 'govwell-ui/components/Menu/use-menu-keyboard-shortcuts'
import SelectMenuContent from 'govwell-ui/components/Select/SelectMenuContent'
import SelectMenuItem from 'govwell-ui/components/Select/SelectMenuItem'
import SelectMenuItemGroupLabel from 'govwell-ui/components/Select/SelectMenuItemGroupLabel'
import { SelectBaseProps, SelectOption } from 'govwell-ui/components/Select/types'
import { useSelectKeyboardShortcuts } from 'govwell-ui/components/Select/use-select-keyboard-shortcuts'
import { useFilteredOptions } from 'govwell-ui/components/Select/util'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import EmptyState from 'src/components/EmptyState'
import Text from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'

const StyledLoadingState = styled(Flex).attrs({
  vertical: true,
  gap: '6px',
})`
  width: 100%;
  max-width: var(--radix-popper-anchor-width);
  padding: 6px;
  .ant-skeleton-input {
    height: 32px !important;
    width: 100% !important;
  }
`

type Props<TValue> = SelectBaseProps<TValue> & {
  onSelectedOptionChange: (
    selectedOption: SelectOption<TValue> | undefined
  ) => void | Promise<unknown>
  selectedOption: SelectOption<TValue> | undefined
}
const Select = <TValue,>({
  ariaLabel,
  autoFocus,
  caption,
  errorMessage,
  filterOption,
  getIsOptionDisabled,
  getOptionKey,
  isAsync = false,
  isClearable,
  isDisabled,
  isLoading,
  isReadOnly,
  isRequired,
  label,
  prefix,
  prefixIcon,
  placeholder,
  onBlur: propOnBlur,
  onSearchQueryChange,
  onSelectedOptionChange,
  options,
  selectedOption,
  size,
  width,
}: Props<TValue>) => {
  const [id] = useState(uuid())
  const [menuId] = useState(uuid())
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const { isOpen: hasFocus, open: onFocus, close: onBlur } = useDisclosure()
  const { isOpen, open, close } = useDisclosure()

  const [hasAutoFocused, setHasAutoFocused] = useState(false)
  useEffect(() => {
    if (autoFocus && !isLoading && !hasAutoFocused) {
      open()
      setHasAutoFocused(true)
    }
  }, [autoFocus, hasAutoFocused, isLoading, open])

  const filteredOptions = useFilteredOptions({
    isAsync,
    options,
    searchQuery,
    filterOption,
  })

  const handleOpen = useCallback(() => {
    if (isDisabled) {
      return
    }
    open()
    inputRef.current?.focus()
  }, [isDisabled, open])

  const handleClose = useCallback(() => {
    close()
    setSearchQuery(null)
  }, [close])

  const handleBlur = useCallback(() => {
    onBlur()
    propOnBlur?.()
    handleClose()
  }, [handleClose, onBlur, propOnBlur])

  const handleClear = useCallback(() => {
    void onSelectedOptionChange(undefined)
    onFocus()
  }, [onFocus, onSelectedOptionChange])

  const handleValueChange = useCallback(
    (value: string | undefined) => {
      setSearchQuery(value ?? null)
      setActiveIndex(0)
      open()
    },
    [open]
  )

  useSelectKeyboardShortcuts({
    hasFocus,
    isOpen,
    onClose: handleClose,
    onOpen: handleOpen,
  })

  const handleOptionSelected = useCallback(
    (o: SelectOption<TValue>) => {
      if (getIsOptionDisabled?.(o)) {
        return
      }
      void onSelectedOptionChange(o)
      handleClose()
      setSearchQuery(null)
      setTimeout(() => {
        inputRef.current?.select()
      })
    },
    [getIsOptionDisabled, handleClose, onSelectedOptionChange]
  )

  const activeOption = filteredOptions[activeIndex]
  const { menuItemRefs } = useMenuKeyboardShortcuts({
    activeIndex,
    count: options.length,
    getIsItemDisabled: (index: number) => {
      const option = options[index]
      if (!option) {
        return true
      }
      return !!getIsOptionDisabled?.(option)
    },
    isEnabled: isOpen,
    onEscape: () => {
      handleClose()
      if (selectedOption) {
        const selectedOptionKey = getOptionKey(selectedOption)
        setActiveIndex(options.findIndex((o) => getOptionKey(o) === selectedOptionKey))
      } else {
        setActiveIndex(0)
      }
    },
    onSelect: () => {
      if (!activeOption) {
        return
      }
      handleOptionSelected(activeOption)
    },
    setActiveIndex,
  })

  useEffect(() => {
    // Reset active index when options change
    setActiveIndex(0)
  }, [filteredOptions])

  useEffect(() => {
    // Report changes in value
    onSearchQueryChange?.(searchQuery ?? '')
  }, [onSearchQueryChange, searchQuery])

  const getOptionId = useCallback(
    (option: SelectOption<TValue>) => `${id}-${getOptionKey(option)}`,
    [getOptionKey, id]
  )

  return (
    <FormControl
      caption={caption}
      errorMessage={errorMessage}
      id={id}
      isRequired={isRequired}
      label={label}
      width={width}
    >
      {({ ariaDescribedBy, ariaInvalid, ariaLive }) => (
        <Popover isOpen={isOpen} onOpen={handleOpen} onClose={handleClose}>
          <Popover.Trigger
            asChild
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault() // Prevent closing when clicking to different parts of the input text
              }
            }}
          >
            <div style={{ width: '100%' }}>
              <InputBase
                ariaActiveDescendant={activeOption ? getOptionId(activeOption) : undefined}
                ariaControls={menuId}
                ariaDescribedBy={ariaDescribedBy}
                ariaExpanded={isOpen}
                ariaInvalid={ariaInvalid}
                ariaLabel={ariaLabel}
                ariaLive={ariaLive}
                autoFocus={autoFocus}
                id={id}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                isRequired={isRequired}
                onBlur={handleBlur}
                onClear={handleClear}
                onFocus={onFocus}
                onValueChange={handleValueChange}
                placeholder={selectedOption?.label ?? placeholder}
                prefix={prefix}
                prefixIcon={prefixIcon}
                ref={inputRef}
                role="combobox"
                size={size}
                suffixIcon={faChevronDown}
                value={searchQuery ?? selectedOption?.label ?? ''}
              />
            </div>
          </Popover.Trigger>
          <SelectMenuContent id={menuId}>
            {filteredOptions.map((o, index) => (
              <Fragment key={`${o.group}-${getOptionKey(o)}`}>
                <SelectMenuItemGroupLabel index={index} option={o} options={options} />
                <SelectMenuItem
                  id={getOptionId(o)}
                  $isDisabled={getIsOptionDisabled?.(o)}
                  key={getOptionKey(o)}
                  onMouseOver={() => setActiveIndex(index)}
                  onClick={() => handleOptionSelected(o)}
                  ref={(el) => menuItemRefs.current.set(index, el)}
                  {...(index === activeIndex
                    ? {
                        ['data-selected']: true, // controls option remaining highlighted when submenu is expanded
                        ['aria-selected']: true,
                      }
                    : {})}
                >
                  {o.customDisplay ? (
                    o.customDisplay(o.value)
                  ) : (
                    <Text
                      color="inherit"
                      strong={!!selectedOption && getOptionKey(o) === getOptionKey(selectedOption)}
                      lineHeight="0"
                    >
                      {o.label}
                    </Text>
                  )}
                </SelectMenuItem>
              </Fragment>
            ))}
            {!filteredOptions.length && !isLoading && (
              <EmptyState hideBorder size="sm">
                <EmptyState.Image />
                <EmptyState.Message>No options found</EmptyState.Message>
              </EmptyState>
            )}
            {!filteredOptions.length && isLoading && (
              <StyledLoadingState>
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
              </StyledLoadingState>
            )}
          </SelectMenuContent>
        </Popover>
      )}
    </FormControl>
  )
}

export default React.memo(Select) as typeof Select
