import { IconDefinition, faIdBadge, faUser } from '@fortawesome/pro-regular-svg-icons'
import { SizeType } from 'antd/es/config-provider/SizeContext'

import { ContactFieldParams, FieldParamsContact } from '../../../types/graphql'
import { makeFieldNameForFieldFragment } from '../Field/util'
import Input, { InputTypes } from '../form/Input'
import StateSelect from '../form/shared/StateSelect'

import FormFieldErrorBoundary from './FormFieldErrorBoundary'
import { ContactFieldNameDisplayMap } from './modals/ContactInputs'
import { ContactFieldName } from './modals/FieldInputUtil'
import { RenderedFormFieldInputProps } from './types'

interface InputConfig {
  type?: InputTypes
  prefixIcon?: IconDefinition
}

const ErrorMessage = 'You cannot leave this field blank'

export const RenderedContactInputs = (props: RenderedFormFieldInputProps) => {
  const { parameters, fieldNameSuffix, label, fieldName: _, ...rest } = props
  const render = (fieldName: ContactFieldName, inputConfig?: InputConfig) => {
    const formattedFieldName = makeFieldNameForFieldFragment(fieldNameSuffix)
    let config = parameters?.contact?.[fieldName as keyof FieldParamsContact] as ContactFieldParams
    switch (fieldName) {
      case ContactFieldName.CompanyName:
        if (parameters?.contact?.businessName) {
          config = parameters?.contact?.businessName
        }
        break
      case ContactFieldName.Email:
        if (parameters?.contact?.emailAddress) {
          config = parameters?.contact?.emailAddress
        }
        break
    }
    const shouldShow = config?.isPresent
    const required = config?.isRequired
    if (!shouldShow) {
      return []
    }

    return [
      <FormFieldErrorBoundary key={`${formattedFieldName}-${fieldName}`}>
        <Input
          {...rest}
          size="large"
          required={required || false}
          label={`${label} - ${ContactFieldNameDisplayMap[fieldName]}`}
          fieldName={[formattedFieldName, fieldName]}
          type={inputConfig?.type}
          prefixIcon={inputConfig?.prefixIcon}
          errorMessage={required ? ErrorMessage : ''}
        />
      </FormFieldErrorBoundary>,
    ]
  }

  return [
    ...render(ContactFieldName.FirstName, { prefixIcon: faUser }),
    ...render(ContactFieldName.LastName, { prefixIcon: faUser }),
    // there's a bug here, this should be businessName, not companyName
    ...render(ContactFieldName.CompanyName),
    ...render(ContactFieldName.PhoneNumber, { type: InputTypes.Phone }),
    ...render(ContactFieldName.FaxNumber, { type: InputTypes.Phone }),
    ...render(ContactFieldName.Email, { type: InputTypes.Email }),
    ...render(ContactFieldName.Title, { prefixIcon: faIdBadge }),
    ...Address(props),
  ]
}

export enum ContactAddressFields {
  Line1 = 'addressLine1',
  Line2 = 'addressLine2',
  City = 'city',
  State = 'state',
  Zip = 'zip',
}

const Address = (props: RenderedFormFieldInputProps) => {
  const { parameters, fieldNameSuffix, label, fieldName: _, placeholder: __, ...rest } = props
  const formattedFieldName = makeFieldNameForFieldFragment(fieldNameSuffix)
  const config = parameters?.contact?.address
  const shouldShow = config?.isPresent
  const required = config?.isRequired || false

  if (!shouldShow) {
    return []
  }

  const size: SizeType = 'large'
  const common = {
    ...rest,
    size,
    required,
    errorMessage: required ? ErrorMessage : '',
  }

  const makeFieldName = (fn: ContactAddressFields) => [formattedFieldName, fn]

  return [
    <FormFieldErrorBoundary key={`${formattedFieldName}${ContactAddressFields.Line1}`}>
      <Input
        {...common}
        label={`${label} - Address Line 1`}
        fieldName={makeFieldName(ContactAddressFields.Line1)}
      />
    </FormFieldErrorBoundary>,
    <FormFieldErrorBoundary key={`${formattedFieldName}${ContactAddressFields.Line2}`}>
      <Input
        {...common}
        required={false}
        errorMessage=""
        label={`${label} - Address Line 2`}
        fieldName={makeFieldName(ContactAddressFields.Line2)}
      />
    </FormFieldErrorBoundary>,
    <FormFieldErrorBoundary key={`${formattedFieldName}${ContactAddressFields.City}`}>
      <Input
        {...common}
        label={`${label} - City`}
        fieldName={makeFieldName(ContactAddressFields.City)}
      />
    </FormFieldErrorBoundary>,
    <FormFieldErrorBoundary key={`${formattedFieldName}${ContactAddressFields.State}`}>
      <StateSelect
        {...common}
        label={`${label} - State`}
        fieldName={makeFieldName(ContactAddressFields.State)}
      />
    </FormFieldErrorBoundary>,
    <FormFieldErrorBoundary key={`${formattedFieldName}${ContactAddressFields.Zip}`}>
      <Input
        {...common}
        label={`${label} - Zip Code`}
        fieldName={makeFieldName(ContactAddressFields.Zip)}
        type={InputTypes.ZipCode}
      />
    </FormFieldErrorBoundary>,
  ]
}
