import dayjs from 'dayjs'
import isNil from 'lodash.isnil'
import omit from 'lodash.omit'

import { DateTemplateRelation } from 'src/models/Settings/Interval'
import { getFieldNameSuffixForFormSubmittal } from 'src/pages/RecordPage/Record/tabs/DetailsTab/util'
import { filterNil, maybeMakeInt, maybeMakeString } from 'src/utils'

import { DateTemplateFragment, FieldFragment, FieldParametersSchema } from '../../../types/graphql'
import { mapFileFragmentToUploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import {
  RecordAutocompleteFieldValues,
  mapRecordResultToAutocompleteOption,
} from '../form/shared/RecordAutocompleteCell/RecordAutocomplete'
import { RecordCollectionSelectValues } from '../form/shared/RecordCollectionSelect'

import {
  getAddressFrontendFormValue,
  getCSLBContractorFrontendFormValue,
  makeFieldNameForFieldFragment,
} from './util'

interface Params {
  fieldFragment: FieldFragment
  fieldNameSuffix?: number | string
}

const mapFieldFragmentToFrontEndFormValue = (params: Params): Record<string, unknown> => {
  const { fieldFragment, fieldNameSuffix } = params
  const {
    allowMultiple,
    type,
    checked,
    date,
    num,
    str,
    strs,
    codeBookItems,
    contact,
    inspectionResult,
    record,
    file,
    files,
    recordCollections,
    foreignValue,
  } = fieldFragment
  const parameters = fieldFragment.parameters as FieldParametersSchema
  const baseFieldName = makeFieldNameForFieldFragment(fieldNameSuffix)
  switch (type) {
    case 'Address': {
      if (!fieldFragment.locationSnapshot && !fieldFragment.str) {
        return { [baseFieldName]: undefined }
      }
      if (fieldFragment.locationSnapshot) {
        return {
          [baseFieldName]: getAddressFrontendFormValue({
            str: fieldFragment.str,
            locationSnapshot: fieldFragment.locationSnapshot,
          }),
        }
      }
      return {
        [baseFieldName]: {
          manualAddress: fieldFragment.str,
        },
      }
    }
    case 'Record': {
      if (!record) {
        return { [baseFieldName]: undefined }
      }
      const mappedOption = mapRecordResultToAutocompleteOption(record)
      const res: RecordAutocompleteFieldValues = {
        label: mappedOption.label,
        selectedId: mappedOption.id,
        selectedOption: record,
        options: [record],
      }
      return {
        [baseFieldName]: res,
      }
    }
    case 'Contact': {
      const value: FieldFragment['contact'] = {
        id: contact?.id ?? -1,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        companyName: contact?.companyName,
        phoneNumber: contact?.phoneNumber,
        faxNumber: contact?.faxNumber,
        email: contact?.email,
        title: contact?.title,
        addressLine1: contact?.addressLine1,
        addressLine2: contact?.addressLine2,
        city: contact?.city,
        state: contact?.state,
        zip: contact?.zip,
      }
      return {
        [baseFieldName]: value,
      }
    }
    case 'ShortText': {
      if (allowMultiple) {
        return { [baseFieldName]: strs?.length ? strs : [''] }
      }
      return {
        [baseFieldName]: str,
      }
    }
    case 'LongText':
    case 'Radio': {
      return {
        [baseFieldName]: str,
      }
    }
    case 'Date': {
      return {
        [baseFieldName]: date ? dayjs(date) : '',
      }
    }
    case 'Checkbox': {
      const { checkboxText } = parameters
      return {
        [baseFieldName]: checked ? [checkboxText] : [],
      }
    }
    case 'Number':
    case 'Currency': {
      return {
        [baseFieldName]: isNil(num) ? '' : `${num}`,
      }
    }
    case 'Dropdown': {
      return {
        [baseFieldName]: strs,
      }
    }
    case 'File': {
      return {
        [baseFieldName]: file,
      }
    }
    case 'Files': {
      return {
        [baseFieldName]: filterNil(files ?? []).map(
          mapFileFragmentToUploadMultipleFilesFileUploadState
        ),
      }
    }
    case 'Signature': {
      return {
        [baseFieldName]: file,
      }
    }
    case 'InspectionResult': {
      return {
        [baseFieldName]: inspectionResult?.id,
      }
    }
    case 'RecordCollection': {
      const res: RecordCollectionSelectValues = {
        selectedIds: (recordCollections || []).flatMap((rc) => rc?.id || []),
        recordCollectionNames: (recordCollections || []).flatMap((rc) => rc?.name || []),
      }
      return {
        [baseFieldName]: res,
      }
    }
    case 'ForeignValue': {
      return {
        [baseFieldName]: omit(foreignValue, '__typename'),
      }
    }
    case 'CodeBookItem': {
      return {
        [baseFieldName]: codeBookItems?.map((cbi) => cbi.id) ?? [],
      }
    }
    case 'CSLBContractorSnapshot': {
      if (fieldFragment.cslbContractorSnapshot?.id) {
        return {
          [baseFieldName]: getCSLBContractorFrontendFormValue({
            cslbContractorSnapshot: fieldFragment.cslbContractorSnapshot,
          }),
        }
      }
      return {}
    }
    default: {
      return {}
    }
  }
}

export const makeInitialFormValues = (args: {
  fields: FieldFragment[]
  getFieldNameSuffix?: (field: FieldFragment) => string
}) => {
  const { fields, getFieldNameSuffix } = args
  return [...(fields || [])]?.reduce((prev: Record<string, unknown>, curr) => {
    const fieldNameSuffix =
      (getFieldNameSuffix ? getFieldNameSuffix(curr) : getFieldNameSuffixForFormSubmittal(curr)) ??
      ''
    const res = mapFieldFragmentToFrontEndFormValue({
      fieldFragment: curr,
      fieldNameSuffix,
    })
    if (!res) {
      return prev
    }
    const resValue = Object.values(res)?.[0]
    const fieldName = makeFieldNameForFieldFragment(fieldNameSuffix)

    // short text values are different from other allowMultiple values:
    // 'multiple' fields of type Record and Address create multiple Fields, whereas
    // 'multiple' fields of type ShortText create a single field with 'strs' populated rather than 'str'
    if (curr.allowMultiple && curr.type !== 'ShortText') {
      const prevValue = prev[fieldName]
      return {
        ...prev,
        [fieldName]: [...(Array.isArray(prevValue) ? prevValue : []), resValue],
      }
    }

    return {
      ...prev,
      [fieldName]: resValue,
    }
  }, {})
}

export const mapBackendDateTemplateToFEFields = (args: {
  fieldNamePrefix: string
  identifier: number | string | null | undefined
  dateTemplate: DateTemplateFragment | null | undefined
  transform?: (fieldName: string) => string
}) => {
  const { fieldNamePrefix, identifier, dateTemplate, transform } = args

  const getKey = (fieldName: string) => (transform ? transform(fieldName) : fieldName)
  const typeFieldName = `${fieldNamePrefix}-type-${identifier}`
  const intervalFieldName = `${fieldNamePrefix}-interval-type-${identifier}`
  const intervalRelationFieldName = `${fieldNamePrefix}-interval-relation-${identifier}`
  const countFieldName = `${fieldNamePrefix}-count-${identifier}`
  const monthFieldName = `${fieldNamePrefix}-month-${identifier}`
  const dayFieldName = `${fieldNamePrefix}-day-${identifier}`
  const minimumDelayDaysFieldName = `${fieldNamePrefix}-min-delay-days-${identifier}`

  let intervalRelation = DateTemplateRelation.InTheFuture
  if (dateTemplate?.interval?.relativeToDate === 'Expiration') {
    if (dateTemplate?.interval?.temporalPosition === 'After') {
      intervalRelation = DateTemplateRelation.AfterExpiration
    } else {
      intervalRelation = DateTemplateRelation.BeforeExpiration
    }
  }

  return {
    [getKey(typeFieldName)]: dateTemplate?.type ?? 'Interval',
    [getKey(intervalFieldName)]: dateTemplate?.interval?.type,
    [getKey(intervalRelationFieldName)]: intervalRelation,
    [getKey(countFieldName)]: maybeMakeString(dateTemplate?.interval?.count),
    [getKey(monthFieldName)]: mapBEMonthToFrontend(dateTemplate?.date?.month),
    [getKey(dayFieldName)]: maybeMakeInt(dateTemplate?.date?.day ?? 31),
    [getKey(minimumDelayDaysFieldName)]: maybeMakeString(dateTemplate?.date?.minimumDelayDays),
  }
}

/**
 * @description The backend works with numeric, 1-indexed month values.
 */
const mapBEMonthToFrontend = (month?: string | number) => {
  if (isNil(month)) {
    return undefined
  }
  const monthNumeric = Number(month)
  if (isNaN(monthNumeric)) {
    return undefined
  }
  return monthNumeric - 1
}
