import { AutomationTemplateFragment, RecordTypeForModulePageFragment } from 'types/graphql'

export const getInspectionLoggedAutomationForRecordType = (
  recordType:
    | {
        automationTemplates?: AutomationTemplateFragment[] | null | undefined
      }
    | null
    | undefined
) => recordType?.automationTemplates?.find((at) => at.triggerType === 'InspectionLogged')

export const getExpirationAutomationForRecordType = (
  recordType: RecordTypeForModulePageFragment | null | undefined
) => recordType?.automationTemplates?.find((at) => at.triggerType === 'Expiration')
