import { faAdd } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { RecordTypeCategory } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { useSlug } from 'src/hooks/use-slug'

type Props = {
  category: RecordTypeCategory | null | undefined
  recordTypeId: number | null | undefined
}
const CreateNewRecordButton = ({ category, recordTypeId }: Props) => {
  const slug = useSlug()

  const getApplyHref = () => {
    if (!slug || !recordTypeId) {
      return ''
    }
    return category === 'Violations'
      ? routes.submitComplaintRecordType({ slug })
      : routes.recordTemplatePick({ slug, recordTypeId })
  }
  const applyHref = getApplyHref()
  return (
    <Button
      type="primary"
      onClick={() => navigate(applyHref)}
      icon={<FontAwesomeIcon icon={faAdd} />}
    >
      Create record
    </Button>
  )
}

export default React.memo(CreateNewRecordButton)
