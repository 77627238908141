import { AddressAutocompleteFieldValues } from 'src/components/form/AddressAutocomplete'

import {
  CSLBContractorSnapshotFragment,
  FieldFragment,
  FieldParametersSchema,
  FieldType,
  LocationSnapshotFragment,
} from '../../../types/graphql'
import { FormFieldNamePrefix } from '../../constants'
import { StateAbbreviationMap } from '../../constants/states'
import { CSLBContractorAutocompleteFieldValues } from '../CSLBContractor/CSLBContractorAutocomplete'

// fieldTypesWithoutInput is duplicated on the backend
const fieldTypesWithoutInput = new Set<FieldType>(['Header', 'ExplanationText', 'LineBreak'])

export const fieldHasNoInput = (type: FieldType) => {
  return fieldTypesWithoutInput.has(type)
}

export const destructureFieldParameters = (parameters: any): FieldParametersSchema => {
  return parameters as FieldParametersSchema
}

interface HasType {
  type: FieldType
}

export function getInputFields<T extends HasType>(fields?: T[]): T[] {
  return fields?.filter((f) => !isFileField(f.type)) ?? []
}

export function getFileFields<T extends HasType>(fields?: T[]): T[] {
  return fields?.filter((f) => isFileField(f.type)) ?? []
}

export const isFileField = (type: FieldType) => ['File', 'Files'].includes(type)

export const isForeignValueField = (type: FieldType) => ['ForeignValue'].includes(type)

export const makeFieldNameForFieldFragment = (fieldNameSuffix?: string | number): string => {
  return fieldNameSuffix ? `${FormFieldNamePrefix}${fieldNameSuffix}` : FormFieldNamePrefix
}

/**
 * Format the address of a contact
 * TODO: Load a formatted address from the backend and delete this function, see api/src/utils/address.ts
 *
 * @param address Address values from a contact field
 * @returns Address if any field is populated, otherwise an empty string
 */
export const formatFieldContactAddress = (
  address:
    | Pick<
        NonNullable<FieldFragment['contact']>,
        'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
      >
    | null
    | undefined
) =>
  [
    `${address?.addressLine1 || ''} ${address?.addressLine2 || ''}`,
    `${address?.city || ''} ${
      address?.state ? StateAbbreviationMap[address.state] : ''
    } ${address?.zip || ''}`,
  ]
    .map((item) => item.trim())
    .filter((a) => !!a)
    .join(', ')

export const getAddressFrontendFormValue = (args: {
  str: string | undefined | null
  locationSnapshot: LocationSnapshotFragment | undefined | null
}): AddressAutocompleteFieldValues => {
  const { str, locationSnapshot } = args
  const option: AddressAutocompleteFieldValues['selectedOption'] = {
    id: locationSnapshot?.locationObjectId,
    address: str,
    parcelId: locationSnapshot?.parcelDisplayName,
  }
  const manualAddress = locationSnapshot ? '' : str || ''
  const res: AddressAutocompleteFieldValues = {
    locationSnapshotId: locationSnapshot?.id ?? undefined,
    locationSnapshot: locationSnapshot ?? undefined,
    manualAddress,
    label: str,
    options: [option],
    selectedId: option.id ?? '',
    selectedOption: option,
  }
  return res
}

export const getCSLBContractorFrontendFormValue = (args: {
  cslbContractorSnapshot: CSLBContractorSnapshotFragment
}): CSLBContractorAutocompleteFieldValues => {
  const { cslbContractorSnapshot } = args
  const option: CSLBContractorAutocompleteFieldValues['selectedOption'] = {
    displayName: cslbContractorSnapshot.displayName,
    licenseNumber: cslbContractorSnapshot.licenseNumber,
  }
  return {
    cslbContractorSnapshot,
    cslbContractorSnapshotId: cslbContractorSnapshot.id,
    label: cslbContractorSnapshot.displayName,
    options: [option],
    selectedId: option.licenseNumber ?? '',
    selectedOption: option,
  }
}
