import { FieldTemplateContext } from 'src/components/FieldAndFileInputs/modals/util'

import {
  AddressEntryType,
  CSLBContractorSnapshotFragment,
  FieldFragment,
  FieldParametersSchema,
  FieldType,
  ForeignValueType,
  OrganizationAddressEntryType,
} from '../../../types/graphql'
import { RefetchFnType } from '../../types'
import { FormFieldWrapperProps } from '../form/Wrapper'
import { ButtonProps } from '../TableV2/types'

export const FormFieldInputTypeDisplayMap: Record<FieldType, string> = {
  Address: 'Address',
  Checkbox: 'Checkbox',
  CodeBookItem: 'Code Book Item',
  Contact: 'Contact Info',
  CSLBContractorSnapshot: 'Contractor (CSLB)',
  Currency: 'Currency',
  Date: 'Date',
  Dropdown: 'Dropdown Select',
  ExplanationText: 'Explanation Text',
  File: 'File',
  Files: 'Files',
  ForeignValue: 'Secured Field',
  Header: 'Header',
  InspectionResult: 'Inspection Result',
  LineBreak: 'Line Break',
  LongText: 'Long Text',
  Number: 'Number',
  Radio: 'Radio Select',
  Record: 'GovWell Record',
  RecordCollection: 'Project',
  ShortText: 'Short Text',
  Signature: 'Signature',
  Valuation: 'Valuation',
  ViolationType: 'Violation Type',
}

export const ForeignValueTypeShortDisplayMap: Record<ForeignValueType, string> = {
  SocialSecurityNumber: 'SSN',
  EmployerIdentificationNumber: 'EIN',
  Other: 'Other',
}

export enum FieldAndFileInputType {
  Default,
  Inspection,
  Violations,
}

export enum FieldAndFileInputsType {
  SingleFieldGroup,
  MultipleFieldGroups,
}

export interface FieldAndFileInputsProps {
  context: FieldTemplateContext
  fields: FieldFragment[]
  presetFields?: FieldFragment[]
  fieldGroupId: number
  fieldGroupName?: string
  refetch: RefetchFnType
  previewTitle?: string
  type: FieldAndFileInputType
  acceptFileTypes?: string
  fieldGroupsType: FieldAndFileInputsType
  additionalButtons?: ButtonProps[]
  allowMultiple?: boolean
  addressEntryType: AddressEntryType
  inheritParentRecordsPrimaryAddress?: boolean
}

export interface RenderedFormFieldInputProps extends FormFieldWrapperProps {
  placeholder: string
  parameters: FieldParametersSchema
  fieldNameSuffix: string
  disabled?: boolean
  allowMultiple?: boolean
  addressEntryType?: OrganizationAddressEntryType
  recordTemplateIdParams?: number[]
  cslbContractorSnapshot?: CSLBContractorSnapshotFragment | null
}
