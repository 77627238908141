import { useMemo } from 'react'

import { Flex, Tooltip } from 'antd'
import { CodeBookItemFragment } from 'types/graphql'

import { FormSelectLoadingPlaceholder } from 'src/components/form/shared/LoadingPlaceholder'
import Text from 'src/components/Typography/Text'
import { useCodeBooksQuery } from 'src/fetch/codeBooks'

type Props = {
  codeBookItemIds: number[]
}
const CodeBookItemFieldDisplay = ({ codeBookItemIds }: Props) => {
  const { data: codeBooksQuery, isLoading: isLoadingCodeBooks } = useCodeBooksQuery()
  const codeBookItemsById = useMemo(
    () =>
      new Map<number, CodeBookItemFragment>(
        codeBooksQuery?.codeBooks.flatMap((cb) => cb.items).map((cbi) => [cbi.id, cbi])
      ),
    [codeBooksQuery?.codeBooks]
  )

  if (isLoadingCodeBooks) {
    return <FormSelectLoadingPlaceholder />
  }
  return (
    <Flex vertical>
      {codeBookItemIds.map((id) => (
        <Tooltip title={codeBookItemsById.get(id)?.text} key={id}>
          <Text>{codeBookItemsById.get(id)?.formattedName}</Text>
        </Tooltip>
      ))}
    </Flex>
  )
}

export default React.memo(CodeBookItemFieldDisplay)
