import { useCallback, useMemo, useState } from 'react'

import { Pin, PinPayload, themes } from '@evervault/react'
import { faPen, faTrash, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ForeignValueType } from 'types/graphql'

import { ForeignValueDisplay } from 'src/components/ForeignValues/ForeignValueDisplay'
import Wrapper, { FormFieldWrapperProps } from 'src/components/form/Wrapper'
import useDisclosure from 'src/hooks/use-disclosure'
import { useTheme } from 'src/hooks/use-theme'
import { ForeignValueTypeLengthMap } from 'src/utils/foreignStorage'

type Props<TInput> = FormFieldWrapperProps<TInput> & {
  type: ForeignValueType
  userHasAccessToForeignValue?: boolean
  mode?: 'create' | 'edit'
}

const getPinGapForNthChild = (n: number) => ({
  [`.field:nth-child(${n})`]: {
    marginRight: '6px',
  },
  [`.field:nth-child(${n}) input`]: {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  [`.field:nth-child(${n + 1}) input`]: {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
  },
})

// This component must be a child of GovWellEvervaultProvider.
export const ForeignValueInput = <TInput,>({
  type,
  userHasAccessToForeignValue,
  mode = 'create',
  ...props
}: Props<TInput>) => {
  const govWellTheme = useTheme()
  const [isComplete, setIsComplete] = useState(false)
  const [value, setValue] = useState<string | null>('')
  const { isOpen: isEditing, open: startEditing, close: stopEditing } = useDisclosure()

  const form = useFormInstance()

  // This is the value that will be restored when cancelling out of editing
  const [originalValue, setOriginalValue] = useState(form.getFieldValue(props.fieldName))
  const rules = useMemo(
    () => [
      ...(props.rules ?? []),
      {
        validator: (_) => {
          if (isComplete) {
            return Promise.resolve()
          }
          if (props.required) {
            return Promise.reject('Please fill this in order to advance with the application.')
          }
          // don't allow partial values when field is not required
          if (!props.required && value !== '') {
            return Promise.reject('Partially complete values are not allowed')
          }
          return Promise.resolve()
        },
      },
    ],
    [isComplete, props.required, props.rules, value]
  )

  const handleChange = useCallback(
    ({ isComplete: newIsComplete, value: newValue }: PinPayload) => {
      setIsComplete(newIsComplete)
      setValue(newValue)
      form.setFieldValue(props.fieldName, {
        type,
        key: newValue,
      })
      if (newIsComplete) {
        form.setFields([{ name: props.fieldName, errors: [] }])
      }
    },
    [form, props.fieldName, type]
  )

  const handleStartEditingClicked = useCallback(() => {
    startEditing()

    // Clear the field value to match what the evervault component displays when it's initially rendered
    form.setFieldValue(props.fieldName, '')
  }, [form, props.fieldName, startEditing])

  const handleStopEditingClicked = useCallback(() => {
    stopEditing()

    // Reset the field value so we don't attempt to save partially-edited ciphertext
    form.setFieldValue(props.fieldName, originalValue)
  }, [form, originalValue, props.fieldName, stopEditing])

  const handleDeleteClicked = useCallback(() => {
    const newValue = {
      type: undefined,
      key: '',
    }
    form.setFieldValue(props.fieldName, newValue)
    setOriginalValue(newValue) // don't restore the db value if you delete, then open for editing and close it again
  }, [form, props.fieldName])

  if (mode === 'edit' && !isEditing) {
    const formValue = form.getFieldValue(props.fieldName)
    const isDatabaseValuePopulated = !!formValue.type
    return (
      <Wrapper {...props} required={false}>
        <Flex align="center">
          {isDatabaseValuePopulated ? (
            <ForeignValueDisplay
              id={formValue.id}
              type={formValue.type}
              userHasAccessToForeignValue={!!userHasAccessToForeignValue}
            />
          ) : (
            '-'
          )}
          <Button
            icon={<FontAwesomeIcon icon={faPen} />}
            type="link"
            onClick={handleStartEditingClicked}
          />
          {isDatabaseValuePopulated && !props.required && (
            <Button
              icon={<FontAwesomeIcon icon={faTrash} />}
              type="link"
              onClick={handleDeleteClicked}
            />
          )}
        </Flex>
      </Wrapper>
    )
  }

  return (
    <Flex align="center">
      <Wrapper {...props} rules={rules}>
        <Pin
          mode="numeric"
          inputType="text"
          onChange={handleChange}
          theme={themes.minimal({
            styles: {
              '&': {
                marginRight: '1px',
              },
              '.field input': {
                height: '40px',
                padding: '0 6px',
                borderColor: govWellTheme.getTokenVal('colorBorder'),
              },
              '.field input::placeholder': {
                color: govWellTheme.getTokenVal('colorTextPlaceholder'),
              },
              '.field:focus-within input': {
                borderColor: govWellTheme.getTokenVal('colorPrimaryBase'),
              },
              ...(type === 'SocialSecurityNumber'
                ? {
                    ...getPinGapForNthChild(3),
                    ...getPinGapForNthChild(5),
                  }
                : {}),
              ...(type === 'EmployerIdentificationNumber'
                ? {
                    ...getPinGapForNthChild(2),
                  }
                : {}),
            },
          })}
          length={ForeignValueTypeLengthMap[type]}
          {...props}
        />
      </Wrapper>
      {mode === 'edit' && isEditing && (
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faXmark} />}
          onClick={handleStopEditingClicked}
        />
      )}
    </Flex>
  )
}

export default React.memo(ForeignValueInput)
