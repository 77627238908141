import { forwardRef, useCallback, useEffect, useState } from 'react'

import { faDollarSign, faHashtag, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControlProps } from 'govwell-ui/components/FormControl/util'
import Input from 'govwell-ui/components/Input/Input'
import { InputBaseProps } from 'govwell-ui/components/Input/InputBase'
import isNil from 'lodash.isnil'

import useDisclosure from 'src/hooks/use-disclosure'
import { formatNumber, NumberFormat } from 'src/utils/number'

const MaximumFractionDigits: Record<NumberFormat, number> = {
  [NumberFormat.Currency]: 2,
  [NumberFormat.Float]: 6,
  [NumberFormat.Integer]: 0,
}

const MinimumFractionDigits: Record<NumberFormat, number> = {
  [NumberFormat.Currency]: 2,
  [NumberFormat.Float]: 0,
  [NumberFormat.Integer]: 0,
}

const LeftAddonIcon: Record<NumberFormat, IconDefinition> = {
  [NumberFormat.Currency]: faDollarSign,
  [NumberFormat.Float]: faHashtag,
  [NumberFormat.Integer]: faHashtag,
}

type Props = Omit<InputBaseProps, 'value' | 'onValueChange'> &
  FormControlProps & {
    format?: NumberFormat // TODO: Support non-integer number formats
    onValueChange: (value: number | undefined) => void
    value: number | null | undefined
  }
const NumberInput = forwardRef<HTMLInputElement, Props>(
  ({ format = NumberFormat.Integer, onValueChange, value, width = '100%', ...props }, ref) => {
    const maximumFractionDigits = MaximumFractionDigits[format]
    const minimumFractionDigits = MinimumFractionDigits[format]

    const formatNumberForUserInteraction = useCallback(
      (n: number | null | undefined) => {
        if (isNil(n)) {
          return ''
        }
        return formatNumber(n, {
          minimumFractionDigits,
          maximumFractionDigits,
          useGrouping: false,
        })
      },
      [maximumFractionDigits, minimumFractionDigits]
    )

    const [inputText, setInputText] = useState(formatNumberForUserInteraction(value))

    const { isOpen: hasFocus, open: focus, close: blur } = useDisclosure()
    useEffect(() => {
      // Whenever the input loses focus, or the value changes while focus is not in the input, reset the input text to proper formatting
      if (!hasFocus) {
        setInputText(
          formatNumber(value, {
            maximumFractionDigits,
            minimumFractionDigits,
            useGrouping: true,
          })
        )
      }
    }, [setInputText, hasFocus, value, maximumFractionDigits, minimumFractionDigits])

    const handleChange = useCallback(
      (newValue: string | undefined) => {
        const str = newValue?.trim()
        if (isNil(str) || str === '') {
          setInputText('')
          onValueChange(undefined)
          return
        }

        if (str === '.') {
          if (maximumFractionDigits === 0) {
            return
          }
          // Auto-convert to neatly formatted decimal value
          setInputText('0.')
          onValueChange(0)
          return
        }

        const newNumericValue = Number(newValue)
        if (isNaN(newNumericValue)) {
          return
        }

        let sanitizedStr = str.replace(/^0+([^\.])/, '$1') // remove excess leading zeroes
        const wholeNumberRegex = `(0|([1-9]\\d*))`
        const fractionRegex =
          maximumFractionDigits === 0 ? '' : `(\\.\\d{0,${maximumFractionDigits}})?`
        const sanitizationRegex = `${wholeNumberRegex}${fractionRegex}`
        sanitizedStr = sanitizedStr.match(new RegExp(sanitizationRegex))?.[0] ?? ''

        setInputText(sanitizedStr)
        const sanitizedValue = Number(sanitizedStr)
        onValueChange(sanitizedValue)
      },
      [maximumFractionDigits, onValueChange]
    )

    const handleFocus = useCallback(() => {
      focus()
      setInputText(formatNumberForUserInteraction(value))
    }, [focus, formatNumberForUserInteraction, value])

    return (
      <Input
        {...props}
        leftAddon={
          props.leftAddon === undefined ? (
            <FontAwesomeIcon icon={LeftAddonIcon[format]} />
          ) : (
            props.leftAddon
          )
        }
        onBlur={blur}
        onFocus={handleFocus}
        onValueChange={handleChange}
        placeholder={props.placeholder ?? 'Enter a value'}
        value={inputText}
        ref={ref}
        type="number"
        width={width}
      />
    )
  }
)

export default NumberInput
