import { useMemo } from 'react'

import { Skeleton } from 'antd'
import styled from 'styled-components'

import { LocationSnapshotFragment } from '../../../../types/graphql'
import { useLocationSnapshotByIdQuery } from '../../../fetch/locations'
import { renderGISFieldValue } from '../../../utils/gis'
import { ColumnData, FieldValueTable } from '../../shared/FieldValueTable'
import { getContainerStyleProps } from '../../shared/StyledComponents'
import Text, { TextSize } from '../../Typography/Text'

const ConnectedToGisContainer = styled.div`
  ${(props) => getContainerStyleProps(props.theme)}
  padding: 12px;
  .ant-table-thead {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 12px;
`
type Props = {
  locationSnapshot: LocationSnapshotFragment
}
const LocationSnapshotTooltipContent = ({ locationSnapshot }: Props) => {
  const { data, isLoading } = useLocationSnapshotByIdQuery(locationSnapshot.id)

  const dataSource: ColumnData[] = useMemo(
    () =>
      (data?.locationSnapshotById?.fields || [])?.map((f) => ({
        fieldName: f.key,
        key: f.key,
        fieldValue: renderGISFieldValue(f.value),
      })),
    [data?.locationSnapshotById?.fields]
  )

  return (
    <ConnectedToGisContainer>
      <Text size={TextSize.Large}>
        This information was imported from a GIS database that is integrated with GovWell and will
        be attached to your submittal.
      </Text>
      {isLoading && <Skeleton active />}
      {!isLoading && <FieldValueTable dataSource={dataSource} />}
    </ConnectedToGisContainer>
  )
}

export default React.memo(LocationSnapshotTooltipContent)
