import { useQueryClient } from '@tanstack/react-query'

import { useAuth } from 'src/auth'
import { getBroadcastChannel } from 'src/hooks/use-broadcast-channel-listener'
import { BroadcastChannelMessageType, LocalStorageKey } from 'src/types'
import { resetMonitoring } from 'src/utils/monitoring'

const clearUserSpecificStorageKeys = () => {
  localStorage.removeItem(LocalStorageKey.NavigationCodeV1IsOpen)
  localStorage.removeItem(LocalStorageKey.NavigationRecordTypeIsOpenMap)
  localStorage.removeItem(LocalStorageKey.RecordTemplateFormStateMap)
}

export const useResetAuthState = () => {
  const { logOut } = useAuth()
  const queryClient = useQueryClient()
  return async () => {
    resetMonitoring()
    queryClient.clear()
    clearUserSpecificStorageKeys()
    await logOut()
    const channel = getBroadcastChannel()
    channel?.postMessage({ type: BroadcastChannelMessageType.Logout })
  }
}
