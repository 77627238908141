import {
  CreateLocationSnapshotMutation,
  CreateLocationSnapshotMutationVariables,
  GetLocationSnapshotByIdQuery,
  GetLocationSnapshotByIdQueryVariables,
  SearchGISMutation,
  SearchGISMutationVariables,
  SearchMapMutation,
  SearchMapMutationVariables,
} from '../../types/graphql'
import { QueryKey, useMutation, useQuery } from '../utils/queryClient'

const SearchMap = gql`
  mutation SearchMapMutation($input: SearchMapInput!) {
    searchMap(input: $input) {
      results {
        ...GISPropertyFragment
      }
    }
  }
  fragment GISPropertyFragment on GISProperty {
    id
    address
    parcelId
    center {
      latitude
      longitude
    }
  }
`
export const useSearchMapMutation = () => {
  return useMutation<SearchMapMutation, SearchMapMutationVariables>({
    mutationDocument: SearchMap,
  })
}

export const CreateLocationSnapshot = gql`
  mutation CreateLocationSnapshotMutation($input: CreateLocationSnapshotInput!) {
    createLocationSnapshot(input: $input) {
      ...LocationSnapshotFragment
    }
  }
`
export const useCreateLocationSnapshotMutation = () => {
  return useMutation<CreateLocationSnapshotMutation, CreateLocationSnapshotMutationVariables>({
    mutationDocument: CreateLocationSnapshot,
  })
}

const SearchGIS = gql`
  mutation SearchGISMutation($input: SearchGISInput!) {
    searchGIS(input: $input) {
      results {
        ...GISPropertySearchFragment
      }
    }
  }
  fragment GISPropertySearchFragment on GISProperty {
    id
    address
    parcelId
  }
`

export const useSearchGISMutation = () => {
  return useMutation<SearchGISMutation, SearchGISMutationVariables>({
    mutationDocument: SearchGIS,
  })
}

const LocationSnapshotById = gql`
  query GetLocationSnapshotByIdQuery($id: Int!) {
    locationSnapshotById(id: $id) {
      ...LocationSnapshotInDepthFragment
    }
  }
`
export const useLocationSnapshotByIdQuery = (id: number) => {
  return useQuery<GetLocationSnapshotByIdQuery, GetLocationSnapshotByIdQueryVariables>({
    queryKey: [QueryKey.LocationSnapshot, id],
    queryDocument: LocationSnapshotById,
    variables: { id },
  })
}
