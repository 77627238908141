import { useContext, useMemo } from 'react'

import { OrgSlugs } from 'src/constants'
import { filterNil } from 'src/utils'

import {
  AddressEntryType,
  CompanyFragment,
  FormulaOutputUserType,
  GetOrganizationBySlugStateQuery,
  RecordTypeFragment,
  UserDataFragmentWithRecordTypeAccesses,
} from '../../../types/graphql'
import { RefetchFnType } from '../../types'

import { AppStateContext, AppStateContextData, AppStateQueryOrganization } from './types'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Finix: any
  }
}

export const useAppStateContext = (): AppStateContextData | undefined => useContext(AppStateContext)
export const useMyOrg = (): AppStateQueryOrganization | undefined => {
  const context = useAppStateContext()
  return context?.organization
}

export function useMyOrgIsSlug(slug: OrgSlugs): boolean {
  return useMyOrg()?.slug === slug
}

const useMyOrgUsers = (includeInactive?: boolean) => {
  const context = useAppStateContext()
  return useMemo(() => {
    const org = context?.organization
    const users = filterNil(org?.users || [])
    return includeInactive ? users : users.filter((u) => u.status === 'Active')
  }, [context?.organization, includeInactive])
}
export const useMyOrgActiveUsers = () => useMyOrgUsers(false)
export const useMyOrgOtherActiveUsers = () => {
  const me = useMyUser()
  const myOrgActiveUsers = useMyOrgActiveUsers()
  const otherOrgUsers = useMemo(
    () => myOrgActiveUsers.filter((u) => u.id !== me?.id),
    [me?.id, myOrgActiveUsers]
  )
  return otherOrgUsers
}
export const useMyOrgActiveInactiveUsers = () => useMyOrgUsers(true)
export const useMyOrgActiveUsersOfType = (userType: FormulaOutputUserType | null | undefined) => {
  const activeUsers = useMyOrgActiveUsers()
  return useMemo(
    () =>
      activeUsers?.filter((u) => {
        switch (userType) {
          case 'Inspector':
            return !!u.isInspector
          case 'AnyUserInOrganization':
            return true
          default:
            return false
        }
      }) ?? [],
    [activeUsers, userType]
  )
}
export const useMyUser = (): UserDataFragmentWithRecordTypeAccesses | undefined | null => {
  const context = useAppStateContext()
  return context?.user
}
export const useMyRecordTypes = (): RecordTypeFragment[] | undefined => {
  const context = useAppStateContext()
  return context?.recordTypes
}
// used for configuration, like Inspection Templates, that are not tied to a specific module
export const useDefaultAddressEntryType = (): AddressEntryType => {
  const recordTypes = useMyRecordTypes()
  return recordTypes?.[0]?.addressEntryType || 'SearchAndManual'
}

export const useAmIAdmin = (): boolean => {
  const user = useMyUser()
  const notAdmin = user && user.group !== 'Admin'
  return !notAdmin
}
export const useAmIReviewer = (): boolean => {
  const user = useMyUser()
  const notAdmin = user && user.group !== 'Reviewer'
  return !notAdmin
}
export const useIsMe = (userId: number): boolean => {
  const user = useMyUser()
  const notMe = user && user.id !== userId
  return !notMe
}
export const useIsCitizen = (): boolean => useMyUser()?.type === 'Citizen'
export const useIsGov = (): boolean => useMyUser()?.type === 'Government'
export const useIsGovWellStaff = (): boolean => {
  const user = useMyUser()
  return !!user && !!user?.isGovWellStaff
}
export const useMyCompany = (): CompanyFragment | undefined | null => {
  return useAppStateContext()?.company
}
export const useRefetchAppState = (): RefetchFnType | undefined => {
  return useAppStateContext()?.refetch
}
export const useOrgSlugMetadata = ():
  | GetOrganizationBySlugStateQuery['organizationBySlug']
  | undefined => {
  return useAppStateContext()?.organizationBySlug
}
export const useAmIInspector = () => {
  return useMyUser()?.isInspector
}

// camp verde has extra stringent requirements for permissioning with regards to reviewers
// this is a stopgap solution until we have a well defined permissioning system in place
export const useIsRestrictedReviewer = () => {
  const slug = useMyOrg()?.slug
  const isReviewer = useAmIReviewer()
  return slug === OrgSlugs.CampVerde && isReviewer
}
