import isNil from 'lodash.isnil'
import { makeAutoObservable } from 'mobx'
import {
  AutomationTriggerType,
  DateTemplateInterval,
  DateTemplateIntervalInput,
  DateTemplateIntervalRelativeToDate,
  DateTemplateIntervalType,
  TemporalPosition,
} from 'types/graphql'

export enum DateTemplateRelation {
  InTheFuture = 'in the future',
  BeforeExpiration = 'before expiration',
  AfterExpiration = 'after expiration',
}

export class Interval {
  public count: number | undefined
  public relativeToDate: DateTemplateIntervalRelativeToDate
  public temporalPosition: TemporalPosition
  public type: DateTemplateIntervalType | undefined

  constructor(args?: {
    count: number | undefined
    relativeToDate: DateTemplateIntervalRelativeToDate
    temporalPosition: TemporalPosition
    type: DateTemplateIntervalType | undefined
  }) {
    const { count, relativeToDate, temporalPosition, type } = args ?? {}
    this.count = count
    this.relativeToDate = relativeToDate ?? 'Now'
    this.temporalPosition = temporalPosition ?? 'After'
    this.type = type
    makeAutoObservable(this)
  }

  public get relation(): DateTemplateRelation {
    if (this.relativeToDate === 'Expiration') {
      if (this.temporalPosition === 'After') {
        return DateTemplateRelation.AfterExpiration
      } else {
        return DateTemplateRelation.BeforeExpiration
      }
    }
    return DateTemplateRelation.InTheFuture
  }

  public set relation(value: DateTemplateRelation) {
    if (value === DateTemplateRelation.AfterExpiration) {
      this.relativeToDate = 'Expiration'
      this.temporalPosition = 'After'
    } else if (value === DateTemplateRelation.BeforeExpiration) {
      this.relativeToDate = 'Expiration'
      this.temporalPosition = 'Before'
    } else {
      this.relativeToDate = 'Now'
      this.temporalPosition = 'After'
    }
  }

  public get isPopulated(): boolean {
    return !isNil(this.count) && !isNil(this.type)
  }

  public getDescription(automationTriggerType?: AutomationTriggerType): string {
    if (!this.isPopulated) {
      return ''
    }

    const isRelativeToInspectionEndDate =
      automationTriggerType === 'InspectionLogged' &&
      this.relation === DateTemplateRelation.InTheFuture

    if (!this.count) {
      if (this.relation === DateTemplateRelation.InTheFuture) {
        if (isRelativeToInspectionEndDate) {
          return 'the inspection end date'
        }
        return 'now'
      }
      return 'when the record expires'
    }

    let singularType = 'day'
    let pluralType = 'days'
    switch (this.type) {
      case 'Hour':
        singularType = 'hour'
        pluralType = 'hours'
        break
      case 'Minute':
        singularType = 'minute'
        pluralType = 'minutes'
        break
      case 'Month':
        singularType = 'month'
        pluralType = 'months'
        break
      case 'Week':
        singularType = 'week'
        pluralType = 'weeks'
        break
      case 'Year':
        singularType = 'year'
        pluralType = 'years'
        break
    }

    let typeDescription = pluralType
    if (this.count === 1) {
      typeDescription = singularType
    }

    let relationDescription: string = this.relation
    if (isRelativeToInspectionEndDate) {
      relationDescription = 'after the inspection end date'
    }
    return `${this.count} ${typeDescription} ${relationDescription}`
  }

  public get protocol(): DateTemplateIntervalInput {
    return {
      count: this.count,
      relativeToDate: this.relativeToDate,
      temporalPosition: this.temporalPosition,
      type: this.type,
    }
  }

  static fromProtocol(protocol: DateTemplateInterval): Interval {
    return new Interval({
      count: protocol.count ?? undefined,
      relativeToDate: protocol.relativeToDate,
      temporalPosition: protocol.temporalPosition,
      type: protocol.type || undefined,
    })
  }
}
