import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'

import { useTheme } from '../../../hooks/use-theme'
import Text, { TextSize } from '../../Typography/Text'
import { Progress } from '../Progress'

interface Props {
  progress: number
  cancelUpload: () => void
}

export const UploadingHeader = () => {
  const { getTokenVal } = useTheme()
  return (
    <Flex gap="12px" align="center" justify="center">
      <FontAwesomeIcon
        icon={faRotate}
        style={{
          height: 24,
          width: 24,
          color: getTokenVal('colorPrimaryBase'),
        }}
      />
      <Text size={TextSize.Base}>Uploading</Text>
    </Flex>
  )
}

export const Uploading = (props: Props) => {
  const { progress, cancelUpload } = props

  return (
    <Flex vertical align="center" gap="12px" style={{ width: '100%' }}>
      <Flex justify="space-between" gap="12px" style={{ width: '100%' }}>
        <UploadingHeader />
        <Button
          type="text"
          onClick={cancelUpload}
          icon={<FontAwesomeIcon icon={faXmark} />}
          size="small"
        >
          Cancel
        </Button>
      </Flex>
      <Progress progress={progress} />
    </Flex>
  )
}
