import * as FaIconsRegular from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Suffix } from '../../../types'

type FaRegular = keyof typeof FaIconsRegular
type FaRegularCapitalized = `F${Suffix<FaRegular, 'f'>}` // fa* Icon names with capitalized F

type IconKey = FaRegularCapitalized // In the future, can extend this with other icon key sets, either our own in-house icons or other libraries

const FaRegularIcons: [FaRegularCapitalized, React.FC][] = Object.keys(FaIconsRegular).map((k) => {
  const iconKey = `F${k.substring(1)}` as FaRegularCapitalized
  return [
    iconKey,
    () => (
      <FontAwesomeIcon
        icon={
          FaIconsRegular[`f${iconKey.substring(1)}` as FaRegular] as FaIconsRegular.IconDefinition
        }
      />
    ),
  ]
})
const Icon = Object.fromEntries([...FaRegularIcons]) as Record<IconKey, React.FC>

export default Icon
