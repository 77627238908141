import { useCallback } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'

import {
  useRecordTaskInspectionAttemptSchedulingIntakeFormTemplateQuery,
  useRequestRecordTaskInspectionAttemptMutation,
} from '../../fetch/recordTaskInspectionAttempts'
import { useDefaultAddressEntryType } from '../../layouts/AppStateContextLayout/utils'
import { filterNil } from '../../utils'
import { useAnnounceFirstInvalidField } from '../../utils/forms'
import {
  mapFieldToSingleFormFieldInput,
  mapMultipleFieldsToFormFieldInputs,
} from '../Field/fe-to-be'
import { RenderedFormFieldInput } from '../FieldAndFileInputs/RenderedFormFieldInput'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import TextArea from '../form/TextArea'
import Modal from '../Modal'
import { FormContainer } from '../shared/StyledComponents'

import InspectionSchedulingInstructions from './InspectionSchedulingInstructions'
import { InspectionsTabModalProps } from './types'

interface FormValues extends Record<string, unknown> {
  date: Dayjs
  schedulingNotes: string
}

export const RequestInspectionModal = (props: InspectionsTabModalProps) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    inspectionTemplateName,
    refetch,
    inspectionCutoffTime,
    inspectionSchedulingInstructions,
  } = props
  const addressEntryType = useDefaultAddressEntryType()
  const [form] = useForm<FormValues>()
  const { intakeFormFieldGroupTemplate, isLoading } =
    useRecordTaskInspectionAttemptSchedulingIntakeFormTemplateQuery(recordTaskInspectionAttemptId)

  const { mutateAsync: requestInspection } = useRequestRecordTaskInspectionAttemptMutation()
  const announceFirstInvalidField = useAnnounceFirstInvalidField(form)
  const onOk = useCallback(async () => {
    try {
      await form.validateFields()

      const formValues = form.getFieldsValue()
      const { date, schedulingNotes } = formValues
      const formFields = mapMultipleFieldsToFormFieldInputs({
        fields: filterNil(intakeFormFieldGroupTemplate?.fields ?? []),
        values: formValues,
      })
      await requestInspection({
        id: recordTaskInspectionAttemptId,
        input: {
          requestedFor: dayjs(date).toISOString(),
          schedulingNotes,
          schedulingIntakeForm: formFields,
        },
      })
      await refetch()
      modalState.close()
    } catch {
      announceFirstInvalidField()
      return Promise.reject()
    }
  }, [
    announceFirstInvalidField,
    form,
    intakeFormFieldGroupTemplate?.fields,
    modalState,
    recordTaskInspectionAttemptId,
    refetch,
    requestInspection,
  ])

  return (
    <Modal
      {...modalState}
      icon={faCalendar}
      title={`Request Inspection: ${inspectionTemplateName || '{No Name}'}`}
      okText="Request inspection"
      onOk={onOk}
    >
      {isLoading && <Skeleton active />}
      {!isLoading && (
        <FormContainer form={form}>
          {inspectionSchedulingInstructions && (
            <InspectionSchedulingInstructions
              inspectionSchedulingInstructions={inspectionSchedulingInstructions}
            />
          )}
          <DatePicker
            fieldName="date"
            label="Requested Inspection Date"
            required
            errorMessage="You must enter a date"
            allowedDates={DatePickerAllowedDates.Future}
            disableWeekends
            cutoffTime={inspectionCutoffTime}
            noMargin
          />
          <TextArea
            fieldName="schedulingNotes"
            label="Notes"
            placeholder="e.g. I will be home between 2pm and 4pm."
            caption="You may add some notes for the staff."
            noMargin
          />
          <Flex vertical gap="24px">
            {intakeFormFieldGroupTemplate?.fields?.map((f) => (
              <RenderedFormFieldInput
                key={f.id}
                formFieldInput={{
                  id: f.id,
                  input: mapFieldToSingleFormFieldInput({ field: f }),
                }}
                fieldNameSuffix={`${f.id}`}
                errorMessage={f.required ? 'Please enter a valid value.' : undefined}
                addressEntryType={addressEntryType}
                noMargin
              />
            ))}
          </Flex>
        </FormContainer>
      )}
    </Modal>
  )
}
