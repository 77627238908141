import { Fragment, ReactNode } from 'react'

import { styled } from 'styled-components'

import { FieldFragment } from '../../../types/graphql'
import { FieldDisplay } from '../Field/FieldDisplay'
import { fieldHasNoInput } from '../Field/util'
import { HorizontalLine } from '../StyledComponents'
import Text, { TextSize } from '../Typography/Text'

const Container = styled.div<{ $padding?: React.CSSProperties['padding'] }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${({ $padding }) => ($padding ? '0px' : $padding)};
`

interface Props {
  fields: FieldFragment[]
  padding?: React.CSSProperties['padding']
}

export const VerticalFieldValues = (props: Props) => {
  const fields = (props?.fields || []).filter((f) => !fieldHasNoInput(f.type))
  return (
    <Container $padding={props.padding}>
      {fields.map((f, index) => (
        <Fragment key={`item-${f.id}`}>
          <Item title={f.label ?? ''}>
            <FieldDisplay input={f} />
          </Item>
          {index !== fields.length - 1 && <HorizontalLine />}
        </Fragment>
      ))}
    </Container>
  )
}

interface ItemProps {
  title: string
  children: ReactNode
}

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Item = (props: ItemProps) => {
  const { title, children } = props
  return (
    <ItemContainer>
      <Text size={TextSize.Base} strong underline>
        {title}
      </Text>
      {typeof children === 'string' ? <Text size={TextSize.Small}>{children}</Text> : children}
    </ItemContainer>
  )
}
