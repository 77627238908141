import { makeAutoObservable } from 'mobx'
import { DateTemplateDate, DateTemplateDateInput } from 'types/graphql'

import { Month } from 'src/types'
import { getNumberOfDaysForMonth } from 'src/utils/date'

export class SemanticDate {
  public minimumDelayDays: number | undefined

  private _day: number
  private _month: number

  constructor(args?: {
    day: number | undefined
    minimumDelayDays: number | undefined
    month: number | undefined
  }) {
    const { day, minimumDelayDays, month } = args ?? {}

    // Default to December 31
    this._month = month ?? Month.December
    this._day = day ?? 31
    this.minimumDelayDays = minimumDelayDays

    makeAutoObservable(this)
  }

  /**
   * @description 1-indexed day value
   */
  public get day(): number {
    return this._day
  }

  public set day(value: number) {
    const numberOfDaysInMonth = getNumberOfDaysForMonth(this._month)
    if (value < 1 || value > numberOfDaysInMonth) {
      return
    }
    this._day = value
  }

  /**
   * @description 1-indexed month value
   */
  public get month(): number {
    return this._month
  }

  public set month(value: number) {
    if (value < 1 || value > 12) {
      return
    }
    this._month = value
    const numberOfDaysInMonth = getNumberOfDaysForMonth(value)
    if (this._day && this._day > numberOfDaysInMonth) {
      this._day = 1
    }
  }

  public get protocol(): DateTemplateDateInput {
    return {
      day: this._day,
      month: this._month,
      minimumDelayDays: this.minimumDelayDays,
    }
  }

  public static fromProtocol(protocol: DateTemplateDate): SemanticDate {
    return new SemanticDate({
      day: protocol.day || undefined,
      minimumDelayDays: protocol.day || undefined,
      month: protocol.month || undefined,
    })
  }
}
