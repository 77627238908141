import dayjs, { Dayjs } from 'dayjs'

import { DateFormats } from 'src/utils/date'

const AllowedDateFormats = [
  'M-D-YYYY',
  'MM-D-YYYY',
  'M-DD-YYYY',
  'MM-DD-YYYY',
  'M/D/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'MM/DD/YYYY',
  'YYYY-M-D',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY-MM-DD',
  'MMMM D, YYYY',
]

export const getIsStringCompleteDate = (value: string) =>
  AllowedDateFormats.some((format) => dayjs(value, format, true).isValid())

export const formatDateForInputText = (value: Dayjs | null | undefined) =>
  value?.format(DateFormats.MonthNameDateNoTime) ?? ''
