import { useCallback } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import isNil from 'lodash.isnil'

import { FieldFragment, UserDataFragment } from '../../../types/graphql'
import {
  useRecordTaskInspectionAttemptSchedulingIntakeFormQuery,
  useRecordTaskInspectionAttemptSchedulingIntakeFormTemplateQuery,
  useScheduleRecordTaskInspectionAttemptMutation,
} from '../../fetch/recordTaskInspectionAttempts'
import { useDefaultAddressEntryType, useIsGov } from '../../layouts/AppStateContextLayout/utils'
import { filterNil } from '../../utils'
import { useAnnounceFirstInvalidField } from '../../utils/forms'
import {
  mapFieldToSingleFormFieldInput,
  mapMultipleFieldsToFormFieldInputs,
} from '../Field/fe-to-be'
import { RenderedFormFieldInput } from '../FieldAndFileInputs/RenderedFormFieldInput'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import { OwnerSelect } from '../form/shared/OwnerSelect'
import TextArea from '../form/TextArea'
import Modal from '../Modal'
import { FormContainer } from '../shared/StyledComponents'
import { VerticalFieldValues } from '../shared/VerticalFieldValues'

import { InspectionMetadata } from './InspectionMetadata'
import InspectionSchedulingInstructions from './InspectionSchedulingInstructions'
import { InspectionsTabModalProps } from './types'

interface FormValues extends Record<string, unknown> {
  date: Dayjs
  schedulingNotes: string
  inspectorUserId?: number
}

interface Props extends InspectionsTabModalProps {
  defaultInspectorUserId: number | undefined
}

export const ScheduleInspectionModal = (props: Props) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    defaultInspectorUserId,
    refetch,
    recordTaskInspectionAttemptRequestedFor,
    inspectionCutoffTime,
    inspectionSchedulingInstructions,
  } = props
  const addressEntryType = useDefaultAddressEntryType()
  const isGov = useIsGov()
  const { intakeFormFieldGroupTemplate, isLoading: isLoadingIntakeFormTemplate } =
    useRecordTaskInspectionAttemptSchedulingIntakeFormTemplateQuery(recordTaskInspectionAttemptId, {
      enabled: !isGov,
    })
  const { intakeFormFieldGroup, isLoading: isLoadingIntakeForm } =
    useRecordTaskInspectionAttemptSchedulingIntakeFormQuery(recordTaskInspectionAttemptId, {
      enabled: isGov,
    })

  const { mutateAsync: schedule } = useScheduleRecordTaskInspectionAttemptMutation()
  const [form] = useForm<FormValues>()
  const announceFirstInvalidField = useAnnounceFirstInvalidField()
  const onOk = useCallback(async () => {
    try {
      await form.validateFields()
      const formValues = form.getFieldsValue()
      const { date, schedulingNotes, inspectorUserId } = formValues
      const formFields = mapMultipleFieldsToFormFieldInputs({
        fields: filterNil(intakeFormFieldGroupTemplate?.fields ?? []),
        values: formValues,
      })
      await schedule({
        id: recordTaskInspectionAttemptId,
        input: {
          scheduledFor: dayjs(date).toISOString(),
          schedulingIntakeForm: formFields,
          schedulingNotes,
          inspectorUserId: inspectorUserId ? inspectorUserId : defaultInspectorUserId,
        },
      })
      await refetch()
      modalState.close()
    } catch {
      announceFirstInvalidField()
      return Promise.reject()
    }
  }, [
    announceFirstInvalidField,
    defaultInspectorUserId,
    form,
    intakeFormFieldGroupTemplate?.fields,
    modalState,
    recordTaskInspectionAttemptId,
    refetch,
    schedule,
  ])

  const placeholder = isGov
    ? 'e.g. The inspector will try to be there between 2pm and 4pm.'
    : 'e.g. I will be home between 2pm and 4pm.'
  const caption = isGov
    ? 'You may add some notes for the applicant.'
    : 'You may add some notes for the staff.'

  const title = `Schedule Inspection`

  return (
    <Modal
      {...modalState}
      icon={faCalendar}
      title={title}
      okText="Schedule"
      onOk={onOk}
      formInitialValues={{
        inspectorUserId: defaultInspectorUserId,
        date: recordTaskInspectionAttemptRequestedFor
          ? dayjs(recordTaskInspectionAttemptRequestedFor)
          : undefined,
      }}
    >
      <FormContainer form={form} gap="16px">
        {inspectionSchedulingInstructions && (
          <InspectionSchedulingInstructions
            inspectionSchedulingInstructions={inspectionSchedulingInstructions}
          />
        )}
        <InspectionMetadata {...props} />
        {isLoadingIntakeForm && <Skeleton active />}
        {!!intakeFormFieldGroup?.fields?.length && (
          <VerticalFieldValues fields={intakeFormFieldGroup.fields} />
        )}
        {isGov && (
          <OwnerSelect
            label="Inspector"
            fieldName="inspectorUserId"
            caption="The inspector who will be assigned this inspection"
            placeholder="Inspector"
            required
            errorMessage="You must pick an inspector for this inspection."
            filterUser={(u: UserDataFragment) => !!u.isInspector}
            noMargin
          />
        )}
        <DatePicker
          fieldName="date"
          label="Inspection Date"
          required
          errorMessage="You must enter a date"
          allowedDates={isGov ? DatePickerAllowedDates.All : DatePickerAllowedDates.Future}
          disableWeekends
          cutoffTime={isGov || isNil(inspectionCutoffTime) ? undefined : inspectionCutoffTime}
          noMargin
        />
        <TextArea
          fieldName="schedulingNotes"
          label="Notes"
          placeholder={placeholder}
          caption={caption}
          noMargin
        />
        {isLoadingIntakeFormTemplate && <Skeleton active />}
        {!isLoadingIntakeFormTemplate && (
          <Flex vertical gap="24px">
            {intakeFormFieldGroupTemplate?.fields?.map((f: FieldFragment) => (
              <RenderedFormFieldInput
                key={f.id}
                formFieldInput={{
                  id: f.id,
                  input: mapFieldToSingleFormFieldInput({ field: f }),
                }}
                fieldNameSuffix={`${f.id}`}
                errorMessage={f.required ? 'Please enter a valid value.' : undefined}
                addressEntryType={addressEntryType}
                noMargin
              />
            ))}
          </Flex>
        )}
      </FormContainer>
    </Modal>
  )
}
