import { useContext } from 'react'

import styled from 'styled-components'

import { PageTitle } from 'src/components/shared/StyledComponents'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

type LayoutType = 'stretch' | 'overflow'

const LayoutContainer = styled.div<{
  $minHeight?: React.CSSProperties['minHeight']
  $maxHeight?: React.CSSProperties['maxHeight']
  $overflowY?: React.CSSProperties['overflowY']
}>`
  width: 100%;
  height: 100%;
  ${({ $minHeight }) => `min-height: ${$minHeight ?? 'fit-content'};`};
  ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight};` : '')};
  ${({ $overflowY }) => ($overflowY ? `overflow-y: ${$overflowY};` : '')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

type PageProps = {
  children: React.ReactNode
  layoutType?: LayoutType
}
const Page = ({ children, layoutType = 'stretch' }: PageProps) => {
  return (
    <PageContext.Provider value={{ layoutType }}>
      <LayoutContainer
        {...(layoutType === 'overflow'
          ? {
              $minHeight: '0',
              $maxHeight: '100%',
            }
          : {})}
      >
        {children}
      </LayoutContainer>
    </PageContext.Provider>
  )
}

type PageHeaderProps = {
  backButtonHref?: string
  icon?: string | null
  noPadding?: boolean
  primaryButton?: React.ReactNode
  showRouterBackButton?: boolean
  subtitle?: React.ReactNode
  title: React.ReactNode
}
const PageHeader = ({ noPadding, ...props }: PageHeaderProps) => {
  const { isSmallScreen } = useCurrentBreakpoint()
  const { layoutType } = usePageContext()
  const paddingPx = isSmallScreen() ? 16 : 24
  const bottomPadding = isSmallScreen() && layoutType === 'overflow' ? '8px' : 0
  return (
    <div
      style={{
        width: '100%',
        padding: noPadding ? 0 : `${paddingPx}px ${paddingPx}px ${bottomPadding} ${paddingPx}px`, // TODO: Add this styling to PageTitle once it's not used anywhere except in a <Page />
      }}
    >
      <PageTitle {...props} />
    </div>
  )
}

const Main = styled.main<{
  $isSmall: boolean
  $overflowY?: React.CSSProperties['overflowY']
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${({ $overflowY }) => ($overflowY ? `overflow-y: ${$overflowY};` : '')};
  padding: ${({ $isSmall }) => ($isSmall ? '16px' : '24px')};
`

type PageContentProps = {
  children: React.ReactNode
}
const PageContent = ({ children }: PageContentProps) => {
  const { layoutType } = usePageContext()
  const { isSmallScreen } = useCurrentBreakpoint()
  return (
    <Main {...(layoutType === 'overflow' ? { $overflowY: 'auto' } : {})} $isSmall={isSmallScreen()}>
      {children}
    </Main>
  )
}

type PageContextType = {
  layoutType: LayoutType
}
const PageContext = React.createContext<PageContextType>({} as PageContextType)
const usePageContext = () => useContext(PageContext)

export default Object.assign(Page, {
  Header: PageHeader,
  Content: PageContent,
})
