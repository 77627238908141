import {
  SearchCSLBContractorsMutation,
  SearchCSLBContractorsMutationVariables,
  CreateCSLBContractorSnapshotMutation,
  CreateCSLBContractorSnapshotMutationVariables,
} from '../../types/graphql'
import { useMutation } from '../utils/queryClient'

const SearchCSLBContractors = gql`
  mutation SearchCSLBContractorsMutation($input: SearchCSLBContractorsInput!) {
    searchCSLBContractors(input: $input) {
      results {
        ...CSLBContractorResponseFragment
      }
    }
  }
  fragment CSLBContractorResponseFragment on CSLBContractorResponse {
    displayName
    licenseNumber
  }
`

export const useSearchCSLBContractorsMutation = () => {
  return useMutation<SearchCSLBContractorsMutation, SearchCSLBContractorsMutationVariables>({
    mutationDocument: SearchCSLBContractors,
  })
}

const CreateCSLBContractorSnapshot = gql`
  mutation CreateCSLBContractorSnapshotMutation($input: CreateCSLBContractorSnapshotInput!) {
    createCSLBContractorSnapshot(input: $input) {
      ...CSLBContractorSnapshotFragment
    }
  }
`

export const useCreateCSLBContractorSnapshotMutation = () => {
  return useMutation<
    CreateCSLBContractorSnapshotMutation,
    CreateCSLBContractorSnapshotMutationVariables
  >({
    mutationDocument: CreateCSLBContractorSnapshot,
  })
}
