import { forwardRef, useState } from 'react'

import FormControl from 'govwell-ui/components/FormControl/FormControl'
import { FormControlProps } from 'govwell-ui/components/FormControl/util'
import InputBase, { InputBaseProps } from 'govwell-ui/components/Input/InputBase'
import { v4 as uuidv4 } from 'uuid'

type Props = InputBaseProps & FormControlProps
const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      ariaLabel,
      autoFocus,
      caption,
      errorMessage,
      id: propId,
      isClearable,
      isDisabled,
      isInvalid,
      isLoading,
      isReadOnly,
      isRequired,
      label,
      leftAddon,
      onBlur,
      onClick,
      onFocus,
      onKeyDown,
      onValueChange,
      placeholder,
      prefix,
      prefixIcon,
      rightAddon,
      size,
      suffix,
      suffixIcon,
      value,
      width,
    },
    ref
  ) => {
    const [id] = useState(propId ?? uuidv4())
    return (
      <FormControl
        caption={caption}
        id={id}
        label={label}
        isRequired={isRequired}
        width={width}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
      >
        {({ ariaDescribedBy, ariaInvalid, ariaLive }) => (
          <InputBase
            ariaDescribedBy={ariaDescribedBy}
            ariaInvalid={ariaInvalid}
            ariaLabel={ariaLabel}
            ariaLive={ariaLive}
            autoFocus={autoFocus}
            id={id}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            leftAddon={leftAddon}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onValueChange={onValueChange}
            placeholder={placeholder}
            prefix={prefix}
            prefixIcon={prefixIcon}
            ref={ref}
            rightAddon={rightAddon}
            size={size}
            suffix={suffix}
            suffixIcon={suffixIcon}
            value={value}
            width="100%"
          />
        )}
      </FormControl>
    )
  }
)

export default Input
