/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useState } from 'react'

import {
  faArrowsRepeat,
  faFileCheck,
  faFilePdf,
  faMagnifyingGlassPlus,
  faPen,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Image, Tooltip } from 'antd'
import styled from 'styled-components'

import FileNameInput from 'src/components/FileUpload/states/FileNameInput'
import { useRenameFileMutation } from 'src/fetch/files'
import useDisclosure from 'src/hooks/use-disclosure'

import { FileUploadStatuses } from '../../../hooks/use-file-upload-state'
import { useTheme } from '../../../hooks/use-theme'
import { formatBytes, isImage, isPdf } from '../../../utils'
import Text, { TextSize } from '../../Typography/Text'

const PreviewIndicator = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme['cyan-7']};
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: solid 0.5px ${({ theme }) => theme['cyan-7']};
  position: absolute;
  top: -7.5px;
  right: -7.5px;
  padding: 3px;
  font-size: 8px;
  cursor: pointer;
`

interface Props {
  id: number | undefined
  name: string
  onRenameFile: (value: string) => void
  onRemoveFile: () => void
  status: FileUploadStatuses
  size: number | undefined
  thumbnailUrl: string | undefined
  type: string | undefined
  url: string | undefined
}

export const Done = (props: Props) => {
  const {
    id,
    name: originalFileName,
    onRenameFile,
    onRemoveFile,
    status,
    size,
    thumbnailUrl,
    type,
    url,
  } = props
  const [fileName, setFileName] = useState(originalFileName)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const editNameState = useDisclosure()
  const previewModalState = useDisclosure()
  const { isOpen: isIndicatorShown, open: startShowingIndicator } = useDisclosure()
  const { isOpen: isNameHovered, open: onMouseEnterName, close: onMouseLeaveName } = useDisclosure()
  const showImage = type && isImage(type) && url && thumbnailUrl
  const icon = type && isPdf(type) ? faFilePdf : faFileCheck
  const { getTokenVal } = useTheme()

  const handleImageClicked = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      previewModalState.toggle()
    },
    [previewModalState]
  )

  const handleNameClicked = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      onMouseLeaveName()
      editNameState.open()
    },
    [editNameState, onMouseLeaveName]
  )

  const handleNameEditsCancelled = useCallback(() => {
    editNameState.close()
  }, [editNameState])

  const { mutateAsync: renameFile, isPending: isRenamingFile } = useRenameFileMutation()
  const handleNameEditsSaved = useCallback(async () => {
    if (!id) {
      return
    }
    if (fileName !== originalFileName) {
      await renameFile({ id, newName: fileName })
      onRenameFile(fileName)
    }
    editNameState.close()
  }, [editNameState, fileName, id, onRenameFile, originalFileName, renameFile])

  const handleRemoveClicked = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      onRemoveFile()
    },
    [onRemoveFile]
  )

  return (
    <Flex gap="16px" align="center" justify="space-between" style={{ width: '100%' }}>
      <Flex gap="12px" align="center" flex={1}>
        <Flex
          onClick={handleImageClicked}
          style={{ minWidth: '40px', position: 'relative' }}
          justify="center"
        >
          {!showImage && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                height: 24,
                width: 24,
                color: getTokenVal('colorSuccessBase'),
              }}
            />
          )}
          {showImage && (
            <>
              <Image
                style={{
                  maxWidth: '40px',
                  maxHeight: '40px',
                  display: 'block',
                  margin: 'auto',
                }}
                src={thumbnailUrl}
                preview={{
                  src: url,
                  visible: previewModalState.isOpen,
                }}
                onLoad={startShowingIndicator}
                alt={originalFileName}
              />
              {isIndicatorShown && (
                <PreviewIndicator icon={faMagnifyingGlassPlus} onClick={handleImageClicked} />
              )}
            </>
          )}
        </Flex>
        {editNameState.isOpen ? (
          <FileNameInput
            errorMessage={errorMessage}
            fileName={fileName}
            isSaving={isRenamingFile}
            onErrorMessageChange={setErrorMessage}
            onFileNameChange={setFileName}
            onCancel={handleNameEditsCancelled}
            onSave={handleNameEditsSaved}
            width="100%"
          />
        ) : (
          <Tooltip title="Edit file name">
            <Text
              size={TextSize.Base}
              wordBreak="break-all"
              style={{ textAlign: 'left', cursor: 'pointer' }}
              onClick={handleNameClicked}
              onMouseEnter={onMouseEnterName}
              onMouseLeave={onMouseLeaveName}
              colorToken={isNameHovered ? 'colorPrimaryBase' : undefined}
            >
              {fileName}
              &nbsp;
              <FontAwesomeIcon icon={faPen} opacity={isNameHovered ? 1 : 0.3} />
            </Text>
          </Tooltip>
        )}
      </Flex>
      <Flex gap="4px" align="center">
        {size && (
          <Text size={TextSize.Small} noWrap>
            {formatBytes(size)}
          </Text>
        )}
        <Flex>
          <Tooltip title="Remove file">
            <Button
              type="text"
              onClick={handleRemoveClicked}
              icon={<FontAwesomeIcon icon={faTrash} />}
              size="small"
            />
          </Tooltip>
          {status === FileUploadStatuses.Existing && (
            <Tooltip title="Replace file">
              <Button type="text" icon={<FontAwesomeIcon icon={faArrowsRepeat} />} size="small" />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
