import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import dayjs, { Dayjs } from 'dayjs'
import { Calendar, Input, Popover } from 'govwell-ui'
import {
  formatDateForInputText,
  getIsStringCompleteDate,
} from 'govwell-ui/components/DatePicker/util'
import { MenuContentStyles } from 'govwell-ui/components/Menu/Menu'
import styled from 'styled-components'

import useDisclosure from 'src/hooks/use-disclosure'

const DatePickerPopoverContent = styled(Popover.Content)`
  ${MenuContentStyles};
`

type Props = {
  caption?: React.ReactNode
  label?: React.ReactNode
  onValueChange: (value: Dayjs | null) => void
  placeholder?: string
  value: Dayjs | null | undefined
}
const DatePicker = ({ caption, label, onValueChange, placeholder, value }: Props) => {
  const popoverState = useDisclosure()
  const popoverContentRef = useRef<HTMLDivElement>(null)

  const inputRef = useRef<HTMLInputElement>(null)
  const defaultInputText = useMemo(() => formatDateForInputText(value), [value])
  const [inputText, setInputText] = useState(defaultInputText) // The user's temporary text entry
  const defaultMonth = useMemo(() => value ?? dayjs(), [value])
  const [month, setMonth] = useState(defaultMonth) // The month which is being viewed in the popover calendar

  const selectedDate = useMemo(() => value?.toDate(), [value])
  const selectedMonth = useMemo(() => month.toDate(), [month])

  const { isOpen: hasFocus, open: focus, close: blur } = useDisclosure()

  useEffect(() => {
    if (!hasFocus) {
      // If the prop value changes while the input does not have focus, reset the input
      setInputText(defaultInputText)
    }
  }, [defaultInputText, hasFocus])

  const handleInputTextChanged = useCallback(
    (newValue: string | undefined) => {
      if (!newValue) {
        setInputText('')
        onValueChange(null)
        return
      }

      setInputText(newValue)
      if (getIsStringCompleteDate(newValue)) {
        onValueChange(dayjs(newValue))
        setMonth(dayjs(newValue))
      }
    },
    [onValueChange]
  )

  const handleOpen = useCallback(() => {
    if (!popoverState.isOpen) {
      setMonth(defaultMonth)
      popoverState.open()
    }
  }, [defaultMonth, popoverState])

  const handleInputClicked = useCallback(() => {
    handleOpen()
  }, [handleOpen])

  const handleInputFocused = useCallback(() => {
    focus()
    handleOpen()
  }, [focus, handleOpen])

  const handleInputBlurred = useCallback(() => {
    blur()
    setTimeout(() => {
      // Timeout needed to skip render cycle for activeElement to update (where focus is)
      if (!popoverContentRef.current?.contains(document.activeElement)) {
        // Don't close the popover if it gained focus
        popoverState.close()
      }
    })

    setInputText(defaultInputText)
  }, [blur, defaultInputText, popoverState])

  const handleInteractOutsidePopover = useCallback(
    (
      e: Parameters<
        NonNullable<React.ComponentProps<typeof Popover.Content>['onInteractOutside']>
      >[0]
    ) => {
      if (hasFocus) {
        e.preventDefault()
      }
    },
    [hasFocus]
  )

  const handleMonthChanged = useCallback((newValue: Date | undefined) => {
    setMonth(newValue ? dayjs(newValue) : dayjs())
  }, [])

  const handleCalendarDateSelected = useCallback(
    (newValue: Date | undefined) => {
      const newDateValue = newValue ? dayjs(newValue) : null
      onValueChange(newDateValue)
      if (newValue) {
        setMonth(dayjs(newValue))
      }
      popoverState.close()
      setInputText(formatDateForInputText(newDateValue))
      inputRef.current?.focus()
    },
    [onValueChange, popoverState]
  )

  return (
    <Popover isOpen={popoverState.isOpen} onOpen={handleOpen} onClose={popoverState.close}>
      <Popover.Anchor asChild>
        <Input
          caption={caption}
          label={label}
          onClick={handleInputClicked}
          onBlur={handleInputBlurred}
          onFocus={handleInputFocused}
          onValueChange={handleInputTextChanged}
          placeholder={placeholder ?? 'Select a date'}
          ref={inputRef}
          suffixIcon={faCalendar}
          type="date"
          value={inputText}
        />
      </Popover.Anchor>
      <DatePickerPopoverContent
        align="start"
        alignOffset={-12}
        autoFocus={false}
        sideOffset={4}
        onFocusOutside={(e) => e.preventDefault()}
        onInteractOutside={handleInteractOutsidePopover}
        onOpenAutoFocus={(e) => e.preventDefault()}
        ref={popoverContentRef}
      >
        <Calendar
          mode="single"
          month={selectedMonth}
          onMonthChange={handleMonthChanged}
          onSelect={handleCalendarDateSelected}
          selected={selectedDate}
        />
      </DatePickerPopoverContent>
    </Popover>
  )
}

export default React.memo(DatePicker)
