import { ReactNode } from 'react'

type FormControlLabelProps =
  | {
      ariaLabel?: never
      label: ReactNode
    }
  | {
      ariaLabel: string
      label?: never
    }
export type FormControlProps = FormControlLabelProps & {
  caption?: ReactNode
  errorMessage?: ReactNode
  id?: string
  isInvalid?: boolean
  isRequired?: boolean
  label?: ReactNode
  width?: React.CSSProperties['width']
}

export const getCaptionId = (id: string) => `${id}-caption`
export const getErrorMessageId = (id: string) => `${id}-error`
export const GovwellFormFieldErrorClassName = 'govwell-form-field-error'

export const useBaseAriaAttributes = (
  args: FormControlProps
): {
  ariaInvalid?: React.HTMLProps<HTMLElement>['aria-invalid']
  ariaLive?: React.HTMLProps<HTMLElement>['aria-live']
  ariaDescribedBy?: React.HTMLProps<HTMLElement>['aria-describedby']
} => {
  const { caption, errorMessage, id, isInvalid } = args

  let ariaDescribedBy = undefined
  if (id) {
    ariaDescribedBy = [
      ...(caption ? [getCaptionId(id)] : []),
      ...(errorMessage ? [getErrorMessageId(id)] : []),
    ].join(' ')
  }

  return {
    ...(isInvalid ? { ariaInvalid: true } : {}),
    ...(isInvalid && errorMessage ? { ariaLive: 'polite' } : {}),
    ...(ariaDescribedBy ? { ariaDescribedBy } : {}),
  }
}
