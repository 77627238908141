import { ReactNode } from 'react'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Form, Radio, RadioGroupProps, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { styled } from 'styled-components'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props<ValueType = unknown> extends FormFieldWrapperProps {
  items: RadioItem[]
  defaultFirstValue?: boolean
  vertical?: boolean
  size?: SizeType
  optionType?: RadioGroupProps['optionType']
  style?: RadioGroupProps['style']
  onChange?: (newValue: ValueType) => Promise<void>
}

export enum RadioType {
  Success = 'Success',
  Neutral = 'Neutral',
  Danger = 'Danger',
}

export type RadioItem<Type = string> = {
  value: Type
  display: React.ReactNode
  type?: RadioType
  onClick?: () => Promise<void>
  tooltip?: ReactNode
  icon?: IconDefinition
}

interface ItemsContainerProps {
  $hasLabel: boolean
  $vertical?: boolean
}
const ItemsContainer = styled.div<ItemsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 0;
  ${(props) => props.$hasLabel && 'margin-top: 12px;'}
  width: 100%;
  ${(props) => props.$vertical && `flex-direction: column;`}
  .${RadioType.Success}.ant-radio-button-wrapper-checked {
    border-color: ${(props) => props.theme.colorSuccessBase};
    background: ${(props) => props.theme.colorSuccessBase};
  }
  .${RadioType.Success}.ant-radio-button-wrapper:hover {
    border-color: ${(props) => props.theme.colorSuccessBase} !important;
    background: ${(props) => props.theme.colorSuccessBase} !important;
    color: white;
  }
  .${RadioType.Success}.ant-radio-button-wrapper:before {
    background: ${(props) => props.theme.colorSuccessBase} !important;
  }

  .${RadioType.Neutral}.ant-radio-button-wrapper-checked {
    border-color: transparent !important;
    background: ${(props) => props.theme.colorTextTertiary} !important;
  }
  .${RadioType.Neutral}.ant-radio-button-wrapper:hover {
    border-color: transparent !important;
    background: ${(props) => props.theme.colorTextTertiary} !important;
    color: white;
  }
  .${RadioType.Neutral}.ant-radio-button-wrapper:before {
    display: none;
  }
  .${RadioType.Neutral}.ant-radio-button-wrapper-checked:before:hover {
    background-color: ${(props) => props.theme.colorTextLabel};
  }

  .${RadioType.Danger}.ant-radio-button-wrapper-checked {
    border-color: ${(props) => props.theme.colorErrorBase};
    background: ${(props) => props.theme.colorErrorBase};
  }
  .${RadioType.Danger}.ant-radio-button-wrapper:hover {
    border-color: ${(props) => props.theme.colorErrorBase} !important;
    background: ${(props) => props.theme.colorErrorBase} !important;
    color: white;
  }
  .${RadioType.Danger}.ant-radio-button-wrapper:before {
    display: none;
  }
  .${RadioType.Danger}.ant-radio-button-wrapper:hover:before {
    background-color: ${(props) => props.theme.colorErrorBase} !important;
  }
`

export function RadioGroup<T>(props: Props<T>) {
  const {
    items,
    defaultFirstValue,
    disabled,
    vertical,
    size,
    optionType,
    style,
    onChange,
    ...formFieldWrapperProps
  } = props
  const { label, fieldName } = formFieldWrapperProps
  const initialValue = defaultFirstValue ? items[0]?.value : undefined
  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  const currentValue = form.getFieldValue(fieldName)

  return (
    <Wrapper {...formFieldWrapperProps} initialValue={initialValue}>
      <Radio.Group
        disabled={disabled}
        size={size}
        optionType={optionType}
        buttonStyle="solid"
        style={style}
        onChange={(e) => {
          onChange?.(e?.target?.value)
        }}
      >
        <ItemsContainer $hasLabel={!!label} $vertical={vertical}>
          {items.map(({ value, display, type, onClick, tooltip, icon }) => (
            <Tooltip title={tooltip} key={`${fieldName}-${value}`}>
              <Radio
                value={value}
                className={type}
                onClick={onClick}
                style={{ forcedColorAdjust: 'none' }}
              >
                <Flex
                  gap="4px"
                  align="center"
                  justify="center"
                  style={{ forcedColorAdjust: 'auto' }}
                >
                  {icon && currentValue === value ? <FontAwesomeIcon icon={icon} /> : null}{' '}
                  {display}
                </Flex>
              </Radio>
            </Tooltip>
          ))}
        </ItemsContainer>
      </Radio.Group>
    </Wrapper>
  )
}
