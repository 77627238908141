import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { Input } from 'govwell-ui'
import { useMenuKeyboardShortcuts } from 'govwell-ui/components/Menu/use-menu-keyboard-shortcuts'
import { v4 as uuid } from 'uuid'

import { navigate, routes } from '@redwoodjs/router'

import EmptyState from 'src/components/EmptyState'
import { RecordAddress } from 'src/components/shared/StyledComponents'
import Text, { TextSize } from 'src/components/Typography/Text'
import UniversalSearchTypeRadioGroup from 'src/components/UniversalSearchModal/UniversalSearchTypeRadio'
import {
  UniversalSearchOption,
  UniversalSearchOptionType,
  UniversalSearchType,
} from 'src/components/UniversalSearchModal/util'
import { useTheme } from 'src/hooks/use-theme'
import { getUrlForRecord } from 'src/utils/records'

import { useUniversalSearchQuery } from '../../fetch/universal-search'
import useDebounce from '../../hooks/use-debounce'
import { UseDisclosureReturn } from '../../hooks/use-disclosure'
import Modal from '../Modal'

import UniversalSearchModalFooter from './UniversalSearchModalFooter'
import UniversalSearchResultTemplate from './UniversalSearchResultTemplate'

type Props = {
  modalState: UseDisclosureReturn
}

const UniversalSearchModal = ({ modalState }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState('')
  const [searchType, setSearchType] = useState<UniversalSearchType>({
    label: 'All',
    mode: 'all',
  })
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    if (!modalState.isOpen) {
      setQuery('')
    }
    let timeout: NodeJS.Timeout
    if (modalState.isOpen) {
      timeout = setTimeout(() => {
        inputRef.current?.focus()
      })
    }
    return () => timeout && clearTimeout(timeout)
  }, [modalState.isOpen])

  const { data, isFetching } = useUniversalSearchQuery(debouncedQuery)
  const options: UniversalSearchOption[] = useMemo(() => {
    if (!data?.universalSearch) {
      return []
    }
    const results: UniversalSearchOption[] = []
    const addAddresses = () =>
      data.universalSearch.addresses.forEach((address) =>
        results.push({
          type: UniversalSearchOptionType.Address,
          title: 'Address',
          subtitle: address,
          subtitleSortString: address,
          url: routes.addressOverview({ addressDisplayName: address }),
          uuid: uuid(),
        })
      )
    const addParcels = () =>
      data.universalSearch.parcels.forEach((parcel) =>
        results.push({
          type: UniversalSearchOptionType.Parcel,
          title: 'Parcel',
          subtitle: parcel,
          subtitleSortString: parcel,
          url: routes.parcelOverview({ parcelDisplayName: parcel }),
          uuid: uuid(),
        })
      )
    const addRecords = (recordTypeId?: number) =>
      data.universalSearch.records
        .filter((record) => !recordTypeId || record.recordTemplate?.recordType?.id === recordTypeId)
        .forEach((record) => {
          const recordType = record.recordTemplate?.recordType
          results.push({
            type: UniversalSearchOptionType.Record,
            title: `${recordType?.name} ${recordType?.recordName}`,
            subtitle: (
              <Flex align="center" gap="6px">
                <Text>#{record.issuedIdentifier ?? record.identifier}</Text>
                {!!record.addressField?.str && (
                  <>
                    <Text>·</Text>
                    <RecordAddress record={record} />
                  </>
                )}
              </Flex>
            ),
            subtitleSortString: `${
              record.issuedIdentifier ?? record.identifier
            }${record.addressField?.str ?? ''}`,
            url: getUrlForRecord({
              isDraft: !!record.isDraft,
              organizationSlug: record.organization?.slug,
              recordUuid: record.uuid,
            }),
            uuid: uuid(),
          })
        })
    const addViolations = () =>
      data.universalSearch.violations.forEach((violation) =>
        results.push({
          type: UniversalSearchOptionType.Violation,
          title: 'Violation',
          subtitle: `#${violation.identifier} · ${violation.address}`,
          subtitleSortString: `${violation.identifier}${violation.address}`,
          url: routes.codeComplaint({ identifier: violation.identifier }),
          uuid: uuid(),
        })
      )
    switch (searchType.mode) {
      case 'all':
        addAddresses()
        addParcels()
        addRecords()
        addViolations()
        break
      case 'addresses':
        addAddresses()
        break
      case 'parcels':
        addParcels()
        break
      case 'records':
        addRecords(searchType.recordTypeId)
        break
      case 'violations':
        addViolations()
        break
    }
    return results.sort(
      (a, b) =>
        a.title.localeCompare(b.title) || a.subtitleSortString.localeCompare(b.subtitleSortString)
    )
  }, [data?.universalSearch, searchType.mode, searchType.recordTypeId])

  const handleOptionClicked = useCallback(
    (option: UniversalSearchOption, openInNewTab?: boolean) => {
      if (openInNewTab) {
        window.open(option.url)
      } else {
        navigate(option.url)
        modalState.close()
      }
    },
    [modalState]
  )

  const [activeIndex, setActiveIndex] = useState(0)
  const { menuItemRefs } = useMenuKeyboardShortcuts({
    activeIndex,
    count: options.length,
    isEnabled: modalState.isOpen,
    onEscape: modalState.close,
    onSelect: (e) => {
      const option = options[activeIndex]
      if (option) {
        handleOptionClicked(option, e.ctrlKey || e.metaKey)
      }
    },
    setActiveIndex,
  })
  useEffect(() => {
    setActiveIndex(0)
    const container = containerRef.current
    if (container) {
      container.scrollTop = 0
    }
  }, [data, setActiveIndex])

  const theme = useTheme()

  return (
    <Modal {...modalState} title="Search" icon={faSearch} onOk={() => {}} footer={null}>
      <div
        ref={containerRef}
        style={{
          height: '60vh',
          maxHeight: '80vh',
          overflow: 'hidden',
          margin: '-12px -24px -12px -12px',
          paddingRight: '12px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            boxShadow: `0px 4px 12px -2px ${theme.getTokenVal('colorBordersecondary')}`,
            clipPath: 'inset(0px 0px -12px 0px)',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          <Input
            ariaLabel="Search input"
            value={query}
            onValueChange={setQuery}
            prefixIcon={faSearch}
            isLoading={isFetching}
            width="100%"
            placeholder="Search record #, parcel ID, or address"
            size="lg"
            autoFocus
            ref={inputRef}
          />
          <UniversalSearchTypeRadioGroup
            searchType={searchType}
            onSearchTypeChange={setSearchType}
          />
          {!!options.length && (
            <Flex justify="center" style={{ paddingBottom: '6px' }}>
              <Text size={TextSize.ExtraSmall} colorToken="colorTextTertiary">
                Showing closest matching results
              </Text>
            </Flex>
          )}
        </div>
        <Flex
          vertical
          role="menu"
          tabIndex={0}
          style={{ flex: 1, overflowY: 'auto' }}
          aria-activedescendant={options[activeIndex]?.uuid}
        >
          {isFetching && <Skeleton active />}
          {!isFetching &&
            options.map((option, index) => (
              <UniversalSearchResultTemplate
                key={option.uuid}
                isActive={activeIndex === index}
                onMouseMove={() => setActiveIndex(index)}
                option={option}
                ref={(el) => menuItemRefs.current.set(index, el)}
              />
            ))}
        </Flex>
        {!options.length && (
          <EmptyState hideBorder>
            <EmptyState.Image />
            <EmptyState.Message>No results</EmptyState.Message>
          </EmptyState>
        )}
        <div
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 1,
          }}
        >
          <UniversalSearchModalFooter count={options.length} />
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(UniversalSearchModal)
