import isNil from 'lodash.isnil'

export enum NumberFormat {
  Integer,
  Float,
  Currency,
}

export const formatNumber = (
  value: number | null | undefined,
  args?: {
    format?: NumberFormat
    maximumFractionDigits?: number
    minimumFractionDigits?: number
    useGrouping?: boolean
  }
): string => {
  if (isNil(value) || isNaN(value)) {
    return ''
  }

  const format = args?.format ?? NumberFormat.Integer
  const useGrouping = args?.useGrouping ?? true
  let minimumFractionDigits = 0
  let maximumFractionDigits = 0
  switch (format) {
    case NumberFormat.Currency:
      minimumFractionDigits = 2
      maximumFractionDigits = 2
      break
    case NumberFormat.Float:
      maximumFractionDigits = 6
      break
  }

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: args?.minimumFractionDigits ?? minimumFractionDigits,
    maximumFractionDigits: args?.maximumFractionDigits ?? maximumFractionDigits,
    useGrouping,
    ...(format === NumberFormat.Currency
      ? {
          style: 'currency',
          currency: 'USD',
        }
      : {}),
  }).format(value)
}
