import { useMemo } from 'react'

import omit from 'lodash.omit'
import {
  CancelMultipleRecordTaskInspectionAttemptsMutation,
  CancelMultipleRecordTaskInspectionAttemptsMutationVariables,
  CancelRecordTaskInspectionAttemptMutation,
  CancelRecordTaskInspectionAttemptMutationVariables,
  ConvertRecordTaskInspectionAttemptToDraftMutation,
  ConvertRecordTaskInspectionAttemptToDraftMutationVariables,
  DeleteMultipleRecordTaskInspectionsMutation,
  DeleteMultipleRecordTaskInspectionsMutationVariables,
  DeleteRecordTaskInspectionMutation,
  DeleteRecordTaskInspectionMutationVariables,
  FetchInspectionAttemptReportFileQuery,
  FetchInspectionAttemptReportFileQueryVariables,
  GetSchedulingIntakeFormForRecordTaskInspectionAttemptQuery,
  GetSchedulingIntakeFormForRecordTaskInspectionAttemptQueryVariables,
  GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQuery,
  GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQueryVariables,
  LogRecordTaskInspectionAttemptMutation,
  LogRecordTaskInspectionAttemptMutationVariables,
  ReassignMultipleRecordTaskInspectionAttemptsMutation,
  ReassignMultipleRecordTaskInspectionAttemptsMutationVariables,
  ReassignRecordTaskInspectionAttemptMutation,
  ReassignRecordTaskInspectionAttemptMutationVariables,
  RequestRecordTaskInspectionAttemptMutation,
  RequestRecordTaskInspectionAttemptMutationVariables,
  RescheduleRecordTaskInspectionAttemptMutation,
  RescheduleRecordTaskInspectionAttemptMutationVariables,
  ScheduleMultipleRecordTaskInspectionAttemptsMutation,
  ScheduleMultipleRecordTaskInspectionAttemptsMutationVariables,
  ScheduleRecordTaskInspectionAttemptMutation,
  ScheduleRecordTaskInspectionAttemptMutationVariables,
  SubmitRecordTaskInspectionAttemptDraftMutation,
  SubmitRecordTaskInspectionAttemptDraftMutationVariables,
  UpdateRecordTaskInspectionAttemptDraftMutation,
  UpdateRecordTaskInspectionAttemptDraftMutationVariables,
  UpdateRecordTaskInspectionAttemptMutation,
  UpdateRecordTaskInspectionAttemptMutationVariables,
} from 'types/graphql'

import { downloadFile } from '../utils/download'
import { QueryKey, useMutation, useQuery, UseQueryOptions } from '../utils/queryClient'

const QUERY = gql`
  query FetchInspectionAttemptReportFileQuery($recordTaskInspectionAttemptId: Int!) {
    recordTaskInspectionAttempt(id: $recordTaskInspectionAttemptId) {
      id
      reportFile {
        id
        url {
          signedUrl
        }
        type
        name
        downloadableName
      }
    }
  }
`

export const useDownloadInspectionReport = (recordTaskInspectionAttemptId: number) => {
  const { refetch, isRefetching } = useQuery<
    FetchInspectionAttemptReportFileQuery,
    FetchInspectionAttemptReportFileQueryVariables
  >({
    enabled: false,
    queryKey: [QueryKey.RecordTaskInspectionAttemptFile, recordTaskInspectionAttemptId],
    queryDocument: QUERY,
    variables: { recordTaskInspectionAttemptId },
  })
  return {
    fn: async () => {
      if (!isRefetching) {
        const result = await refetch()
        const file = result.data?.recordTaskInspectionAttempt?.reportFile
        if (!file) {
          return
        }
        await downloadFile({
          url: file.url?.signedUrl ?? '',
          downloadableName: file.downloadableName ?? '',
        })
      }
    },
    isLoading: isRefetching,
  }
}

const ScheduleMultipleRecordTaskInspectionAttempts = gql`
  mutation ScheduleMultipleRecordTaskInspectionAttemptsMutation(
    $ids: [Int!]!
    $input: ScheduleRecordTaskInspectionAttemptInput!
  ) {
    scheduleMultipleRecordTaskInspectionAttempts(ids: $ids, input: $input) {
      id
    }
  }
`

export const useScheduleMultipleRecordTaskInspectionAttemptsMutation = () => {
  return useMutation<
    ScheduleMultipleRecordTaskInspectionAttemptsMutation,
    ScheduleMultipleRecordTaskInspectionAttemptsMutationVariables
  >({
    mutationDocument: ScheduleMultipleRecordTaskInspectionAttempts,
  })
}

const Cancel = gql`
  mutation CancelRecordTaskInspectionAttemptMutation($id: Int!) {
    cancelRecordTaskInspectionAttempt(id: $id) {
      id
    }
  }
`
export const useCancelRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    CancelRecordTaskInspectionAttemptMutation,
    CancelRecordTaskInspectionAttemptMutationVariables
  >({ mutationDocument: Cancel })
}

const Delete = gql`
  mutation DeleteRecordTaskInspectionMutation($id: Int!) {
    deleteRecordTaskInspection(id: $id) {
      id
    }
  }
`

export const useDeleteRecordTaskInspectionMutation = () => {
  return useMutation<
    DeleteRecordTaskInspectionMutation,
    DeleteRecordTaskInspectionMutationVariables
  >({
    mutationDocument: Delete,
  })
}

const Reassign = gql`
  mutation ReassignRecordTaskInspectionAttemptMutation(
    $id: Int!
    $input: ReassignRecordTaskInspectionAttemptInput!
  ) {
    reassignRecordTaskInspectionAttempt(id: $id, input: $input) {
      id
    }
  }
`
export const useReassignRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    ReassignRecordTaskInspectionAttemptMutation,
    ReassignRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Reassign,
  })
}

const Request = gql`
  mutation RequestRecordTaskInspectionAttemptMutation(
    $id: Int!
    $input: RequestTaskInspectionAttemptInput!
  ) {
    requestRecordTaskInspectionAttempt(id: $id, input: $input) {
      id
    }
  }
`
export const useRequestRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    RequestRecordTaskInspectionAttemptMutation,
    RequestRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Request,
  })
}

const Reschedule = gql`
  mutation RescheduleRecordTaskInspectionAttemptMutation($id: Int!, $scheduledFor: DateTime!) {
    rescheduleRecordTaskInspectionAttempt(id: $id, scheduledFor: $scheduledFor) {
      id
    }
  }
`

export const useRescheduleRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    RescheduleRecordTaskInspectionAttemptMutation,
    RescheduleRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Reschedule,
  })
}

const Schedule = gql`
  mutation ScheduleRecordTaskInspectionAttemptMutation(
    $id: Int!
    $input: ScheduleRecordTaskInspectionAttemptInput!
  ) {
    scheduleRecordTaskInspectionAttempt(id: $id, input: $input) {
      id
    }
  }
`
export const useScheduleRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    ScheduleRecordTaskInspectionAttemptMutation,
    ScheduleRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Schedule,
  })
}

const Log = gql`
  mutation LogRecordTaskInspectionAttemptMutation(
    $id: Int!
    $input: LogRecordTaskInspectionAttemptInput!
  ) {
    logRecordTaskInspectionAttempt(id: $id, input: $input) {
      id
    }
  }
`
export const useLogRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    LogRecordTaskInspectionAttemptMutation,
    LogRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Log,
  })
}

const CancelMultiple = gql`
  mutation CancelMultipleRecordTaskInspectionAttemptsMutation($ids: [Int!]!) {
    cancelMultipleRecordTaskInspectionAttempts(ids: $ids)
  }
`
export const useCancelMultipleRecordTaskInspectionAttemptsMutation = () => {
  return useMutation<
    CancelMultipleRecordTaskInspectionAttemptsMutation,
    CancelMultipleRecordTaskInspectionAttemptsMutationVariables
  >({ mutationDocument: CancelMultiple })
}

const DeleteMultiple = gql`
  mutation DeleteMultipleRecordTaskInspectionsMutation($ids: [Int!]!) {
    deleteMultipleRecordTaskInspections(ids: $ids)
  }
`
export const useDeleteMultipleRecordTaskInspectionsMutation = () => {
  return useMutation<
    DeleteMultipleRecordTaskInspectionsMutation,
    DeleteMultipleRecordTaskInspectionsMutationVariables
  >({
    mutationDocument: DeleteMultiple,
  })
}

const ReassignMultiple = gql`
  mutation ReassignMultipleRecordTaskInspectionAttemptsMutation(
    $ids: [Int!]!
    $input: ReassignRecordTaskInspectionAttemptInput!
  ) {
    reassignMultipleRecordTaskInspectionAttempts(ids: $ids, input: $input)
  }
`

export const useReassignMultipleRecordTaskInspectionAttemptsMutation = () => {
  return useMutation<
    ReassignMultipleRecordTaskInspectionAttemptsMutation,
    ReassignMultipleRecordTaskInspectionAttemptsMutationVariables
  >({ mutationDocument: ReassignMultiple })
}

const ConvertToDraft = gql`
  mutation ConvertRecordTaskInspectionAttemptToDraftMutation(
    $id: Int!
    $input: LogRecordTaskInspectionAttemptInput!
  ) {
    convertRecordTaskInspectionAttemptToDraft(id: $id, input: $input) {
      id
    }
  }
`

export const useConvertRecordTaskInspectionAttemptToDraftMutation = () => {
  return useMutation<
    ConvertRecordTaskInspectionAttemptToDraftMutation,
    ConvertRecordTaskInspectionAttemptToDraftMutationVariables
  >({ mutationDocument: ConvertToDraft })
}

const UpdateDraft = gql`
  mutation UpdateRecordTaskInspectionAttemptDraftMutation(
    $id: Int!
    $input: LogRecordTaskInspectionAttemptInput!
  ) {
    updateRecordTaskInspectionAttemptDraft(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateRecordTaskInspectionAttemptDraftMutation = () => {
  return useMutation<
    UpdateRecordTaskInspectionAttemptDraftMutation,
    UpdateRecordTaskInspectionAttemptDraftMutationVariables
  >({ mutationDocument: UpdateDraft })
}

const SubmitDraft = gql`
  mutation SubmitRecordTaskInspectionAttemptDraftMutation(
    $id: Int!
    $input: LogRecordTaskInspectionAttemptInput!
  ) {
    submitRecordTaskInspectionAttemptDraft(id: $id, input: $input) {
      id
    }
  }
`

export const useSubmitRecordTaskInspectionAttemptDraftMutation = () => {
  return useMutation<
    SubmitRecordTaskInspectionAttemptDraftMutation,
    SubmitRecordTaskInspectionAttemptDraftMutationVariables
  >({ mutationDocument: SubmitDraft })
}

const Update = gql`
  mutation UpdateRecordTaskInspectionAttemptMutation(
    $id: Int!
    $input: UpdateRecordTaskInspectionAttemptInput!
  ) {
    updateRecordTaskInspectionAttempt(id: $id, input: $input) {
      id
    }
  }
`
export const useUpdateRecordTaskInspectionAttemptMutation = () => {
  return useMutation<
    UpdateRecordTaskInspectionAttemptMutation,
    UpdateRecordTaskInspectionAttemptMutationVariables
  >({
    mutationDocument: Update,
  })
}

const GetSchedulingIntakeForm = gql`
  query GetSchedulingIntakeFormForRecordTaskInspectionAttemptQuery(
    $recordTaskInspectionAttemptId: Int!
  ) {
    recordTaskInspectionAttemptSchedulingIntakeForm(
      recordTaskInspectionAttemptId: $recordTaskInspectionAttemptId
    ) {
      ...FieldGroupFragment
    }
  }
`
export const useRecordTaskInspectionAttemptSchedulingIntakeFormQuery = (
  recordTaskInspectionAttemptId: number,
  options?: UseQueryOptions<
    GetSchedulingIntakeFormForRecordTaskInspectionAttemptQuery,
    GetSchedulingIntakeFormForRecordTaskInspectionAttemptQueryVariables
  >
) => {
  const result = useQuery<
    GetSchedulingIntakeFormForRecordTaskInspectionAttemptQuery,
    GetSchedulingIntakeFormForRecordTaskInspectionAttemptQueryVariables
  >({
    queryKey: [QueryKey.RecordTaskInspectionAttemptIntakeForm, recordTaskInspectionAttemptId],
    queryDocument: GetSchedulingIntakeForm,
    variables: {
      recordTaskInspectionAttemptId,
    },
    ...options,
  })

  const intakeFormFieldGroup = useMemo(
    () => result.data?.recordTaskInspectionAttemptSchedulingIntakeForm ?? null,
    [result.data?.recordTaskInspectionAttemptSchedulingIntakeForm]
  )
  return {
    ...omit(result, 'data'),
    intakeFormFieldGroup,
  }
}

const GetSchedulingIntakeFormTemplate = gql`
  query GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQuery(
    $recordTaskInspectionAttemptId: Int!
  ) {
    recordTaskInspectionAttemptSchedulingIntakeFormTemplate(
      recordTaskInspectionAttemptId: $recordTaskInspectionAttemptId
    ) {
      ...FieldGroupFragment
    }
  }
`
export const useRecordTaskInspectionAttemptSchedulingIntakeFormTemplateQuery = (
  recordTaskInspectionAttemptId: number,
  options?: UseQueryOptions<
    GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQuery,
    GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQueryVariables
  >
) => {
  const result = useQuery<
    GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQuery,
    GetSchedulingIntakeFormTemplateForRecordTaskInspectionAttemptQueryVariables
  >({
    queryKey: [QueryKey.RecordTaskInspectionAttemptIntakeForm, recordTaskInspectionAttemptId],
    queryDocument: GetSchedulingIntakeFormTemplate,
    variables: {
      recordTaskInspectionAttemptId,
    },
    ...options,
  })

  const intakeFormFieldGroupTemplate = useMemo(
    () => result.data?.recordTaskInspectionAttemptSchedulingIntakeFormTemplate ?? null,
    [result.data?.recordTaskInspectionAttemptSchedulingIntakeFormTemplate]
  )
  return {
    ...omit(result, 'data'),
    intakeFormFieldGroupTemplate,
  }
}
