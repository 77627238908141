import {
  RecordTaskGroupType,
  RecordTaskTemplateDocumentConfigFragment,
  RecordTaskTemplateGroupFeeConfigFragment,
  RecordTaskTemplateGroupFragment,
  RecordTaskTemplateInspectionGroupFragment,
  TaskTemplateFragment,
} from 'types/graphql'

import { mapBackendDateTemplateToFEFields } from 'src/components/Field/be-to-fe'
import DateTemplateInputs from 'src/components/shared/DateTemplateInputs/DateTemplateInputs'

import {
  RecordTaskGroupTypeToRecordTaskGroupNameMap,
  filterNil,
  maybeMakeString,
} from '../../../utils'
import { OptionGroupType, OptionType } from '../../form/SelectGroup'

export const RecordUserSelectApplicantValue = 'RECORD-USER-SELECT-APPLICANT-VALUE'
export const RecordUserSelectOwnerValue = 'RECORD-USER-SELECT-OWNER-VALUE'

export enum WorkflowStepCompletionTypes {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export const makeWorkflowStepFieldName = (name: string, rttg: RecordTaskTemplateGroupFragment) =>
  `${name}-${rttg.id}`

export const getWorkflowStepInitialValues = (rttgs: RecordTaskTemplateGroupFragment[]) => {
  return rttgs.reduce((prev, rttg) => {
    const getOwnerUserIdVal = () => {
      if (rttg.ownerUser?.id) {
        return rttg.ownerUser?.id
      }
      if (rttg.ownedByPermitOwner) {
        return RecordUserSelectOwnerValue
      }
      if (rttg.ownedByApplicant) {
        return RecordUserSelectApplicantValue
      }
      return undefined
    }
    return {
      ...prev,
      // general
      [makeWorkflowStepFieldName('type', rttg)]: rttg.type,
      [makeWorkflowStepFieldName('identifier', rttg)]: rttg.identifier,
      [makeWorkflowStepFieldName('ownerUserId', rttg)]: getOwnerUserIdVal(),
      [makeWorkflowStepFieldName('businessDays', rttg)]: maybeMakeString(rttg.daysToComplete),
      [makeWorkflowStepFieldName('isOmittedOnWorkflowCreation', rttg)]:
        rttg.isOmittedOnWorkflowCreation,
      [makeWorkflowStepFieldName('changeToStatusId', rttg)]: rttg.changeToStatusId,
      // custom
      [makeWorkflowStepFieldName('addFilesToPlanReview', rttg)]:
        rttg.recordTaskTemplates?.[0]?.addFilesToPlanReview,
      // plan review
      [makeWorkflowStepFieldName('planReviewDaysToComplete', rttg)]: maybeMakeString(
        rttg.planReviewDaysToComplete
      ),
      [makeWorkflowStepFieldName('defaultPlanReviewerIds', rttg)]: rttg.defaultPlanReviewerIds,
      [makeWorkflowStepFieldName('planReviewRoutingDaysToComplete', rttg)]: maybeMakeString(
        rttg.planReviewRoutingDaysToComplete
      ),
      [makeWorkflowStepFieldName('planReviewRouteAutomatically', rttg)]:
        rttg.planReviewRouteAutomatically
          ? WorkflowStepCompletionTypes.Automatic
          : WorkflowStepCompletionTypes.Manual,
      [makeWorkflowStepFieldName('checklistIds', rttg)]: (rttg.checklistTemplates || []).map(
        (ci) => ci.id
      ),
      // payment
      ...makeFeeConfigInitialValues(filterNil(rttg.feeConfigs ?? []), rttg),
      [makeWorkflowStepFieldName('feesDaysToComplete', rttg)]: maybeMakeString(
        rttg.feesDaysToComplete
      ),
      // document generation
      [makeWorkflowStepFieldName('document-template-id', rttg)]: rttg.documentTemplateId,
      // send notification
      [makeWorkflowStepFieldName('default-email-template-id', rttg)]: rttg.defaultEmailTemplate?.id,
      // inspections
      ...makeInspectionGroupsInitialValues(rttg),
      [makeWorkflowStepFieldName('additionalNotificationUserIds', rttg)]:
        rttg.additionalNotificationUserIds,
      // custom - emails
      ...makeEmailAutomationInitialValues(rttg),
      // issue
      ...makeIssueInitialValues(rttg),
      // expiration
      ...makeExpirationInitialValues(rttg),
      // checklist
      ...makeChecklistInitialValues(rttg),
      // renewals
      ...makeRenewalInitialValues(rttg),
      // schedule workflow
      ...makeScheduleWorkflowInitialValues(rttg),
      // application review - single or multiple
      ...(rttg.type === 'ApplicationReview'
        ? {
            [makeWorkflowStepFieldName('applicationReviewAllowMultipleUsers', rttg)]: false,
          }
        : {}),
      ...(rttg.type === 'ApplicationReviewMultiple'
        ? {
            [makeWorkflowStepFieldName('applicationReviewAllowMultipleUsers', rttg)]: true,
          }
        : {}),
    }
  }, {})
}

const makeEmailAutomationInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const emailAutomations = rttg.recordTaskTemplates?.[0]?.emailAutomations || []
  return emailAutomations.reduce((prev, curr) => {
    const recipientUserIds = [
      ...(curr?.recipientUserIds || []),
      ...(curr?.recipientApplicant ? [RecordUserSelectApplicantValue] : []),
    ]
    return {
      ...prev,
      [makeWorkflowStepFieldName(`email-template-${curr?.id}`, rttg)]: curr?.emailTemplateId,
      [makeWorkflowStepFieldName(`email-owners-${curr?.id}`, rttg)]: recipientUserIds,
      ...mapBackendDateTemplateToFEFields({
        dateTemplate: curr?.dateTemplate,
        fieldNamePrefix: 'email-date-template',
        identifier: curr?.id,
        transform: (fieldName) => makeWorkflowStepFieldName(fieldName, rttg),
      }),
    }
  }, {})
}

const makeInspectionGroupsInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const inspectionGroups = rttg.inspectionGroups
  return inspectionGroups?.reduce((prev, inspectionGroup) => {
    return {
      ...prev,
      [makeWorkflowStepFieldName(`inspection-group-${inspectionGroup.id}-name`, rttg)]:
        inspectionGroup.name,
      [makeWorkflowStepFieldName(
        `inspection-group-${inspectionGroup.id}-alwaysAvailableToApplicants`,
        rttg
      )]: inspectionGroup.alwaysAvailableToApplicants,
      ...makeInspectionGroupInitialValues(inspectionGroup, rttg),
    }
  }, {})
}

const makeInspectionGroupInitialValues = (
  inspectionGroup: RecordTaskTemplateInspectionGroupFragment,
  rttg: RecordTaskTemplateGroupFragment
) => {
  const inspections = inspectionGroup.inspections
  return inspections?.reduce((prev, inspection) => {
    return {
      ...prev,
      [makeWorkflowStepFieldName(`inspection-type-${inspection?.id}`, rttg)]:
        inspection?.inspectionTemplate?.id,
    }
  }, {})
}

const makeIssueDocumentConfigInitialValues = (
  documentConfig: RecordTaskTemplateDocumentConfigFragment | null | undefined,
  rttg: RecordTaskTemplateGroupFragment
) => {
  return {
    [makeWorkflowStepFieldName(`issue-document-${documentConfig?.id}`, rttg)]:
      documentConfig?.documentTemplateId,
    ...mapBackendDateTemplateToFEFields({
      dateTemplate: documentConfig?.dateTemplate,
      fieldNamePrefix: 'issue-document-date-template',
      identifier: documentConfig?.id,
      transform: (fieldName) => makeWorkflowStepFieldName(fieldName, rttg),
    }),
  }
}

const makeFeeConfigInitialValues = (
  feeConfigs: RecordTaskTemplateGroupFeeConfigFragment[],
  rttg: RecordTaskTemplateGroupFragment
) => {
  return feeConfigs.reduce(
    (prev, curr) => ({
      ...prev,
      [makeWorkflowStepFieldName(`fee-config-visible-${curr.id}`, rttg)]: curr.visible,
      [makeWorkflowStepFieldName(`fee-config-feeId-${curr.id}`, rttg)]: curr.fee?.id ?? undefined,
    }),
    {}
  )
}

const makeIssueInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const docConfigValues = (rttg.issueConfig?.documentConfigs || []).reduce((prev, curr) => {
    return {
      ...prev,
      ...makeIssueDocumentConfigInitialValues(curr, rttg),
    }
  }, {})
  return {
    ...docConfigValues,
    [makeWorkflowStepFieldName(`issue-type`, rttg)]: rttg.issueConfig?.type,
  }
}

const makeChecklistInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  return rttg.checklistItems?.reduce(
    (prev, curr) => ({
      ...prev,
      [makeWorkflowStepFieldName(`checklist-item-${curr.id}`, rttg)]: curr.name,
    }),
    {}
  )
}

const makeRenewalInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const defaultRenewalWorkflowTemplateId = rttg.defaultRenewalWorkflowTemplateId
  return {
    [makeWorkflowStepFieldName('defaultRenewalWorkflowTemplateId', rttg)]:
      defaultRenewalWorkflowTemplateId,
  }
}

const makeScheduleWorkflowInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const beginWorkflowTemplateId =
    rttg.scheduleWorkflowScheduledEventTemplate?.beginWorkflowTemplate?.id
  return {
    [makeWorkflowStepFieldName('beginWorkflowTemplateId', rttg)]: beginWorkflowTemplateId,
  }
}

const makeExpirationInitialValues = (rttg: RecordTaskTemplateGroupFragment) => {
  const scheduledEvents = filterNil([
    ...(rttg.notificationScheduledEventTemplates || []),
    rttg.expirationScheduledEventTemplate,
  ])
  return scheduledEvents.reduce((prev, curr) => {
    const recipientUserIdsRaw = (curr?.recipients || []).flatMap((r) => r.userId || [])
    const recipientUserIds = [
      ...recipientUserIdsRaw,
      ...(curr?.emailApplicant ? [RecordUserSelectApplicantValue] : []),
      ...(curr?.emailOwner ? [RecordUserSelectOwnerValue] : []),
    ]
    const nonGovWellEmails = (curr?.recipients || []).flatMap((r) => r.emailAddress || [])

    return {
      ...prev,
      ...mapBackendDateTemplateToFEFields({
        dateTemplate: curr?.dateTemplate,
        fieldNamePrefix: 'date-template',
        identifier: curr?.id,
        transform: (fieldName) => makeWorkflowStepFieldName(fieldName, rttg),
      }),
      [makeWorkflowStepFieldName(`change-status-${curr?.id}`, rttg)]: curr?.changeToStatusId,
      [makeWorkflowStepFieldName(`event-email-template-${curr?.id}`, rttg)]: curr?.emailTemplateId,
      [makeWorkflowStepFieldName(`event-email-recipients-${curr?.id}`, rttg)]: recipientUserIds,
      [makeWorkflowStepFieldName(`event-email-recipients-non-govwell-${curr?.id}`, rttg)]:
        nonGovWellEmails,
    }
  }, {})
}

const sortOptions = (a: OptionType, b: OptionType) => `${a.label}`.localeCompare(`${b.label}`)

export const makeOptions = (
  taskTemplates: TaskTemplateFragment[],
  disableIssue: boolean,
  allowInspections: boolean,
  allowFees: boolean
): OptionGroupType<RecordTaskGroupType | number>[] => {
  const taskOptions = taskTemplates.map((tt) => ({
    label: tt.identifier,
    value: tt.id,
  }))

  const feesOptions: OptionGroupType<RecordTaskGroupType>[] = [
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['Payment'],
      subLabel:
        'Staff are prompted to configure fees and send invoices. This step is complete when payment is received.',
      value: 'Payment',
    },
  ]

  const inspectionsOptions: OptionGroupType<RecordTaskGroupType>[] = [
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['Inspections'],
      subLabel:
        'Schedule, log, and manage inspections. This step is complete when all inspections are passing.',
      value: 'Inspections',
    },
  ]

  const govWellManagedStepOptions: OptionGroupType<RecordTaskGroupType>[] = [
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['ApplicationReview'],
      subLabel: 'Staff are prompted to review the form submittal and may request changes.',
      value: 'ApplicationReview',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['ChangeStatus'],
      subLabel: 'Staff are prompted to select a new status for the record.',
      value: 'ChangeStatus',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['PlanReview'],
      subLabel:
        'Staff are prompted to review and mark up plan sets uploaded by the applicant, share feedback, and generate corrections reports.',
      value: 'PlanReview',
    },
    ...(allowFees ? feesOptions : []),
    ...(allowInspections ? inspectionsOptions : []),
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['DocumentGeneration'],
      subLabel: 'Staff are prompted to generate documents that will be stored on the record.',
      value: 'DocumentGeneration',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['Issue'],
      subLabel: `Staff are prompted to issue the record by generating a document.`,
      value: 'Issue',
      disabled: disableIssue,
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['Expiration'],
      subLabel:
        'Staff are prompted to set an expiration date on the record, optionally sending email notifications.',
      value: 'Expiration',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['SendNotification'],
      subLabel: 'Staff are prompted to send an email notification.',
      value: 'SendNotification',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['ScheduleWorkflow'],
      subLabel:
        'Staff are prompted to schedule a workflow to occur in the future. All staff are allowed to skip this step.',
      value: 'ScheduleWorkflow',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['Renewal'],
      subLabel:
        'The workflow step owner is prompted to create a renewal of this record. The renewal record will be linked as a child of the original record.',
      value: 'Renewal',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['RequestRecords'],
      subLabel:
        'Staff are prompted to send out requests for the creation of additional records, which will be linked as children of the original record.',
      value: 'RequestRecords',
    },
    {
      label: RecordTaskGroupTypeToRecordTaskGroupNameMap['CreateChildRecord'],
      subLabel:
        'Staff are prompted to create a record that will be linked as the child of the original record.',
      value: 'CreateChildRecord',
    },
  ]

  return [
    {
      label: 'GovWell Managed Steps',
      options: govWellManagedStepOptions.sort(sortOptions),
    },
    ...(taskOptions.length
      ? [
          {
            label: 'Custom Steps',
            options: taskOptions.sort(sortOptions),
          },
        ]
      : []),
  ]
}

export const generateDateTemplateInputFieldNameProps = (args: {
  fieldNamePrefix: string
  identifier: number | string | null | undefined
  transform?: (fieldName: string) => string
}): Pick<
  React.ComponentProps<typeof DateTemplateInputs>,
  | 'countFieldName'
  | 'dayFieldName'
  | 'intervalTypeFieldName'
  | 'intervalRelationFieldName'
  | 'monthFieldName'
  | 'typeFieldName'
  | 'minimumDelayDaysFieldName'
> => {
  const { fieldNamePrefix, identifier, transform } = args

  const getKey = (fieldName: string) => (transform ? transform(fieldName) : fieldName)
  const typeFieldName = `${fieldNamePrefix}-type-${identifier}`
  const intervalTypeFieldName = `${fieldNamePrefix}-interval-type-${identifier}`
  const intervalRelationFieldName = `${fieldNamePrefix}-interval-relation-${identifier}`
  const countFieldName = `${fieldNamePrefix}-count-${identifier}`
  const monthFieldName = `${fieldNamePrefix}-month-${identifier}`
  const dayFieldName = `${fieldNamePrefix}-day-${identifier}`
  const minimumDelayDaysFieldName = `${fieldNamePrefix}-min-delay-days-${identifier}`

  return {
    typeFieldName: getKey(typeFieldName),
    intervalTypeFieldName: getKey(intervalTypeFieldName),
    intervalRelationFieldName: getKey(intervalRelationFieldName),
    countFieldName: getKey(countFieldName),
    monthFieldName: getKey(monthFieldName),
    dayFieldName: getKey(dayFieldName),
    minimumDelayDaysFieldName: getKey(minimumDelayDaysFieldName),
  }
}
