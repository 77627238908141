import { useEffect } from 'react'

import { useAuth } from '@clerk/clerk-react'

import { BroadcastChannelMessageType } from 'src/types'

export const useBroadcastChannelListener = () => {
  const { isSignedIn } = useAuth()
  useEffect(() => {
    const channel = getBroadcastChannel()
    if (!channel) {
      return
    }

    const listener = (e: MessageEvent) => {
      if (e.source === self) {
        return
      }
      if (isSignedIn && e.data?.type === BroadcastChannelMessageType.Logout) {
        window.location.reload()
      }
      if (isSignedIn && e.data?.type === BroadcastChannelMessageType.AuthenticationCheck) {
        channel.postMessage({ type: BroadcastChannelMessageType.AuthenticationACK })
      }
      if (
        !isSignedIn &&
        !document.hidden &&
        e.data?.type === BroadcastChannelMessageType.AuthenticationACK
      ) {
        // Reload the unauthenticated active tab if the user has signed in from a different tab
        window.location.reload()
      }
    }

    channel.addEventListener('message', listener)
    return () => channel.removeEventListener('message', listener)
  }, [isSignedIn])
}

export const getBroadcastChannel = () => {
  if (!window.BroadcastChannel) {
    return null
  }
  return new window.BroadcastChannel('govwell')
}
