import {
  AddressEntryType,
  RecordTaskStatus,
  RecordTaskType,
  RecordTypeStatusType,
} from 'types/graphql'

import { EmailTemplateType, RecordTemplateApplicantType } from '../../../api/types/graphql'
import { RecordTypeCategory, ViolationInspectionStatus, ViolationStatus } from '../../types/graphql'

export const PhoneNumberRegex = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
export const ZipCodeRegex = new RegExp(/^\d{5}(-\d{4})?$/)

export const OrganizationId = 1

export enum Environments {
  Development = 'Dev',
  Demo = 'Demo',
  Local = 'Local',
  Production = 'Prod',
}

// When adding a variable here, be sure to allowlist it in redwood.toml at the root of this repo!
export const EnvVariables = {
  ClerkPublishableKey: process.env.CLERK_PUBLISHABLE_KEY || '',
  CoBrowseLicenseKey: process.env.COBROWSE_LICENSE_ID,
  DatadogClientToken: process.env.DD_RUM_CLIENT_TOKEN,
  DatadogEnv: process.env.DD_ENV,
  DatadogRumEnabled: process.env.DD_ENABLE_RUM,
  DatadogService: process.env.DD_SERVICE,
  Environment: process.env.Environment as Environments,
  EvervaultApiKey: process.env.EVERVAULT_API_KEY,
  EvervaultAppId: process.env.EVERVAULT_APP_ID,
  EvervaultRelayHostname: process.env.EVERVAULT_RELAY_HOSTNAME,
  EvervaultTeamId: process.env.EVERVAULT_TEAM_ID,
  FileUploadApiKey: process.env.FILE_UPLOAD_API_KEY,
  FinixAppId: process.env.FINIX_APP_ID,
  IntercomApiBase: process.env.INTERCOM_API_BASE,
  IntercomAppId: process.env.INTERCOM_APP_ID,
  KingslandGWUrl: process.env.KINGSLAND_GW_URL,
  LocalNgrokUrl: process.env.LOCAL_NGROK_URL,
  SupabaseApiKey: process.env.SUPABASE_API_KEY,
  SupabaseProjectUrl: process.env.SUPABASE_PROJECT_URL,
  VercelDeploymentSha: process.env.VERCEL_GIT_COMMIT_SHA,
}

export const GovWellTechEmailDomain = 'govwelltech.com'
export const GovWellEmailDomain = 'govwell.com'

export const makeOrgHomePageUrl = (slug: string) => `${AppBaseUrl}/${slug}`

export const isGovWellEmail = (emailAddress?: string | null | undefined) =>
  emailAddress?.endsWith(`@${GovWellEmailDomain}`) ||
  emailAddress?.endsWith(`@${GovWellTechEmailDomain}`) ||
  false

export const EvervaultRelayUrl =
  EnvVariables.Environment === Environments.Local
    ? `https://${EnvVariables.EvervaultRelayHostname}/foreign-storage`
    : `https://${EnvVariables.EvervaultRelayHostname}/api/foreign-storage`

export const MarketingSiteUrl = 'https://govwell.com'
export const AppBaseUrl =
  EnvVariables.Environment === Environments.Production
    ? 'https://app.govwell.com'
    : EnvVariables.Environment === Environments.Demo
      ? 'https://demo.govwell.com'
      : EnvVariables.Environment === Environments.Development
        ? 'https://dev.govwell.com'
        : 'http://localhost:8910'

export const GovWellFileUploadUrl = `${AppBaseUrl}/api/file-upload/v1`

export const WebhookBaseUrl =
  EnvVariables.Environment === Environments.Local
    ? `${EnvVariables.LocalNgrokUrl}`
    : `${AppBaseUrl}/api`

export const ElementIds = {
  SubmitComplaint: 'submit-complaint',
  ViolationEllipsis: 'violation-ellipsis-',
  ViewViolation: 'view-violation',
  DeleteViolation: 'delete-violation',
  ResolveViolation: 'resolve-violation',
  ScheduleInspection: 'schedule-inspection',
  LogInspection: 'log-inspection',
  AddComment: 'add-comment',
  EditType: 'edit-type',
  UploadFiles: 'upload-files',
  GenerateMailer: 'generate-mailer',
  NavCodeEnforcement: 'nav-code-enforcement',
  NavComplaints: 'nav-complaints',
  NavInspections: 'nav-inspections',
  ThankYouPageText: 'thank-you-page-text',
}

export const InspectionStatusToDisplayMap: Record<ViolationInspectionStatus, string> = {
  Scheduled: 'To Do',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  CompletedPassed: 'Completed - Passed',
  CompletedInViolation: 'Completed - In Violation',
}

export const ViolationStatusToDisplayMap: Record<ViolationStatus, string> = {
  Submitted: 'To Review',
  InProgress: 'Investigating',
  Closed: 'Resolved',
  Archived: 'Archived',
  InViolation: 'In Violation',
  NotInViolation: 'Not In Violation',
  Ticketed: 'Ticketed',
  Abatement: 'Abatement',
  Prosecution: 'Prosecution',
  CivilHearingNotice: 'Civil Hearing Notice',
}

export const ExternalLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

export const FormFieldNamePrefix = `field-name-`

export const AcceptedPdf = '.pdf'
export const AcceptedFileTypes = '.pdf, .jpeg, .jpg, .png'
export const AcceptedFileTypesWithCad =
  '.pdf, .jpeg, .jpg, .png, .dwg, .dxf, .dgn, .stl, .iges, .igs, .step, .stp'
export const AcceptedImages = '.jpeg, .jpg, .png'
export const AcceptedMp4 = '.mov, .mp4'
export const AcceptedDocx = '.docx'
export const AcceptedShp = '.shp'

export const AcceptedFileTypesCodeEnforcementGov = `${AcceptedFileTypes}, ${AcceptedMp4}, ${AcceptedDocx}`

export const RecordTypeDisplayMap: Record<RecordTypeCategory, string> = {
  Licenses: 'Licenses',
  Permits: 'Permits',
  Violations: 'Code Enforcement',
}

export const GovWellTermsOfService = 'https://www.govwell.com/terms'

export const RecordTemplateApplicantTypeDisplayMap: Record<RecordTemplateApplicantType, string> = {
  User: 'Individuals',
  Company: 'Companies',
}

export const EmailTemplateTypeDisplayMap: Record<EmailTemplateType, string> = {
  Record: 'Link to the specific record',
  RecordSignup: 'Signup link that redirects to the specific record',
  RecordTypeApply: 'Link to submit a new record',
}

export const EmailTemplateTypeButtonTextMap: Record<EmailTemplateType, string> = {
  Record: 'View in GovWell',
  RecordSignup: 'View in GovWell',
  RecordTypeApply: 'Submit a new application in GovWell',
}

// OrgSlugs are duplicated on the BE, update there too!
export enum OrgSlugs {
  Amherst = 'amherst',
  Austin = 'austin',
  BlackMountain = 'black-mountain',
  CampVerde = 'camp-verde',
  CapeElizabeth = 'cape-elizabeth',
  Champlain = 'champlain',
  Clarksville = 'clarksville',
  ClearcreekTownship = 'clearcreek-township',
  ColdSpring = 'cold-spring',
  Collinsville = 'collinsville',
  Decatur = 'decatur',
  Eustis = 'eustis',
  Fulshear = 'fulshear',
  Gadsden = 'gadsden',
  Gonzales = 'gonzales',
  GovWell = 'govwell',
  Hampton = 'hampton',
  HarrisonCounty = 'harrison-county',
  Hewitt = 'hewitt',
  Hutto = 'hutto',
  King = 'king',
  Kingsland = 'kingsland',
  LaPorte = 'laporte',
  Middlebury = 'middlebury',
  MtCrestedButte = 'mt-crested-butte',
  Nederland = 'nederland',
  NorthRoyalton = 'north-royalton',
  Oxford = 'oxford',
  Paradise = 'paradise',
  Perinton = 'perinton',
  Piqua = 'piqua',
  Saginaw = 'saginaw',
  Shelby = 'shelby',
  Vermillion = 'vermillion',
  Willis = 'willis',
  Winfield = 'winfield',
}
export const OrgHomePageHeader = 'Public Portal'

export const KB = 1024
export const MB = 1024 * KB
export const GB = 1024 * MB
export const MaxFileSize = 1 * GB

export const DatadogSettings = {
  datadogSite: 'us5.datadoghq.com',
  datadogApplicationId: 'c8cabb41-753f-478c-9357-30df956267c1',
}

export const ProcessingFeeExplanation =
  'A small processing fee is added to credit/debit card transactions to cover interchange fees charged by credit card companies like Visa.'

export const RecordTypeStatusTypeDisplayMap: Record<RecordTypeStatusType, string> = {
  ToDo: 'To Do',
  InProgress: 'In Progress',
  Complete: 'Complete',
  CompleteNegative: 'Complete - Archived/Expired',
}

export const RecordTaskStatusTypeDisplayMap: Record<RecordTaskStatus, string> = {
  ToDo: 'To Do',
  Future: 'Future',
  Done: 'Done',
}

export const PLAN_REVIEW_STAMP_LINE_HEIGHT_PX = 12
export const PLAN_REVIEW_IMAGE_DEFAULT_WIDTH_PX = 30

// duplicated in file://./../../../api/src/constants/index.ts
export const GovWellTokenHeader = 'x-govwell-token'

export const RecordTaskTypeDisplayMap: Record<RecordTaskType, string> = {
  AcknowledgePlanReview: 'Acknowledge Plan Review Comments',
  ApplicationReview: 'Review Application',
  ChangeStatus: 'Change Status',
  Checklist: 'Complete Checklist Items',
  CreateChildRecord: 'Create Child Record',
  CreateRequestedRecord: 'Create Requested Record',
  Custom: 'Custom',
  GenerateDocument: 'Generate Document',
  Issue: 'Issue',
  Migrate: 'Migrate',
  PayFees: 'Pay Fees',
  PlanReview: 'Plan Review',
  PlanReviewRouting: 'Route for Plan Review',
  Renewal: 'Renewal',
  RequestFees: 'Request Fees',
  RequestRecords: 'Request Records',
  Route: 'Route for Review',
  ScheduleNotifications: 'Schedule Notifications',
  ScheduleWorkflow: 'Schedule Future Workflows',
  SelectStep: 'Select Step',
  SendNotification: 'Send Email',
  SetExpiration: 'Set Expiration Date',
  UpdateApplication: 'Update Application',
  UpdatePlansSet: 'Update Plan Set',
}

export const AddressEntryTypeDisplayMap: Record<AddressEntryType, string> = {
  SearchAndManual: 'GIS Search and Manual Entry',
  ManualOnly: 'Manual Entry Only',
  SearchOnly: 'GIS Search Only',
}
