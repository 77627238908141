import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ColumnDef,
  ColumnSort,
  ExpandedState,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import { Flex, Pagination, Tooltip } from 'antd'
import { Checkbox, Header } from 'govwell-ui'
import TableHeaderCell, { StyledTH } from 'govwell-ui/components/Table/TableHeaderCell'
import TableRow from 'govwell-ui/components/Table/TableRow'
import {
  TableColumnId,
  TableDropTargetDragState,
  TableRowDragState,
} from 'govwell-ui/components/Table/types'
import { useTable } from 'govwell-ui/components/Table/use-table'
import { isTableDropTargetDragState, isTableRowDragState } from 'govwell-ui/components/Table/util'
import isNil from 'lodash.isnil'
import styled from 'styled-components'
import { v4 } from 'uuid'

import DragIndicator from 'src/components/DragIndicator'
import EmptyState from 'src/components/EmptyState'
import { IconOnlyButton } from 'src/components/shared/StyledComponents'
import { ColorToken } from 'src/constants/theme/types'
import useDisclosure from 'src/hooks/use-disclosure'

import { getReorderingIndices } from '../../../utils/drag-and-drop'
import { Size } from '../../types'

import { TableContext } from './context'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const ScrollContainer = styled(Flex)<{ $hasBorderRadius: boolean }>`
  max-width: 100%;
  overflow-x: auto;
  border: solid 1px ${({ theme }) => theme.colorSplit};
  border-radius: ${({ $hasBorderRadius }) => ($hasBorderRadius ? '6px' : '0px')};
`
const StyledTable = styled.table<{
  $isLoading: boolean
  $layout: React.CSSProperties['tableLayout']
  $minWidth?: React.CSSProperties['minWidth']
}>`
  table-layout: ${({ $layout }) => $layout};
  ${({ $minWidth }) => !isNil($minWidth) && `min-width: ${$minWidth};`};
  width: 100%;
  padding: 0;
  border-spacing: 0px;
  ${({ $isLoading }) =>
    $isLoading
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''}
`
const StyledTHead = styled.thead<{ $colorToken?: ColorToken }>`
  background-color: ${({ $colorToken = 'colorFillAlter', theme }) => theme[$colorToken] as string};
  padding: 0;
`
const StyledEmptyStateWrapper = styled.div<{ $isDropTarget: boolean }>`
  > div {
    padding: 12px 0px;
    transition: background-color 0.25s;
    ${({ $isDropTarget, theme }) =>
      $isDropTarget &&
      `
      background-color: ${theme['blue-1']};
    `}
  }
`

export type OnRowReorderedInput<TData> = {
  // The logical movement: move the item at sourceIndex to destinationIndex
  // This data should be sufficient for 99% of cases
  sourceIndex: number
  destinationIndex: number
  rawData: {
    // The physical movement:
    // sourceRow and destinationRow are the rows that the user happened to interact with visually
    // closestEdge is the general position of the cursor within destinationRow at the time that the sourceRow was released
    sourceRow: Row<TData>
    destinationRow: Row<TData>
    closestEdge: Edge
  }
}

type Props<TData = unknown> = {
  actions?: React.ReactNode
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  enablePagination?: boolean
  enableRowExpansion?: boolean
  enableRowSelection?: boolean
  enableRowReordering?: boolean
  enableSearching?: boolean
  emptyStateAction?: React.ReactNode
  emptyStateImage?: React.ReactNode
  emptyStateMessage?: React.ReactNode
  expanded?: ExpandedState
  expandButtonTooltipContent?: {
    closed: React.ReactNode
    open: React.ReactNode
  }
  getRowCanExpand?: (row: Row<TData>) => boolean
  getRowExpandedContent?: (value: TData) => React.ReactNode
  getRowId: (value: TData, index: number) => string
  getRowIsSelectable?: (row: Row<TData>) => boolean
  hasBorderRadius?: boolean
  isLoading?: boolean
  isSortedOnClient?: boolean
  layout?: React.CSSProperties['tableLayout']
  minWidth?: React.CSSProperties['minWidth']
  onExpandedChange?: (expanded: ExpandedState) => void
  onHoveredRowChange?: (row: Row<TData> | undefined) => void
  onPaginationChange?: (pagination: PaginationState) => void
  onRowDroppedIntoTable?: (sourceRow: Row<TData>) => void
  onRowReordered?: (input: OnRowReorderedInput<TData>) => void
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void
  onSearchQueryChange?: (value: string) => void
  onSortingChange?: OnChangeFn<ColumnSort[]> | undefined
  /*
   * This prop should only be used if you are dragging rows across multiple tables. Otherwise, don't use it!
   * Add a new custom getCanReorderRow prop instead if you don't want to override the same-table invariant
   * but still need to add additional restrictions on which rows can be reordered.
   *
   * You MUST define a real invariant to replace it, do not just return true. Reordering events are global,
   * so you need to identify that the row belongs to the specific group of tables you are dragging between
   * to avoid conflicting with other draggable elements on screen.
   */
  overrideReorderingWithinSameTableInvariant?: (rowDragState: TableRowDragState<TData>) => boolean
  pagination?: PaginationState
  reorderButtonTooltipContent?: React.ReactNode
  rowSelection?: RowSelectionState
  searchQuery?: string
  showCount?: boolean
  size?: Size
  sorting?: ColumnSort[]
  title?: React.ReactNode
  totalCount?: number
  verticalAlign?: React.CSSProperties['verticalAlign']
} & (
  | {
      isPaginatedOnClient?: false
      totalCount?: number
    }
  | {
      isPaginatedOnClient: true
      totalCount?: never
    }
)
const TableContent = <TData,>({
  actions,
  columns: propColumns,
  data,
  enablePagination = false,
  enableRowExpansion = false,
  enableRowReordering = false,
  enableRowSelection = false,
  enableSearching = false,
  emptyStateAction = null,
  emptyStateImage = <EmptyState.Image />,
  emptyStateMessage = 'No results found',
  expanded,
  expandButtonTooltipContent,
  getRowCanExpand,
  getRowExpandedContent,
  getRowId,
  getRowIsSelectable,
  hasBorderRadius = true,
  isLoading,
  isPaginatedOnClient = false,
  isSortedOnClient = false,
  layout = 'auto',
  minWidth,
  onExpandedChange,
  onHoveredRowChange,
  onPaginationChange,
  onRowDroppedIntoTable,
  onRowReordered,
  onRowSelectionChange,
  onSearchQueryChange,
  onSortingChange,
  overrideReorderingWithinSameTableInvariant,
  pagination,
  reorderButtonTooltipContent,
  rowSelection,
  searchQuery,
  showCount = false,
  size = 'md',
  sorting,
  title,
  totalCount: propTotalCount,
  verticalAlign,
}: Props<TData>) => {
  const [uuid] = useState(v4())

  const rowSelectionColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Select,
      meta: {
        width: layout === 'auto' ? '0px' : '16px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: layout === 'auto' ? '8px' : '4px', // TODO(GOV-303): allow customizing
      },
      header: ({ table }) => (
        <Checkbox
          value={
            table.getIsAllRowsSelected()
              ? true
              : table.getIsSomeRowsSelected()
                ? 'indeterminate'
                : false
          }
          onValueChange={() => table.toggleAllRowsSelected()}
        />
      ),
      cell: ({ row }) => {
        return (
          <Flex justify="center" style={{ width: '100%' }}>
            <Checkbox
              value={row.getIsSelected()}
              onValueChange={row.getToggleSelectedHandler()}
              isDisabled={!row.getCanSelect()}
            />
          </Flex>
        )
      },
    })
  }, [layout])

  const rowReorderColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Reorder,
      header: '',
      meta: {
        width: layout === 'auto' ? '0px' : '24px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: 0,
      },
      cell: () => (
        <DragIndicator tooltipContent={reorderButtonTooltipContent ?? 'Drag to reorder'} />
      ),
    })
  }, [layout, reorderButtonTooltipContent])

  const rowExpansionColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Expand,
      meta: {
        width: layout === 'auto' ? '12px' : '24px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: '2px',
      },
      cell: ({ row }) => {
        const isExpandable = row.getCanExpand()
        if (!isExpandable) {
          return null
        }

        const isExpanded = row.getIsExpanded()
        return (
          <Tooltip
            title={
              isExpanded
                ? (expandButtonTooltipContent?.open ?? 'Collapse row')
                : (expandButtonTooltipContent?.closed ?? 'Expand row')
            }
          >
            <Flex justify="center" style={{ width: '100%' }}>
              <IconOnlyButton
                icon={<FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />}
                size="small"
                onClick={row.getToggleExpandedHandler()}
              />
            </Flex>
          </Tooltip>
        )
      },
    })
  }, [expandButtonTooltipContent?.closed, expandButtonTooltipContent?.open, layout])

  const totalCount = isPaginatedOnClient ? data?.length : propTotalCount
  const isPaginationEnabled = enablePagination && !isNil(pagination) && !isNil(totalCount)
  const isRowReorderingEnabled = enableRowReordering && !!onRowReordered
  const isRowSelectionEnabled =
    enableRowSelection && !!rowSelection && !!onRowSelectionChange && !!data?.length
  const isRowExpansionEnabled =
    enableRowExpansion && !!expanded && !!getRowExpandedContent && !!onExpandedChange
  const isSearchingEnabled = enableSearching && !isNil(onSearchQueryChange)
  const columns = useMemo(
    () => [
      ...(isRowReorderingEnabled ? [rowReorderColumn] : []),
      ...(isRowSelectionEnabled ? [rowSelectionColumn] : []),
      ...(isRowExpansionEnabled ? [rowExpansionColumn] : []),
      ...propColumns,
    ],
    [
      propColumns,
      isRowExpansionEnabled,
      isRowReorderingEnabled,
      isRowSelectionEnabled,
      rowExpansionColumn,
      rowReorderColumn,
      rowSelectionColumn,
    ]
  )
  const { tableState } = useTable<TData>({
    columns,
    data,
    expanded,
    getRowCanExpand,
    getRowId,
    getRowIsSelectable,
    isPaginationEnabled,
    isRowExpansionEnabled,
    isRowSelectionEnabled,
    isPaginatedOnClient,
    isSortedOnClient,
    onExpandedChange,
    onPaginationChange,
    onRowSelectionChange,
    onSortingChange,
    pagination,
    rowSelection,
    sorting,
  })
  const rows = tableState.getRowModel().rows

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      onPaginationChange?.({
        pageIndex: page - 1,
        pageSize,
      })
    },
    [onPaginationChange]
  )
  const handleRowReordered = useCallback(
    // Adjust the indices to "debounce" cases where the user dragged an item
    // less than 50% of the way through another item.
    (sourceRow: Row<TData>, destinationRow: Row<TData>, closestEdge: Edge) => {
      const maxIndex = data?.length ? data?.length - 1 : 0
      const { sourceIndex, destinationIndex } = getReorderingIndices({
        sourceRow,
        destinationRow,
        closestEdge,
        maxIndex,
      })
      onRowReordered?.({
        sourceIndex,
        destinationIndex,
        rawData: { sourceRow, destinationRow, closestEdge },
      })
    },
    [onRowReordered, data?.length]
  )

  const tableDropTargetRef = useRef<HTMLDivElement>(null)
  const {
    isOpen: isDropTarget,
    open: prepareForDrop,
    close: stopPreparingForDrop,
  } = useDisclosure()

  useEffect(() => {
    const element = tableDropTargetRef.current
    if (!element || !onRowDroppedIntoTable) {
      return
    }
    return dropTargetForElements({
      canDrop: ({ source }) => {
        const dragData = source.data
        if (!isTableRowDragState<TData>(dragData)) {
          return false
        }
        return true
      },
      element,
      getData: (): TableDropTargetDragState => ({ isTableDropTarget: true, tableUuid: uuid }),
      getDropEffect: () => 'copy',
      onDragEnter: prepareForDrop,
      onDragLeave: stopPreparingForDrop,
      onDrop: stopPreparingForDrop,
    })
  }, [onRowDroppedIntoTable, prepareForDrop, stopPreparingForDrop, uuid])

  useEffect(() => {
    return monitorForElements({
      onDrop: async ({ source, location }) => {
        if (!onRowDroppedIntoTable) {
          return
        }
        const destination = location.current.dropTargets[0]
        if (!destination) {
          return
        }
        const sourceData = source.data
        const destinationData = destination.data
        if (
          !isTableRowDragState<TData>(sourceData) ||
          !isTableDropTargetDragState(destinationData) ||
          destinationData.tableUuid !== uuid
        ) {
          return
        }
        onRowDroppedIntoTable(sourceData.row)
      },
    })
  }, [onRowDroppedIntoTable, uuid])

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerWidth = `${tableContainerRef.current?.clientWidth ?? 0}px`
  const headerGroups = tableState.getHeaderGroups()
  const hasHeaders = headerGroups.some((hg) => hg.headers.length > 0)
  const showTableControls = !!(actions || isSearchingEnabled || showCount || title)

  return (
    <TableContext.Provider value={{ size, uuid }}>
      <Section>
        {showTableControls && (
          <Header
            actions={actions}
            {...(isSearchingEnabled
              ? {
                  onSearchQueryChange,
                  searchQuery,
                }
              : {})}
            title={title}
            {...(showCount ? { count: totalCount } : {})}
          />
        )}
        <Flex vertical gap="12px">
          <ScrollContainer vertical ref={tableContainerRef} $hasBorderRadius={hasBorderRadius}>
            <Flex
              vertical
              ref={tableDropTargetRef}
              style={{ width: 'fit-content', minWidth: '100%' }}
            >
              <StyledTable
                $isLoading={!!isLoading}
                $layout={layout}
                $minWidth={minWidth}
                ref={null}
              >
                <StyledTHead>
                  {!!isLoading && !hasHeaders && (
                    <tr>
                      <StyledTH $width="100%" />
                    </tr>
                  )}
                  {headerGroups.map((headerGroup) => {
                    return (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHeaderCell
                            header={header}
                            isSortingEnabled={!!onSortingChange}
                            key={header.id}
                          />
                        ))}
                      </tr>
                    )
                  })}
                </StyledTHead>
                {rows.map((row, index, arr) => (
                  <TableRow
                    key={row.id}
                    expanded={expanded}
                    getRowExpandedContent={getRowExpandedContent}
                    isLastRow={index === arr.length - 1}
                    onHoveredRowChange={onHoveredRowChange}
                    onRowReordered={handleRowReordered}
                    overrideReorderingWithinSameTableInvariant={
                      overrideReorderingWithinSameTableInvariant
                    }
                    row={row}
                    tableContainerWidth={tableContainerWidth}
                    verticalAlign={verticalAlign}
                  />
                ))}
              </StyledTable>
              {!rows.length && (
                <StyledEmptyStateWrapper $isDropTarget={isDropTarget}>
                  <EmptyState hideBorder size={size}>
                    {emptyStateImage}
                    <EmptyState.Message>{emptyStateMessage}</EmptyState.Message>
                    {emptyStateAction}
                  </EmptyState>
                </StyledEmptyStateWrapper>
              )}
            </Flex>
          </ScrollContainer>
          <Flex justify="flex-end">
            {isPaginationEnabled && (
              <Pagination
                current={pagination.pageIndex + 1}
                onChange={handlePaginationChange}
                pageSize={pagination.pageSize}
                style={{ position: 'sticky', bottom: 0 }}
                total={totalCount}
                showSizeChanger={false}
              />
            )}
          </Flex>
        </Flex>
      </Section>
    </TableContext.Provider>
  )
}

export default TableContent
